import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import objectHash from 'object-hash';
import pick from 'lodash/pick';

import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider } from '@material-ui/core/styles';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import Waypoint from 'react-waypoint';

import theme from 'qdw-next/theme';
import ConditionAddNew from './ConditionAddNew';
import ConditionContainer from './ConditionContainer';
import ConditionDisplay from './ConditionDisplay';
import ConditionEditor from './ConditionEditor';
import { getCategories, getConditions, getStatuses, updateConditions } from './api';
import styles from './styles.module.scss';

const filters = props => pick(props, ['periodEndDate', 'periodType', 'personId', 'riskAlgorithm']);

export default class RiskConditions extends Component {
  static defaultProps = {
    onSelect: () => {}
  };

  static propTypes = {
    onSelect: PropTypes.func,
    periodEndDate: PropTypes.string.isRequired,
    periods: PropTypes.arrayOf(PropTypes.object).isRequired,
    periodType: PropTypes.number.isRequired,
    personId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    riskAlgorithm: PropTypes.number.isRequired
  };

  formArrayNames = {
    new: 'new'
  };

  state = {
    categories: [],
    conditionsByHash: {},
    initialFormValues: {},
    isEditing: false,
    isLoading: false,
    selectedHash: null,
    serverOrderedHashes: [],
    showScrollIndicator: false,
    statuses: []
  };

  componentDidMount() {
    this.loadCategories();
    this.loadStatuses();
    this.loadConditions();
  }

  componentDidUpdate(prevProps, prevState) {
    const prevFilters = filters(prevProps);
    const currFilters = filters(this.props);

    if (!isEqual(prevFilters, currFilters)) {
      this.loadConditions();
    }

    if (prevState.selectedHash !== this.state.selectedHash) {
      this.props.onSelect(this.state.conditionsByHash[this.state.selectedHash]);
    }
  }

  handleCreateClick = () => {
    const { periodEndDate } = this.props;
    const { isEditing, isLoading } = this.state;

    if (!isEditing || !!isLoading) {
      return;
    }

    // FIXME: until a bug is addressed by react-final-form, we need to use push (insert and unshift do not work)
    this.form.mutators.push(this.formArrayNames.new, {
      yearAt: periodEndDate.slice(0, 4)
    });
  };

  handleFormSubmit = async valuesByHash => {
    const { periodEndDate, personId, riskAlgorithm } = this.props;
    const { conditionsByHash } = this.state;
    const serverValues = [];

    // existing conditions
    Object.entries(valuesByHash)
      .filter(([hash]) => !Object.values(this.formArrayNames).includes(hash))
      .forEach(([hash, values]) => {
        const condition = conditionsByHash[hash];

        if (condition.riskStatusId !== values.riskStatusId || condition.notes !== values.notes) {
          serverValues.push({
            eventCode: condition.eventCode,
            groupId: condition.groupId,
            notes: values.notes,
            riskAlgorithmId: riskAlgorithm,
            riskStatusId: values.riskStatusId,
            userCreated: condition.userCreated,
            yearAt: periodEndDate.slice(0, 4)
          });
        }
      });

    // new conditions
    Object.entries(valuesByHash)
      .filter(([hash]) => Object.values(this.formArrayNames).includes(hash))
      .reduce((acc, [_, values]) => [...acc, ...values], [])
      .forEach(values => {
        const [categoryId, groupId] = values.category.split('-');

        serverValues.push({
          eventCode: values.eventCode,
          groupId,
          notes: values.notes,
          riskAlgorithmId: riskAlgorithm,
          riskStatusId: values.riskStatusId,
          userCreated: true,
          yearAt: values.yearAt
        });
      });

    try {
      const results = await updateConditions({ personId, conditions: serverValues });
      await this.loadConditions();
      return results.data;
    } catch (e) {
      return {};
    }
  };

  handleEditClick = () => this.setState({ isEditing: true });

  handleScrolledDown = () => this.setState({ showScrollIndicator: true });

  handleScrolledToTop = () => this.setState({ showScrollIndicator: false });

  handleSelectionChange = hash => this.setState({ selectedHash: hash });

  handleUndoClick = () => {
    this.setState({ isEditing: false });

    // Specifically delay resetting the form values for 250ms to
    // allow the animations to finish.
    setTimeout(() => {
      this.form.reset();
    }, 250);
  };

  async loadCategories() {
    const { riskAlgorithm } = this.props;
    this.setState({ categories: [] });
    const results = await getCategories({ riskAlgorithmId: riskAlgorithm });
    this.setState({ categories: results.data });
    return results;
  }

  async loadConditions() {
    this.setState({
      conditionsByHash: {},
      isEditing: false,
      isLoading: true,
      selectedHash: null,
      serverOrderedHashes: [],
      showScrollIndicator: false
    });

    try {
      const results = await getConditions(filters(this.props));

      const conditionsByHash = {};
      const initialFormValues = {};
      const serverOrderedHashes = [];

      results.data.forEach(condition => {
        const hash = objectHash([condition.category, condition.groupId]);
        conditionsByHash[hash] = { ...condition };
        initialFormValues[hash] = { ...condition };
        serverOrderedHashes.push(hash);
      });

      this.setState({ conditionsByHash, initialFormValues, serverOrderedHashes });
      return results;
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async loadStatuses() {
    this.setState({ statuses: [] });
    const results = await getStatuses();
    this.setState({ statuses: results.data });
    return results;
  }

  render() {
    const { initialFormValues } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        <Form initialValues={initialFormValues} mutators={{ ...arrayMutators }} onSubmit={this.handleFormSubmit}>
          {({ errors, form, handleSubmit, pristine, submitting }) => {
            this.form = form;

            // FIXME: the form is sometimes reporting invalid even when the errors object is empty - use this technique until the issue is resolved
            const invalid = !!Object.keys(errors).length;

            return (
              <form className={classNames(styles.container, 'risk-modal-conditions')} onSubmit={handleSubmit}>
                {this.renderHeader({ invalid, pristine, submitting })}
                {this.renderLoading() || this.renderNoData() || this.renderContent({ submitting })}
              </form>
            );
          }}
        </Form>
      </MuiThemeProvider>
    );
  }

  renderContent({ submitting }) {
    const { isEditing, isLoading, serverOrderedHashes } = this.state;

    if (isLoading || (!isEditing && serverOrderedHashes.length <= 0)) {
      return null;
    }

    return (
      <div className={styles.content}>
        <Waypoint onEnter={this.handleScrolledToTop} onLeave={this.handleScrolledDown} />
        <ul className={styles.contentList}>
          {this.renderNewItems({ submitting })}
          {this.renderExistingItems({ submitting })}
        </ul>
      </div>
    );
  }

  renderExistingItems({ submitting }) {
    const { conditionsByHash, isEditing, selectedHash, serverOrderedHashes, statuses } = this.state;

    return serverOrderedHashes.map(hash => {
      const condition = conditionsByHash[hash];
      const isSelected = selectedHash === hash;

      return (
        <ConditionContainer isEditing={isEditing} key={hash}>
          <ConditionDisplay
            condition={condition}
            hash={hash}
            isEditing={isEditing}
            isSelected={isSelected}
            statuses={statuses}
            onClick={this.handleSelectionChange}
          />
          <Collapse in={isEditing}>
            <ConditionEditor isSubmitting={submitting} name={hash} statuses={statuses} />
          </Collapse>
        </ConditionContainer>
      );
    });
  }

  renderHeader({ invalid, pristine, submitting }) {
    const { isEditing, isLoading, showScrollIndicator } = this.state;

    const actions = isEditing ? (
      <Fragment>
        <Button disabled={submitting} key="undo" onClick={this.handleUndoClick} size="small" type="reset">
          Undo
        </Button>
        <Button disabled={submitting} key="create" onClick={this.handleCreateClick} size="small">
          Add New
        </Button>
        <Button
          color="primary"
          disabled={pristine || submitting || invalid}
          key="save"
          size="small"
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </Fragment>
    ) : (
      <Button color="primary" key="edit" onClick={this.handleEditClick} size="small">
        Edit
      </Button>
    );

    return (
      <Paper className={styles.header} square elevation={showScrollIndicator ? 1 : 0}>
        <h3 className={styles.headerTitle}>Conditions</h3>
        {!isLoading && <div className={styles.headerActions}>{actions}</div>}
      </Paper>
    );
  }

  renderLoading() {
    const { isLoading } = this.state;

    return isLoading ? (
      <div className={styles.content}>
        <div className="loading-overlay" />
      </div>
    ) : null;
  }

  renderNewItems({ submitting }) {
    const { periods } = this.props;
    const { categories, isEditing, statuses } = this.state;

    return isEditing ? (
      <FieldArray name={this.formArrayNames.new}>
        {({ fields }) =>
          fields.map(id => (
            <ConditionContainer isEditing={isEditing} key={id}>
              <ConditionAddNew
                categories={categories}
                isSubmitting={submitting}
                name={id}
                periods={periods}
                statuses={statuses}
              />
            </ConditionContainer>
          ))
        }
      </FieldArray>
    ) : null;
  }

  renderNoData() {
    const { isEditing, serverOrderedHashes } = this.state;

    return !isEditing && serverOrderedHashes.length <= 0 ? (
      <div className={classNames(styles.content, styles.contentEmpty)}>
        <p>No condition data available</p>
      </div>
    ) : null;
  }
}
