import PropTypes from 'prop-types';
import React, { Component } from 'react';

import PopperInput from '../PopperInput';
import { SuperHierarchyList } from '../SuperHierarchyList';
import withState from '../SuperHierarchyList/withState';

class SuperHierarchyListPopper extends Component {
  static defaultProps = {
    sentinelDisplay: 'All selected'
  };

  static propTypes = {
    PopperInputProps: PropTypes.object,
    hierarchy: PropTypes.any,
    hierarchyStrategy: PropTypes.any,
    id: PropTypes.any,
    renderDisplayValue: PropTypes.func,
    selectAllChecked: PropTypes.any,
    selectAllEnabled: PropTypes.any,
    sentinelDisplay: PropTypes.string
  };

  displayValue() {
    const { hierarchy, hierarchyStrategy, renderDisplayValue, selectAllChecked, selectAllEnabled, sentinelDisplay } = this.props;

    if (selectAllChecked && selectAllEnabled) {
      return sentinelDisplay;
    } else {
      const checkedNodes = hierarchyStrategy(hierarchy);
      return renderDisplayValue ? renderDisplayValue(checkedNodes) : checkedNodes.map(n => n.display).join(', ');
    }
  }

  render() {
    const {
      PopperInputProps,
      InputProps,
      hierarchyStrategy,
      id,
      renderDisplayValue,
      sentinelDisplay,
      ...propsSubset
    } = this.props;

    return (
      <PopperInput {...PopperInputProps} {...InputProps} id={id} displayValue={this.displayValue()}>
        <SuperHierarchyList {...propsSubset} />
      </PopperInput>
    );
  }
}

export default withState(SuperHierarchyListPopper);
