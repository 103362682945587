import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import classNames from 'classnames';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';

import HierarchyList from '../HierarchyList';
import styles from './styles.module.scss';
import withState from './withState';

export class SuperHierarchyList extends Component {
  static defaultProps = {
    searchEnabled: false,
    searchValue: '',
    selectAllEnabled: false
  };

  static propTypes = {
    hierarchy: PropTypes.object,
    onHierarchyChange: PropTypes.func,
    onSearchChange: PropTypes.func,
    onSelectAllChange: PropTypes.func,
    renderNodeDisplay: PropTypes.any,
    searchEnabled: PropTypes.bool,
    searchPredicate: PropTypes.func,
    searchValue: PropTypes.string,
    selectAllChecked: PropTypes.bool,
    selectAllEnabled: PropTypes.bool,
    dataValue: PropTypes.string
  };

  handleNodeVisibilityChange = (node, next) => {
    const { hierarchy, onHierarchyChange } = this.props;

    if (onHierarchyChange && node.visible !== next) {
      onHierarchyChange({
        eventType: HierarchyList.eventTypes.visible,
        hierarchy,
        nextPropValue: next,
        node,
        propName: 'visible'
      });
    }
  };

  handleSearchChange = event => {
    const searchText = event.target.value;
    const { hierarchy, onSearchChange } = this.props;

    onSearchChange && onSearchChange(event);

    this.searchNodes(searchText, hierarchy.nodes, hierarchy.multiple);
  };

  hierarchyListVisible() {
    return !this.selectAllEnabled() || !this.selectAllChecked();
  }

  render() {
    return (
      <Fragment>
        <Fragment>{this.renderSelectAll()}</Fragment>
        <Fragment>{this.renderSearch()}</Fragment>
        <Fragment>{this.renderHierarchyList()}</Fragment>
      </Fragment>
    );
  }

  renderHierarchyList() {
    if (!this.hierarchyListVisible()) {
      return null;
    }

    const { hierarchy, onHierarchyChange, renderNodeDisplay } = this.props;

    return (
      <div style={{ marginLeft: 6 }}>
        <HierarchyList hierarchy={hierarchy} onHierarchyChange={onHierarchyChange} renderNodeDisplay={renderNodeDisplay} />
      </div>
    );
  }

  renderSearch() {
    if (!this.searchVisible()) {
      return null;
    }

    const { searchValue } = this.props;

    return (
      <div className={classNames(styles.inputContainer)}>
        <TextField fullWidth={true} onChange={this.handleSearchChange} placeholder="Search" value={searchValue} />
      </div>
    );
  }

  renderSelectAll() {
    if (!this.selectAllVisible()) {
      return null;
    }

    const { onSelectAllChange } = this.props;

    return (
      <div className={classNames(styles.inputContainer)}>
        <FormControlLabel
          label="Select All"
          control={<Checkbox checked={this.selectAllChecked()} onChange={onSelectAllChange} />}
          data-value={this.props.dataValue + 'SelectAll'}
        />
      </div>
    );
  }

  searchEnabled() {
    const { searchEnabled } = this.props;

    return !!searchEnabled;
  }

  searchNodes(searchText, nodes, isMultiSelect) {
    nodes.forEach(node => {
      if (!node.hasChildren) {
        this.handleNodeVisibilityChange(node, this.searchPredicate(searchText, node));
      } else {
        this.searchNodes(searchText, node.nodes, isMultiSelect);
        this.handleNodeVisibilityChange(
          node,
          node.descendants().some(d => d.visible) || (isMultiSelect && this.searchPredicate(searchText, node))
        );
      }
    });
  }

  searchPredicate(searchText, node) {
    const { searchPredicate } = this.props;

    return searchPredicate
      ? searchPredicate(searchText, node)
      : node.display
          .trim()
          .toLowerCase()
          .includes(searchText.trim().toLowerCase());
  }

  searchVisible() {
    return this.searchEnabled() && (!this.selectAllEnabled() || !this.selectAllChecked());
  }

  selectAllEnabled() {
    const { selectAllEnabled } = this.props;

    return !!selectAllEnabled;
  }

  selectAllChecked() {
    const { selectAllChecked } = this.props;

    return !!selectAllChecked;
  }

  selectAllVisible() {
    const { hierarchy } = this.props;

    if (!hierarchy.multiple && this.selectAllEnabled()) {
      console.warn('selectAll cannot be enabled when the hierarchy does not support multiple selection');
    }

    return hierarchy.multiple && this.selectAllEnabled();
  }
}

export default withState(SuperHierarchyList);
