import { Component } from 'react';

class PcBreakGlassSearchIconWrapper extends Component {
  componentDidMount() {
    document.addEventListener('breakGlassSelectPatient', event => {
      const url = new URL(window.location.href);
      url.search = `?cmPersonId=${event.detail.personId}&cmTab=chart`;
      window.location.href = url.toString();
    });
  }

  render() {
    const { jwt, client, apiUrl, patientChartEnv, breakGlassIcon } = this.props;
    return (
      <pc-config env={patientChartEnv} jwt={jwt} client={client}>
        <pc-break-glass-search api-url={apiUrl} button-icon={true} button-color={'black'} break-glass-icon={breakGlassIcon}/>
      </pc-config>
    );
  }
}

export default PcBreakGlassSearchIconWrapper;
