import PropTypes from 'prop-types';
import React from 'react';

import NumberFormat from 'react-number-format';

export default function IntegerNumberFormat(props) {
  let { defaultValue, inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      allowEmptyFormatting={false}
      allowNegative={false}
      decimalScale={0}
      onValueChange={values => onChange({ target: { value: values.floatValue || defaultValue } })}
      ref={inputRef}
      {...other}
    />
  );
}

IntegerNumberFormat.defaultProps = {
  defaultValue: ''
};

IntegerNumberFormat.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};
