import get from 'lodash/get';

import { getAncestorNodes, getDescendantNodes } from './utils';

export class SingleSelectHierarchyNode {
  _ancestors;
  _checked;
  _dataItem;
  _descendants;
  _expanded;
  _hierarchy;
  _index;
  _level;
  _nodes;
  _parentNode;
  _visible;

  get checked() {
    return !!this._checked;
  }
  set checked(value) {
    if (this.hasChildren) {
      console.warn('A node cannot be checked when hasChildren returns true.');
      return;
    } else if (!!value === this._checked) {
      return;
    } else if (!!value && !this.checked) {
      this.hierarchy
        .descendants()
        .filter(d => !d.hasChildren)
        .forEach(d => (d.checked = false));
    }

    this._checked = !!value;
  }

  get dataItem() {
    return { ...this._dataItem };
  }

  get disabled() {
    return !!get(this.dataItem, this.hierarchy.dataMemberPaths.disabled, false);
  }

  get display() {
    return get(this.dataItem, this.hierarchy.dataMemberPaths.display, '');
  }

  get expanded() {
    return !!this._expanded;
  }
  set expanded(value) {
    if (!this.hasChildren) {
      console.warn('A node cannot be expanded when hasChildren returns false.');
      return;
    }
    this._expanded = !!value;
  }

  get hasChildren() {
    return !!get(this.nodes, 'length', 0);
  }

  get hierarchy() {
    return this._hierarchy;
  }

  get index() {
    return this._index;
  }

  get indeterminate() {
    return false;
  }

  get key() {
    return get(this.dataItem, this.hierarchy.dataMemberPaths.key);
  }

  get level() {
    return this._level;
  }

  get nodes() {
    return [...this._nodes];
  }

  get parentNode() {
    return this._parentNode;
  }

  get visible() {
    return !!this._visible;
  }
  set visible(value) {
    this._visible = !!value;
  }

  constructor(options) {
    this._initialize(options);
  }

  _initialize(options) {
    const { dataItem, hierarchy, index, level, parentNode } = options;

    this._checked = false;

    // Join path for compatibility with QDW Rails
    if (dataItem.path && Array.isArray(dataItem.path)) {
      dataItem.path = dataItem.path.join('/');
    }

    this._dataItem = dataItem;
    this._expanded = false;
    this._hierarchy = hierarchy;
    this._index = index;
    this._level = level;
    this._parentNode = parentNode;
    this.visible = true;

    this._nodes = get(dataItem, hierarchy.dataMemberPaths.children, []).map(
      (childDataItem, childIndex) =>
        new hierarchy._NodeType({
          dataItem: childDataItem,
          hierarchy: this.hierarchy,
          index: childIndex,
          level: this.level + 1,
          parentNode: this
        })
    );
  }

  ancestors() {
    if (!this._ancestors) {
      this._ancestors = getAncestorNodes(this);
    }
    return [...this._ancestors];
  }

  descendants() {
    if (!this._descendants) {
      this._descendants = getDescendantNodes(this);
    }
    return [...this._descendants];
  }

  next() {
    return this.parentNode.nodes[this.index + 1];
  }

  previous() {
    return this.parentNode.nodes[this.index - 1];
  }

  siblings() {
    const siblings = [...this.parentNode.nodes];
    siblings.splice(this.index, 1);
    return siblings;
  }
}
