import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import classNames from 'classnames';

import AppBar from '@material-ui/core/AppBar';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import sharedStyles from './shared-styles';

const styles = () => ({
  ...sharedStyles,
  scrollable: {
    ...sharedStyles.scrollable,
    minHeight: 300,
    maxHeight: 300
  }
});

export function TemplateViewer({ classes, template }) {
  return (
    <Fragment>
      <AppBar>
        <Toolbar>
          <Typography color="inherit" variant="h6">
            Template Details
          </Typography>
        </Toolbar>
      </AppBar>

      <div className={classNames(classes.padded, classes.scrollable)}>
        <FormControl>
          <InputLabel htmlFor="outreachType">Mode</InputLabel>
          <Input disabled={true} disableUnderline={true} id="outreachType" value={template.dependency.outreachType} />
        </FormControl>

        <FormControl>
          <InputLabel htmlFor="content">Text Preview</InputLabel>
          <Input disabled={true} disableUnderline={true} id="content" multiline={true} value={template.dependency.content} />
        </FormControl>
      </div>
    </Fragment>
  );
}

TemplateViewer.propTypes = {
  classes: PropTypes.object,
  template: PropTypes.object.isRequired
};

export default withStyles(styles)(TemplateViewer);
