import PropTypes from 'prop-types';
import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

export default function TextField({
  input: { onBlur, onChange, onFocus, value, ...restInput },
  isSubmitting,
  label,
  meta: { error, touched },
  multiline
}) {
  return (
    <FormControl error={error && touched} fullWidth margin="dense">
      <InputLabel>{label}</InputLabel>
      <Input
        disabled={isSubmitting}
        inputProps={restInput}
        multiline={multiline}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
      />
      {error && touched ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
}

TextField.defaultProps = {
  input: { value: '' },
  isSubmitting: false,
  meta: {},
  multiline: false
};

TextField.propTypes = {
  input: PropTypes.object,
  isSubmitting: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  multiline: PropTypes.bool
};
