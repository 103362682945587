import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: 'transparent'
      },
      root: {
        boxShadow: 'unset',
        padding: '0 15px'
      }
    },
    MuiList: {
      padding: {
        paddingBottom: 0,
        paddingTop: 0
      }
    },
    MuiListItemText: {
      primary: {
        lineHeight: 'unset'
      }
    }
  },
  palette: {
    primary: {
      main: '#31849a'
    },
    secondary: {
      main: '#7cbf4c'
    }
  },
  props: {
    MuiAppBar: {
      color: 'default',
      position: 'static'
    },
    MuiFormControl: {
      fullWidth: true,
      margin: 'normal'
    },
    MuiToolbar: {
      disableGutters: true
    }
  }
});

export default theme;
