import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const Checkbox = ({ checked }) => {
  const classes = classNames('fa', {
    ['fa-check-square-o']: checked,
    ['fa-square-o']: !checked
  });

  return <i className={classes} style={{ display: 'inline-block' }} />;
};

Checkbox.propTypes = { checked: PropTypes.bool };

export default Checkbox;
