import React from 'react';
import get from 'lodash/get';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

export function renderComplexSelectItems<T>(
  items: T[],
  valueMemberPath: keyof T,
  primaryDisplayMemberPath: keyof T,
  secondaryDisplayMemberPath?: keyof T
) {
  if (!get(items, 'length')) {
    return null;
  }
  return items.map((item: T) => {
    const menuValue: any = item[valueMemberPath];
    const primaryValue: any = item[primaryDisplayMemberPath];
    const secondaryValue: any = item[secondaryDisplayMemberPath!];
    return (
      <MenuItem key={menuValue} value={menuValue}>
        <ListItemText primary={primaryValue} secondary={secondaryValue} />
      </MenuItem>
    );
  });
}

export function renderNativeSelectItems<T>(
  items: T[],
  valueMemberPath: keyof T,
  primaryDisplayMemberPath: keyof T,
  secondaryDisplayMemberPath?: keyof T
) {
  if (!get(items, 'length')) {
    return null;
  }
  return items.map((item: T) => {
    const menuValue: any = item[valueMemberPath];
    const primaryValue: any = item[primaryDisplayMemberPath];
    const secondaryValue: any = item[secondaryDisplayMemberPath!];
    return (
      <option key={menuValue} value={menuValue}>
        {primaryValue}
      </option>
    );
  });
}
