import PropTypes from 'prop-types';
import React, { Component } from 'react';
import debounce from 'lodash/debounce';

export default class SearchInput extends Component {
  static propTypes = {
    value: PropTypes.string,
    onValueUpdate: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = { currentValue: '' };
    this.propagateValueChange = debounce(this.propagateValueChange, 200, { leading: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentValue !== prevState.currentValue) {
      this.propagateValueChange(this.state.currentValue);
    }
  }

  render() {
    return (
      <div className="search-box">
        <input
          placeholder="Search"
          onChange={this.handleChange}
          name="search"
          autoComplete="off"
          value={this.state.currentValue}
        />
      </div>
    );
  }

  propagateValueChange(value) {
    this.props.onValueUpdate(value);
  }

  handleChange = event => {
    this.setState({ currentValue: event.target.value });
  };
}
