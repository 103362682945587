import React from 'react';

import ChevronRight from '@material-ui/icons/ChevronRight';
import ExpandMore from '@material-ui/icons/ExpandMore';

export interface Props {
  expanded: boolean;
}

export default function ExpanderIcon({ expanded = false }: Props) {
  return !!expanded ? <ExpandMore /> : <ChevronRight />;
}
