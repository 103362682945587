import { createTheme } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

const theme = createTheme({
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['ubuntu'].join(',')
  },
  overrides: {
    MuiCardHeader: {
      root: {
        backgroundColor: '#4e595b',
        padding: '1em'
      }
    },
    MuiTypography: {
      h5: {
        color: '#fff',
        fontFamily: '"Maven Pro", sans-serif',
        fontSize: '90%',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      }
    }
  },
  palette: {
    primary: {
      main: '#31849a'
    },
    secondary: {
      main: '#7cbf4c'
    }
  }
});

export default theme;
