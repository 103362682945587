import React from 'react';

import get from 'lodash/get';
import includes from 'lodash/includes';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import pickBy from 'lodash/pickBy';

import moment from 'moment-timezone';

import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

export function generateTimeRange(startTime, interval, period) {
  const labels = [];
  const periodsInADay = moment.duration(1, 'day').as(period);
  const startTimeMoment = moment(startTime, 'hh:mm');

  for (let i = 0; i < periodsInADay; i += interval) {
    startTimeMoment.add(i === 0 ? 0 : interval, period);
    labels.push(startTimeMoment.format('hh:mm A'));
  }

  return labels;
}

export function generateWeekDays() {
  return moment.weekdays().map((weekday, index) => ({
    id: index,
    name: weekday
  }));
}

function getErrorMessage(key) {
  switch (key) {
    case 'required':
      return 'Required';
    case 'type':
    default:
      return 'Invalid';
  }
}

function normalize(providedObject, defaultObject) {
  providedObject = pickBy(providedObject, value => !(isNull(value) || isUndefined(value)));
  return { ...defaultObject, ...providedObject };
}

export function normalizeCampaign(campaign) {
  const defaultCampaign = {
    daysSinceLeastRecentVisit: '',
    daysSinceMostRecentVisit: '',
    duration: 1,
    durationType: 3,
    enabled: true,
    id: undefined,
    languages: [],
    maxAge: '',
    measureId: '',
    minAge: '',
    name: '',
    pcps: [],
    periodTypeId: '',
    providerAttribution: '',
    priority: 1,
    templateId: '',
    userId: undefined,
    windowsAttributes: [],
    lobs: [],
    memberProducts: [],
    sourceList: [],
    providerList: [],
    locationList: [],
    patientListId: '',
    campaignType: '',
    apptDuration: 1,
    apptDurationType: 0,
    initialOptOut: true
  };
  return normalize(campaign, defaultCampaign);
}

export function normalizeCampaignWindow(campaignWindow) {
  const defaultCampaignWindow = {
    campaignId: undefined,
    dayOfWeek: '',
    endTime: '',
    id: undefined,
    messageLimit: '',
    startTime: '',
    timeZone: moment.tz.guess()
  };

  return normalize(campaignWindow, defaultCampaignWindow);
}

export function renderComplexMultiSelectDisplay(items, valueMemberPath, displayMemberPath, separator = ', ') {
  return function(selectedValues) {
    if (!get(items, 'length') || !get(selectedValues, 'length')) {
      return '';
    }

    return selectedValues
      .map(selectedValue => {
        const match = items.find(item => item[valueMemberPath].toString() === selectedValue.toString());
        return get(match, displayMemberPath);
      })
      .filter(displayValue => !!displayValue)
      .sort()
      .join(separator);
  };
}

export function renderComplexMultiSelectItems(
  items,
  selectedItems,
  valueMemberPath,
  primaryDisplayMemberPath,
  secondaryDisplayMemberPath
) {
  if (!get(items, 'length')) {
    return null;
  }

  return items.map(item => (
    <MenuItem key={item[valueMemberPath]} value={item[valueMemberPath]}>
      <Checkbox checked={includes(selectedItems, item[valueMemberPath])} />
      <ListItemText primary={item[primaryDisplayMemberPath]} secondary={item[secondaryDisplayMemberPath]} />
    </MenuItem>
  ));
}

export function renderErrorMessages(errors) {
  const keys = Object.keys(errors || {});
  if (!keys.length) {
    return null;
  }

  return keys.map(key => <FormHelperText key={key}>{getErrorMessage(key)}</FormHelperText>);
}

export function renderSimpleSelectItems(items) {
  if (!get(items, 'length')) {
    return null;
  }

  return items.map((item, index) => (
    <MenuItem key={index} value={item}>
      <ListItemText primary={item} />
    </MenuItem>
  ));
}
