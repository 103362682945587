import React, { Component, Fragment } from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import ApiService from './apiService';
import { CurrentUser, User } from 'qdw-next/common/data/models';
import VirtualizedUserList from './VirtualizedUserList';
import SearchBar from './SearchBar';

import styles from './styles.module.scss';

interface Props {
  users: User[];
  doneLoading: boolean;
  allUsersSelected: boolean;
  cognitoConfig: boolean;
  currentUser: CurrentUser;
  selectedUsers: number[];
  searchQuery: string;
  onAddNewUserClick: () => void;
  onSelectFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onUserFilter: (query: string, event: React.ChangeEvent<HTMLInputElement>) => void;
  onToggleSelectAll: (event: React.MouseEvent<HTMLElement>) => void;
  onDeleteUser(id: number): void;
  onToggleSelectedUser(id: number): void;
}

export default class UserList extends Component<Props> {
  state = {
    alertMessage: <div />,
    open: false
  };

  constructor(props: any) {
    super(props);
  }

  public handleClose = () => {
    this.setState({ open: false });
  };

  public showSyncUsersButton(currentUser: CurrentUser | undefined) {
    if (currentUser && currentUser.role === 'super_admin' && this.props.cognitoConfig === true) {
      return (
        <div className={styles.userManagementButton}>
          <label htmlFor="sync-users">
            <Button id="sync-users" component="span" className={styles.syncUsersButton} onClick={this.handleSyncAllUsers}>
              Sync All Users
            </Button>
          </label>
        </div>
      );
    }
  }

  public showSyncOrganizationHierarchyButton = (currentUser: CurrentUser | undefined) => {
    const chimeraRebuildEnabled = (window as any).QDW_EXPORTS.configuration.chimeraRebuildHierarchyEnabled
    if (currentUser && currentUser.role === 'super_admin' && chimeraRebuildEnabled) {
      return (
        <div className={styles.userManagementButton}>
          <label htmlFor="sync-organization-hierarchy">
            <Button id="sync-organization-hierarchy" component="span" className={styles.SyncOrganizationHierarchyButton}
                    onClick={this.handleSyncOrganizationHierarchy}>
              Hierarchy Refresh
            </Button>
          </label>
        </div>
      );
    }
  }

  public showSyncChimeraSettingsButton = (currentUser: CurrentUser | undefined) => {
    if (currentUser && currentUser.role === 'super_admin') {
      return (
        <div className={styles.userManagementButton}>
          <label htmlFor="sync-chimera-settings">
            <Button id="sync-chimera-settings" component="span" className={styles.SyncChimeraSettingsButton}
                    onClick={this.handleSyncChimeraSettings}>
              Sync Settings
            </Button>
          </label>
        </div>
      );
    }
  }

  public handleSyncAllUsers = () => {
    this.syncAllUsers()
      .then(() => {
        this.setState({
          open: true,
          alertMessage: <SnackbarContent className={styles.success} message={<div>All users are now fully synced.</div>} />
        });
      })
      .catch((e: Error) => {
        this.setState({
          open: true,
          alertMessage: (
            <SnackbarContent
              className={styles.error}
              message={
                <div>
                  <div>Error: Users were not synced correctly.</div>
                  {e.message && <div>{e.message}</div>}
                </div>
              }
            />
          )
        });
      });
  };

  public handleSyncOrganizationHierarchy = () => {
    this.syncOrganizationHierarchy()
      .then(() => {
        this.setState({
          open: true,
          alertMessage: <SnackbarContent className={styles.success} message={<div>Organization hierarchy fully synced.</div>} />
        });
      })
      .catch((e: Error) => {
        this.setState({
          open: true,
          alertMessage: (
            <SnackbarContent
              className={styles.error}
              message={
                <div>
                  <div>Error: Organization hierarchy was not synced correctly.</div>
                  {e.message && <div>{e.message}</div>}
                </div>
              }
            />
          )
        });
      });
  };

  public handleSyncChimeraSettings = () => {
    this.syncChimeraSettings()
      .then(() => {
        this.setState({
          open: true,
          alertMessage: <SnackbarContent className={styles.success} message={<div>Your settings are now starting to be synced.</div>} />
        });
      })
      .catch((e: Error) => {
        this.setState({
          open: true,
          alertMessage: (
            <SnackbarContent
              className={styles.error}
              message={
                <div>
                  <div>Error: Settings did not sync properly.</div>
                  {e.message && <div>{e.message}</div>}
                </div>
              }
            />
          )
        });
      });
  };

  public async syncAllUsers() {
    const apiService = new ApiService();
    const fetchOptions = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': (window as any).Rails.csrfToken(),
        'X-Requested-With': 'XMLHttpRequest'
      },
      method: 'GET'
    };

    const response = await apiService.syncAllUsers({}, fetchOptions);
    return response;
  }

  public async syncOrganizationHierarchy() {
    const apiService = new ApiService();
    const fetchOptions = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': (window as any).Rails.csrfToken(),
        'X-Requested-With': 'XMLHttpRequest'
      },
      method: 'POST'
    };

    const response = await apiService.syncOrganizationHierarchy({}, fetchOptions);
    return response;
  }

  public async syncChimeraSettings() {
    const apiService = new ApiService();
    const fetchOptions = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': (window as any).Rails.csrfToken(),
        'X-Requested-With': 'XMLHttpRequest'
      },
      method: 'POST'
    };

    const response = await apiService.syncChimeraSettings({}, fetchOptions);
    return response;
  }

  showSnackbar = () => {
    this.setState({
      open: true,
      alertMessage: <SnackbarContent className={styles.success} message={<div>User management opened in new tab.</div>} />
    });
  }

  public render() {
    const { users, selectedUsers, allUsersSelected } = this.props;
    const allUsers = (
      <VirtualizedUserList
        users={users}
        selectedUsers={selectedUsers}
        onToggleSelectedUser={this.props.onToggleSelectedUser}
        onDeleteUser={this.props.onDeleteUser}
      />
    );

    const renderChimeraLink = (window as any).QDW_EXPORTS.configuration.chimeraUserManagementEnabled;
    const chimeraLink = (window as any).QDW_EXPORTS.configuration.chimeraUserManagementUrl;

    return (
      <Fragment>
        {renderChimeraLink &&
          <Grid item style={{ width: '100%' }}>
            <Card>
              <CardContent style={{  paddingBottom: '16px'  }}>
                Please visit our <a onClick={this.showSnackbar} target="_blank" rel="noopener noreferrer" href={chimeraLink}>New User Management page</a> to create users or update person record access
              </CardContent>
            </Card>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              open={this.state.open}
              autoHideDuration={8000}
              onClose={this.handleClose}
            >
              {this.state.alertMessage}
            </Snackbar>
          </Grid>
        }
        <Grid className="userList" item xs={6}>
          <Card>
            <CardHeader title="Users" />
            <CardContent>
              <Grid container>
                <Grid item xs={9}>
                  <FormControl className={styles.userListFilter}>
                    <InputLabel htmlFor="userListFilterInput">Search by name, username, group, category</InputLabel>
                    <SearchBar onUserFilter={this.props.onUserFilter} clearQuery={this.props.searchQuery} />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <div className={styles.userListSelectAll}>
                    <span>{this.props.users.length} Users</span>
                    <Checkbox
                      id="userListSelectAll"
                      className={styles.checkBox}
                      checked={allUsersSelected}
                      value={`all`}
                      onClick={this.props.onToggleSelectAll}
                    />
                  </div>
                </Grid>
                {(this.props.doneLoading && allUsers) || <CircularProgress style={{ width: '100%' }} />}
              </Grid>
              {this.showSyncOrganizationHierarchyButton(this.props.currentUser)}
              {this.showSyncChimeraSettingsButton(this.props.currentUser)}
            </CardContent>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              open={this.state.open}
              autoHideDuration={5000}
              onClose={this.handleClose}
            >
              {this.state.alertMessage}
            </Snackbar>
          </Card>
        </Grid>
      </Fragment>
    );
  }
}
