import PropTypes from 'prop-types';
import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';

import SelectField from './SelectField';

export default function PeriodField({ periods, ...props }) {
  return (
    <SelectField label="Year" {...props}>
      {periods.map(({ periodName }) => (
        <MenuItem key={periodName} value={periodName}>
          {periodName}
        </MenuItem>
      ))}
    </SelectField>
  );
}

PeriodField.defaultProps = {
  periods: []
};

PeriodField.propTypes = {
  periods: PropTypes.arrayOf(
    PropTypes.shape({
      endDate: PropTypes.string,
      periodId: PropTypes.number,
      periodName: PropTypes.string,
      periodTypeId: PropTypes.number
    })
  )
};
