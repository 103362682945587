import React, { Component, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { Theme } from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Field } from 'react-final-form';
import get from 'lodash/get';

import { renderComplexSelectItems } from 'qdw-next/common/formHelpers';
import UserEditFormState, { SelectedFields } from './UserEditFormState';
import { CurrentUser } from 'qdw-next/common/data/models';
import SuperHierarchyListPopper from 'qdw-next/common/components/SuperHierarchyListPopper';
import { hierarchyStrategies } from 'qdw-next/common/data';
import { BinderyRoles, HRARole, QDWExportRole } from './qdwExport';
import FinalFormTreePopper from 'qdw-next/common/components/FinalFormTreePopper';
import AlertDialog from 'qdw-next/common/components/AlertDialog';
interface Props
  extends Pick<
    UserEditFormState,
    | 'careManage'
    | 'careManagerAdmin'
    | 'desktop'
    | 'referral'
    | 'referralPatientChart'
    | 'export'
    | 'outreach'
    | 'templates'
    | 'templatesSelected'
    | 'binderySelected'
    | 'dashboards'
    | 'initiatives'
    | 'reports'
    | 'widgets'
    | 'widgetsSelected'
    | 'ewaDashboards'
    | 'ewaDashboardsSelected'
    | 'reportsSelected'
    | 'initiativesSelected'
    | 'dashboardsSelected'
    | 'electronicFormRoleSelected'
    | 'hmrRoleSelected'
    | 'hpmRoleSelected'
    | 'hraRoleSelected'
    | 'hraAuditRoleSelected'
    | 'paperFormRoleSelected'
  > {
  currentUser: CurrentUser;
  onSelectChange: (key: string, value: string | string[] | boolean | null) => void;
  onMfaReset: () => void;
  multiEdit: boolean;
  binderyConfig: BinderyRoles;
  hraConfig: HRARole;
  mfaConfig: boolean;
  mfaVerified?: boolean;
}

type bitMaskFields = Pick<
  CurrentUser,
  | 'electronicFormPermissions'
  | 'hraPermissions'
  | 'hraAuditPermissions'
  | 'hmrPermissions'
  | 'hpmPermissions'
  | 'paperFormPermissions'
>;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(2)
    },
    checkboxes: {
      paddingLeft: '7px'
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 150
    },
    multiSelectLabel: {
      fontSize: 13
    },
    multiSelect: {
      margin: theme.spacing(1),
      width: 420
    }
  });

interface FeatureAccessState {
  outreach: boolean | null;
  export: boolean | null;
  careManage: boolean | null;
  careManagerAdmin: boolean | null;
  desktop: boolean | null;
  referral: boolean | null;
  referralPatientChart: boolean | null;
}

type IndeterminateTypes = Pick<
  FeatureAccessState,
  'outreach' | 'export' | 'careManage' | 'careManagerAdmin' | 'desktop' | 'referral' | 'referralPatientChart'
>;

export class FeatureAccessPanel extends Component<Props & WithStyles<typeof styles>, FeatureAccessState> {
  readonly popperWidth = 420;

  state = {
    outreach: this.props.outreach,
    export: this.props.export,
    careManage: this.props.careManage,
    careManagerAdmin: this.props.careManagerAdmin,
    desktop: this.props.desktop,
    referral: this.props.referral,
    referralPatientChart: this.props.referralPatientChart,
    widgetSelectAll: true,
    ewaDashboardsSelectAll: true
  };

  public handleMultiSelectChange = (stateKey: string) => (values: string[]): void => {
    this.props.onSelectChange(stateKey, values);
  };

  public handleSelectChange = (stateKey: keyof SelectedFields) => (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const selectedValue = event.target.value;
    this.props.onSelectChange(stateKey, selectedValue);
  };

  public handleMfaReset = () => {
    this.props.onMfaReset();
  };

  renderOutreach(currentUser: CurrentUser | undefined) {
    if (currentUser && currentUser.outreachAbility) {
      return (
        <FormControlLabel
          disabled
          control={
            <Checkbox
              data-test="outreach"
              checked={!!this.props.outreach}
              {...this.indeterminate(this.props.outreach)}
              onChange={this.handleCheckChange('outreach')}
            />
          }
          label="Outreach"
        />
      );
    }
  }

  renderOutreachTemplate(currentUser: CurrentUser | undefined) {
    if (currentUser && currentUser.outreachAbility && this.props.outreach) {
      return (
        <FormControl className={this.props.classes.multiSelect}>
          <InputLabel className={this.props.classes.multiSelectLabel}>Outreach Template</InputLabel>
          <Field
            name="outreachTemplates"
            hierarchy={this.props.templates}
            hierarchyStrategy={hierarchyStrategies.ancestorFirst}
            searchEnabled={true}
            selectAllEnabled={true}
            component={FinalFormTreePopper}
            id="outreachTemplate"
            onValueChange={this.handleMultiSelectChange('templatesSelected')}
            value={this.props.templatesSelected}
            errorText="No templates selected"
            PopperInputProps={{ PopperProps: { popperwidth: this.popperWidth } }}
          />
        </FormControl>
      );
    }
  }

  renderBindery() {
    const binderyConfig = this.props.binderyConfig;
    const binderyAccess = binderyConfig.roles;
    const binderyAccessSelections = this.props.multiEdit
      ? [{ value: 'unchanged', display: 'Unchanged' }].concat(binderyAccess)
      : binderyAccess;

    return (
      <FormControl className={this.props.classes.formControl} disabled>
        <InputLabel shrink>Bindery</InputLabel>
        <Select value={this.props.binderySelected} onChange={this.handleSelectChange('binderySelected')}>
          {renderComplexSelectItems(binderyAccessSelections, 'value', 'display')}
        </Select>
      </FormControl>
    );
  }

  renderMFA() {
    const mfaEnabled = this.props.mfaConfig;
    if (mfaEnabled && !this.props.multiEdit && this.props.mfaVerified) {
      return (
        <Grid container>
          <Grid item>
            <FormControl className={this.props.classes.formControl} data-test="mfaReset">
              <AlertDialog
                buttonText="Reset Device Enrollment"
                dialogDescription="Are you sure you want remove all enrolled devices for this user?"
                dialogTitle="TWO-FACTOR AUTHENTICATION"
                onChange={this.handleMfaReset}
              />
            </FormControl>
          </Grid>
        </Grid>
      );
    }
  }

  renderDesktopAccess(currentUser: CurrentUser | undefined) {
    const featureAccess = currentUser.role.includes('super_admin') || currentUser.desktopAccess;
    if (featureAccess) {
      return (
        <FormControlLabel
          control={
            <Checkbox
              data-test="desktop"
              checked={!!this.props.desktop}
              {...this.indeterminate(this.props.desktop)}
              onChange={this.handleCheckChange('desktop')}
            />
          }
          label="Desktop"
        />
      );
    }
  }

  renderCareManage(currentUser: CurrentUser | undefined) {
    const featureAccess = currentUser.role.includes('super_admin') || currentUser.careManager;
    if (featureAccess) {
      return (
        <FormControlLabel
          disabled
          control={
            <Checkbox
              data-test="care"
              checked={!!this.props.careManage}
              {...this.indeterminate(this.props.careManage)}
              onChange={this.handleCheckChange('careManage')}
            />
          }
          label="CM"
        />
      );
    }
  }

  renderCareManagerAdmin(currentUser: CurrentUser | undefined) {
    const featureAccess = currentUser.role.includes('super_admin') || currentUser.careManagerAdmin;
    if (featureAccess) {
      return (
        <FormControlLabel
          disabled
          control={
            <Checkbox
              data-test="careManagerAdmin"
              checked={!!this.props.careManagerAdmin}
              {...this.indeterminate(this.props.careManagerAdmin)}
              onChange={this.handleCheckChange('careManagerAdmin')}
            />
          }
          label="CM Admin"
        />
      );
    }
  }

  renderReferral(currentUser: CurrentUser | undefined) {
    const featureAccess = currentUser.role.includes('super_admin') || currentUser.referralAccess;
    if (featureAccess) {
      return (
        <FormControlLabel
          control={
            <Checkbox
              data-test="referral"
              checked={!!this.props.referral}
              {...this.indeterminate(this.props.referral)}
              onChange={this.handleCheckChange('referral')}
            />
          }
          label="Referral"
        />
      );
    }
  }

  renderPatientChartReferral(currentUser: CurrentUser | undefined) {
    const featureAccess =
      currentUser.role.includes('super_admin') || (currentUser.referralAccess && currentUser.referralPatientChart);
    if (featureAccess && this.props.referral) {
      return (
        <Grid container className={this.props.classes.checkboxes}>
          <Grid item xs>
            <FormControlLabel
              control={
                <Checkbox
                  data-test="referralPatientChart"
                  checked={!!this.props.referralPatientChart}
                  {...this.indeterminate(this.props.referralPatientChart)}
                  onChange={this.handleCheckChange('referralPatientChart')}
                />
              }
              label="Patient Chart (for Referral)"
            />
          </Grid>
        </Grid>
      );
    }
  }

  renderExportAbility(currentUser: CurrentUser | undefined) {
    const featureAccess = currentUser.role.includes('super_admin') || currentUser.exportAbility;
    if (featureAccess) {
      return (
        <FormControlLabel
          disabled
          control={
            <Checkbox
              data-test="export"
              checked={!!this.props.export}
              {...this.indeterminate(this.props.export)}
              onChange={this.handleCheckChange('export')}
            />
          }
          label="Export"
        />
      );
    }
  }

  renderHRAGroup() {
    const hraConfig = this.props.hraConfig;

    const electronicFormRole = this.bitMaskPermissions(
      hraConfig.roles.electronicForm,
      'electronicFormPermissions',
      this.props.currentUser
    );
    const hmrRole = this.bitMaskPermissions(hraConfig.roles.hmr, 'hmrPermissions', this.props.currentUser);
    const hpmRole = this.bitMaskPermissions(hraConfig.roles.hpm, 'hpmPermissions', this.props.currentUser);
    const hraRole = this.bitMaskPermissions(hraConfig.roles.hra, 'hraPermissions', this.props.currentUser);
    const hraAuditRole = this.bitMaskPermissions(hraConfig.roles.hraAudit, 'hraPermissions', this.props.currentUser);
    const paperFormRole = this.bitMaskPermissions(hraConfig.roles.paperForm, 'paperFormPermissions', this.props.currentUser);

    if (hraConfig.enabled) {
      return (
        <Fragment>
          <Grid container>
            <Grid item xs={12}>
              <FormControl className={this.props.classes.formControl}>
                <InputLabel shrink htmlFor="user-type-label-placeholder">
                  HMR Role
                </InputLabel>
                <Select value={this.props.hmrRoleSelected} onChange={this.handleSelectChange('hmrRoleSelected')}>
                  {renderComplexSelectItems(hmrRole, 'value', 'display')}
                </Select>
              </FormControl>

              <FormControl className={this.props.classes.formControl}>
                <InputLabel shrink htmlFor="user-type-label-placeholder">
                  HPM Role
                </InputLabel>
                <Select value={this.props.hpmRoleSelected} onChange={this.handleSelectChange('hpmRoleSelected')}>
                  {renderComplexSelectItems(hpmRole, 'value', 'display')}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={this.props.classes.formControl}>
                <InputLabel shrink htmlFor="user-type-label-placeholder">
                  HRA Role
                </InputLabel>
                <Select value={this.props.hraRoleSelected} onChange={this.handleSelectChange('hraRoleSelected')}>
                  {renderComplexSelectItems(hraRole, 'value', 'display')}
                </Select>
              </FormControl>

              <FormControl className={this.props.classes.formControl}>
                <InputLabel shrink htmlFor="user-type-label-placeholder">
                  HRA Audit Role
                </InputLabel>
                <Select value={this.props.hraAuditRoleSelected} onChange={this.handleSelectChange('hraAuditRoleSelected')}>
                  {renderComplexSelectItems(hraAuditRole, 'value', 'display')}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={this.props.classes.formControl}>
                <InputLabel shrink htmlFor="user-type-label-placeholder">
                  Electronic Form Role
                </InputLabel>
                <Select
                  value={this.props.electronicFormRoleSelected}
                  onChange={this.handleSelectChange('electronicFormRoleSelected')}
                >
                  {renderComplexSelectItems(electronicFormRole, 'value', 'display')}
                </Select>
              </FormControl>

              <FormControl className={this.props.classes.formControl}>
                <InputLabel shrink htmlFor="user-type-label-placeholder">
                  Paper Form Role
                </InputLabel>
                <Select value={this.props.paperFormRoleSelected} onChange={this.handleSelectChange('paperFormRoleSelected')}>
                  {renderComplexSelectItems(paperFormRole, 'value', 'display')}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Fragment>
      );
    }
  }

  public render() {
    return (
      <Fragment>
        <Grid container className={this.props.classes.root}>
          <Grid item xs>
            <Typography variant="h6">Feature Access</Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs className="disabledMultiSelect">
            <FormControl disabled className={this.props.classes.multiSelect}>
              <InputLabel className={this.props.classes.multiSelectLabel}>Reports</InputLabel>
              <SuperHierarchyListPopper
                PopperInputProps={{ PopperProps: { popperwidth: this.popperWidth } }}
                hierarchy={this.props.reports}
                hierarchyStrategy={hierarchyStrategies.ancestorFirst}
                searchEnabled={true}
                selectAllEnabled={true}
                onValueChange={this.handleMultiSelectChange('reportsSelected')}
                value={this.props.reportsSelected}
                id="reports"
              />
            </FormControl>
          </Grid>
        </Grid>
        {this.renderPatientChartReferral(this.props.currentUser)}
        <Grid container>
          <Grid item xs className="disabledMultiSelect">
            <FormControl className={this.props.classes.multiSelect} disabled>
              <InputLabel className={this.props.classes.multiSelectLabel}>Initiatives</InputLabel>
              <SuperHierarchyListPopper
                PopperInputProps={{ PopperProps: { popperwidth: this.popperWidth } }}
                hierarchy={this.props.initiatives}
                hierarchyStrategy={hierarchyStrategies.ancestorFirst}
                searchEnabled={true}
                selectAllEnabled={true}
                onValueChange={this.handleMultiSelectChange('initiativesSelected')}
                value={this.props.initiativesSelected}
                id="initiatives"
              />
            </FormControl>
            <FormControl className={this.props.classes.multiSelect} disabled>
              <InputLabel className={this.props.classes.multiSelectLabel}>Dashboards</InputLabel>
              <SuperHierarchyListPopper
                PopperInputProps={{ PopperProps: { popperwidth: this.popperWidth } }}
                hierarchy={this.props.dashboards}
                hierarchyStrategy={hierarchyStrategies.ancestorFirst}
                searchEnabled={true}
                selectAllEnabled={true}
                onValueChange={this.handleMultiSelectChange('dashboardsSelected')}
                value={this.props.dashboardsSelected}
                id="dashboards"
              />
            </FormControl>
            <FormControl className={this.props.classes.multiSelect} disabled>
              <InputLabel className={this.props.classes.multiSelectLabel}>Widgets</InputLabel>
              <Field
                name="widgets"
                hierarchy={this.props.widgets}
                hierarchyStrategy={hierarchyStrategies.ancestorFirst}
                searchEnabled={true}
                selectAllEnabled={true}
                component={FinalFormTreePopper}
                id="widgets"
                onValueChange={this.handleMultiSelectChange('widgetsSelected')}
                value={this.props.widgetsSelected}
              />
            </FormControl>
            <FormControl className={this.props.classes.multiSelect}>
              <InputLabel className={this.props.classes.multiSelectLabel}>EWA Dashboards</InputLabel>
              <Field
                name="ewaDashboards"
                hierarchy={this.props.ewaDashboards}
                hierarchyStrategy={hierarchyStrategies.ancestorFirst}
                searchEnabled={true}
                selectAllEnabled={false}
                component={FinalFormTreePopper}
                id="ewaDashboards"
                onValueChange={this.handleMultiSelectChange('ewaDashboardsSelected')}
                value={this.props.ewaDashboardsSelected}
              />
            </FormControl>
          </Grid>
          <Grid item xs>
            {this.renderBindery()}
          </Grid>
        </Grid>
        <Grid container className={this.props.classes.checkboxes}>
          <Grid item xs>
            {this.renderOutreach(this.props.currentUser)}
            {this.renderExportAbility(this.props.currentUser)}
            {this.renderCareManage(this.props.currentUser)}
            {this.renderCareManagerAdmin(this.props.currentUser)}
            {this.renderDesktopAccess(this.props.currentUser)}
            {this.renderReferral(this.props.currentUser)}
            {this.renderOutreachTemplate(this.props.currentUser)}
            {this.renderHRAGroup()}
            {this.renderMFA()}
          </Grid>
        </Grid>
      </Fragment>
    );
  }

  private bitMaskPermissions(
    defaultBitMaskPermissions: Array<QDWExportRole<number>>,
    bitMaskType: keyof bitMaskFields,
    currentUser: CurrentUser
  ) {
    const filteredBitMaskPermissions = defaultBitMaskPermissions.filter(
      permission => permission.value <= get(currentUser, bitMaskType, 0)
    );
    return filteredBitMaskPermissions;
  }

  private indeterminate = (value: boolean | null) => {
    return this.props.multiEdit ? { indeterminate: value === null } : {};
  };

  private handleCheckChange = (stateKey: keyof IndeterminateTypes) => (event: React.ChangeEvent<HTMLInputElement>): void => {
    /* Controlled element that has 4 way indeterminate state */
    if (stateKey === 'referral' && !this.props[stateKey]) {
      this.props.onSelectChange('referralPatientChart', false);
      this.setState({
        referralPatientChart: false
      });
    }

    if (this.props[stateKey] === true && this.props.multiEdit) {
      if (stateKey === 'outreach') {
        this.props.onSelectChange('templatesSelected', []);
      }
      this.props.onSelectChange(stateKey, null);
    } else if (this.props[stateKey] === null) {
      this.props.onSelectChange(stateKey, false);
    } else {
      this.props.onSelectChange(stateKey, event.target.checked);
    }
  };
}

export default withStyles(styles)(FeatureAccessPanel);
