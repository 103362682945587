import qdwNext from 'qdw-next';
import BulkNumberCreation from 'qdw-next/components/Outreach/BulkCreate';

export function outreachBulkCreateReactDirective(reactDirective) {
  return reactDirective(BulkNumberCreation, undefined, {
    restrict: 'E'
  });
}

qdwNext.directive('outreachBulkCreate', ['reactDirective', outreachBulkCreateReactDirective]);
