import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import moment from 'moment';

import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import AppBar from '@material-ui/core/AppBar';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import sharedStyles from './shared-styles';
import { formatCampaignForEdit } from './utils';

const styles = theme => ({
  ...sharedStyles,
  selected: {
    backgroundColor: theme.palette.divider
  }
});

export class CampaignList extends Component {
  static defaultProps = {
    campaigns: [],
    selectedCampaign: null
  };

  static propTypes = {
    campaigns: PropTypes.arrayOf(PropTypes.object),
    classes: PropTypes.object,
    onSelectedCampaignChange: PropTypes.func.isRequired,
    selectedCampaign: PropTypes.object
  };

  handleAddNewClick = () => {
    this.props.onSelectedCampaignChange({});
  };

  handleCampaignClick = campaign => () => {
    campaign = cloneDeep(campaign);
    campaign = formatCampaignForEdit(campaign);
    this.props.onSelectedCampaignChange(campaign);
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <AppBar>
          <Toolbar>
            <Grid alignItems="center" container>
              <Grid item xs>
                <Typography color="inherit" variant="h6">
                  Campaigns
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Tooltip title="Add New Campaign">
                  <IconButton onClick={this.handleAddNewClick}>
                    <AddCircleOutline />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <List className={classNames(classes.scrollable)}>{this.renderListItems()}</List>
      </Fragment>
    );
  }

  renderListItems() {
    const { campaigns, classes, selectedCampaign } = this.props;
    const selectedId = get(selectedCampaign, 'id');

    return campaigns.map(campaign => (
      <ListItem
        button
        className={classNames({ [classes.selected]: campaign.id === selectedId })}
        divider={true}
        key={campaign.id}
        onClick={this.handleCampaignClick(campaign)}
      >
        <ListItemText primary={campaign.name} secondary={`Created ${moment(campaign.createdAt).format('DD.MMM.YYYY')}`} />
        <ChevronRight />
      </ListItem>
    ));
  }
}

export default withStyles(styles)(CampaignList);
