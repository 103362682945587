// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__inputContainer___fy0LD {\n  margin: 0 15px; }\n", "",{"version":3,"sources":["webpack://./app/frontend/qdw-next/common/components/SuperHierarchyList/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAc,EAAA","sourcesContent":[".inputContainer {\n  margin: 0 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": "styles-module__inputContainer___fy0LD"
};
export default ___CSS_LOADER_EXPORT___;
