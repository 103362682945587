import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import uniqBy from 'lodash/uniqBy';

import SuperHierarchyListPopper from 'qdw-next/common/components/SuperHierarchyListPopper';
import { MultiSelectHierarchy, hierarchyStrategies } from 'qdw-next/common/data';

import sharedStyles from '../shared-styles';
import { formatCampaignForUpsert } from '../utils';
import IntegerNumberFormat from './IntegerNumberFormat';
import CampaignWindows from './CampaignWindows';
import { normalizeCampaign, renderErrorMessages } from './utils';
import { renderComplexSelectItems } from 'qdw-next/common/formHelpers';
import { validateCampaign } from './validator';
import { SingleSelectHierarchy } from '../../../../common/data/hierarchy';

const styles = () => ({
  ...sharedStyles
});

export class CampaignForm extends Component {
  static defaultProps = {
    durationTypes: [],
    languages: [],
    lobs: [],
    memberProducts: [],
    measures: [],
    patient_lists: [],
    periodTypes: [],
    providers: [],
    providerAttributionTypes: [],
    sourceList: [],
    providerList: [],
    locationList: [],
    templates: [],
    timeZones: []
  };

  static propTypes = {
    campaign: PropTypes.object.isRequired,
    classes: PropTypes.object,
    durationTypes: PropTypes.arrayOf(PropTypes.object),
    languages: PropTypes.arrayOf(PropTypes.object),
    lobs: PropTypes.arrayOf(PropTypes.object),
    memberProducts: PropTypes.arrayOf(PropTypes.object),
    measures: PropTypes.arrayOf(PropTypes.object),
    patientLists: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func.isRequired,
    onUpsert: PropTypes.func.isRequired,
    periodTypes: PropTypes.arrayOf(PropTypes.object),
    providers: PropTypes.arrayOf(PropTypes.object),
    providerAttributionTypes: PropTypes.arrayOf(PropTypes.object),
    sourceList: PropTypes.arrayOf(PropTypes.object),
    providerList: PropTypes.arrayOf(PropTypes.object),
    locationList: PropTypes.arrayOf(PropTypes.object),
    templates: PropTypes.arrayOf(PropTypes.object),
    timeZones: PropTypes.arrayOf(PropTypes.object)
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { campaign: prevCampaign } = prevState;
    const { campaign: nextCampaign } = nextProps;

    if (isEqual(prevCampaign, nextCampaign)) {
      return null;
    }

    const campaign = normalizeCampaign(nextCampaign);
    const newState = { campaign, errors: {} };

    if (get(prevCampaign, 'id') === get(nextCampaign, 'id')) {
      newState.errors = prevState.errors;
    }

    return newState;
  }

  hierarchies = {};
  state = {
    campaign: null,
    errors: {},
    providerList: new MultiSelectHierarchy({
      dataItems: this.props.providerList,
      dataMemberPaths: { key: 'provider_id', display: 'provider_name' },
      flat: true
    }),
    locationList: new MultiSelectHierarchy({
      dataItems: this.props.locationList,
      dataMemberPaths: { key: 'location_id', display: 'location_name' },
      flat: true
    })
  };

  constructor(props) {
    super(props);

    this.createHierarchies();
  }

  componentDidUpdate(prevProps) {
    this.createHierarchies(prevProps);
  }

  createHierarchies(prevProps) {
    const { languages, providers, templates, lobs, memberProducts, sourceList, patientLists } = this.props;

    if (!isEqual(get(prevProps, 'languages'), languages)) {
      this.hierarchies.languages = new MultiSelectHierarchy({ dataItems: languages });
    }

    if (!isEqual(get(prevProps, 'lobs'), lobs)) {
      this.hierarchies.lobs = new MultiSelectHierarchy({ dataItems: lobs });
    }

    if (!isEqual(get(prevProps, 'memberProducts'), memberProducts)) {
      this.hierarchies.memberProducts = new MultiSelectHierarchy({ dataItems: memberProducts });
    }

    if (!isEqual(get(prevProps, 'providers'), providers)) {
      this.hierarchies.providers = new MultiSelectHierarchy({ dataItems: providers, dataMemberPaths: { key: 'path' } });
    }

    if (!isEqual(get(prevProps, 'sourceList'), sourceList)) {
      this.hierarchies.sourceList = new MultiSelectHierarchy({
        dataItems: sourceList,
        dataMemberPaths: { key: 'source_id', display: 'source_name' },
        flat: true
      });
    }

    if (!isEqual(get(prevProps, 'templates'), templates)) {
      this.hierarchies.templates = new SingleSelectHierarchy({ dataItems: templates });
    }

    if (!isEqual(get(prevProps, 'patientLists'), patientLists)) {
      this.hierarchies.patientLists = new SingleSelectHierarchy({
        dataItems: patientLists,
        dataMemberPaths: { key: 'id', display: 'name' },
        flat: true
      });
    }
  }

  render() {
    const { classes, durationTypes, measures, periodTypes, providerAttributionTypes, timeZones } = this.props;
    const { campaign, errors } = this.state;
    const campaignTypes = [
      { value: 'measure_based', display: 'Measure Based' },
      { value: 'patient_list', display: 'Patient List' },
      { value: 'appointment_triggered', display: 'Appointment Triggered' }
    ];

    const appointmentDurationTypes = [
      { display: 'Day', value: 0 },
      { display: 'Week', value: 1 },
      { display: 'Month', value: 2 }
    ];

    return (
      <Fragment>
        <AppBar>
          <Toolbar>
            <Grid alignItems="center" container>
              <Grid item xs>
                {this.renderTitle()}
              </Grid>
              <Grid container item justifyContent="flex-end" xs={2}>
                <Button onClick={this.handleCancel}>Cancel</Button>
              </Grid>
              <Grid container item justifyContent="flex-end" xs={2}>
                <Button onClick={this.handleUpsert}>Save</Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <form className={classNames(classes.padded, classes.scrollable)}>
          <Grid container>
            <Grid item xs>
              <FormControl error={!!errors.name}>
                <InputLabel htmlFor="name">Name</InputLabel>
                <Input id="name" onChange={this.handleChange('name')} value={campaign.name} />
                {renderErrorMessages(errors.name)}
              </FormControl>
            </Grid>

            <Grid alignItems={errors.name ? 'center' : 'flex-end'} container item xs={3} style={{ zIndex: 0 }}>
              <FormControlLabel
                control={<Switch checked={!!campaign.initialOptOut} onChange={this.handleCheckChange('initialOptOut')} />}
                label="Initial Opt Out?"
              />
            </Grid>

            <Grid alignItems={errors.name ? 'center' : 'flex-end'} container item xs={3} style={{ zIndex: 0 }}>
              <FormControlLabel
                control={<Switch checked={campaign.enabled} onChange={this.handleCheckChange('enabled')} />}
                label="Enabled?"
              />
            </Grid>
          </Grid>

          <FormControl error={!!errors.priority}>
            <InputLabel htmlFor="priority" shrink={true}>
              Campaign Priority
            </InputLabel>
            <Input
              id="priority"
              inputComponent={IntegerNumberFormat}
              inputProps={{ maxLength: 4 }}
              onChange={this.handleChange('priority')}
              value={campaign.priority}
            />
            {renderErrorMessages(errors.priority)}
          </FormControl>

          <FormControl error={!!errors.campaignType}>
            <InputLabel htmlFor="campaignType">Campaign Type</InputLabel>
            <Select
              inputProps={{ id: 'campaignType' }}
              onChange={this.handleCampaignTypeChange('campaignType')}
              value={campaign.campaignType}
            >
              {renderComplexSelectItems(campaignTypes, 'value', 'display')}
            </Select>
            {renderErrorMessages(errors.campaignType)}
          </FormControl>

          {this.renderMeasureSelection(campaign, measures, errors, durationTypes, periodTypes)}
          {this.renderAppointmentTriggeredOptions(campaign, appointmentDurationTypes, errors)}
          {this.renderPatientListOptions(campaign, errors, durationTypes)}
          <FormControl>
            <InputLabel htmlFor="lobs">Line of Business (LoBs)</InputLabel>
            <SuperHierarchyListPopper
              id="lobs"
              hierarchy={this.hierarchies.lobs}
              hierarchyStrategy={hierarchyStrategies.ancestorFirst}
              onValueChange={this.handleHierarchyValueChange('lobs')}
              searchEnabled={true}
              selectAllEnabled={true}
              value={campaign.lobs}
            />
          </FormControl>

          <FormControl>
            <InputLabel htmlFor="memberProducts">Member Products</InputLabel>
            <SuperHierarchyListPopper
              id="memberProducts"
              hierarchy={this.hierarchies.memberProducts}
              hierarchyStrategy={hierarchyStrategies.ancestorFirst}
              onValueChange={this.handleHierarchyValueChange('memberProducts')}
              searchEnabled={true}
              selectAllEnabled={true}
              value={campaign.memberProducts}
            />
          </FormControl>

          <FormControl error={!!errors.pcps}>
            <InputLabel htmlFor="pcps">Organizations</InputLabel>
            <SuperHierarchyListPopper
              id="pcps"
              hierarchy={this.hierarchies.providers}
              hierarchyStrategy={hierarchyStrategies.ancestorFirst}
              onValueChange={this.handleHierarchyValueChange('pcps')}
              searchEnabled={true}
              selectAllEnabled={true}
              value={campaign.pcps}
            />
            {renderErrorMessages(errors.pcps)}
          </FormControl>

          <FormControl error={!!errors.templateId}>
            <InputLabel htmlFor="templateId">Template</InputLabel>
            <SuperHierarchyListPopper
              id="templateId"
              hierarchy={this.hierarchies.templates}
              hierarchyStrategy={hierarchyStrategies.descendantOnly}
              onValueChange={this.handleHierarchyValueChange('templateId')}
              searchEnabled={true}
              value={campaign.templateId}
            />
            {renderErrorMessages(errors.templateId)}
          </FormControl>

          <FormControl error={!!errors.providerAttribution}>
            <InputLabel htmlFor={'providerAttribution'}>Provider Attribution</InputLabel>
            <Select
              inputProps={{ id: 'providerAttribution' }}
              onChange={this.handleChange('providerAttribution')}
              value={campaign.providerAttribution}
            >
              {renderComplexSelectItems(providerAttributionTypes, 'value', 'display')}
            </Select>
            {renderErrorMessages(errors.providerAttribution)}
          </FormControl>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl error={!!errors.minAge}>
                <InputLabel htmlFor="minAge" shrink={true}>
                  Minimum Patient Age
                </InputLabel>
                <Input
                  id="minAge"
                  inputComponent={IntegerNumberFormat}
                  onChange={this.handleChange('minAge')}
                  value={campaign.minAge}
                />
                {renderErrorMessages(errors.minAge)}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl error={!!errors.maxAge}>
                <InputLabel htmlFor="maxAge" shrink={true}>
                  Maximum Patient Age
                </InputLabel>
                <Input
                  id="minAge"
                  inputComponent={IntegerNumberFormat}
                  onChange={this.handleChange('maxAge')}
                  value={campaign.maxAge}
                />
                {renderErrorMessages(errors.maxAge)}
              </FormControl>
            </Grid>
          </Grid>

          <FormControl error={!!errors.languages}>
            <InputLabel htmlFor="languages">Languages</InputLabel>
            <SuperHierarchyListPopper
              id="languages"
              hierarchy={this.hierarchies.languages}
              hierarchyStrategy={hierarchyStrategies.ancestorFirst}
              onValueChange={this.handleHierarchyValueChange('languages')}
              searchEnabled={true}
              selectAllEnabled={true}
              value={campaign.languages}
            />
            {renderErrorMessages(errors.languages)}
          </FormControl>

          <CampaignWindows
            campaignWindows={campaign.windowsAttributes}
            errors={errors.windowsAttributes}
            onChange={this.handleChange('windowsAttributes')}
            timeZones={timeZones}
          />
        </form>
      </Fragment>
    );
  }

  renderTitle() {
    const { campaign } = this.state;

    return (
      <Typography color="inherit" variant="h6">
        {`${campaign.id ? 'Edit' : 'Create'} Campaign`}
      </Typography>
    );
  }

  renderMeasureSelection(campaign, measures, errors, durationTypes, periodTypes) {
    if (campaign.campaignType === 'measure_based') {
      return (
        <div>
          <Grid container justifyContent="flex-end">
            <Grid item xs={11}>
              <FormControl error={!!errors.measureId}>
                <InputLabel htmlFor="measureId">Measure</InputLabel>
                <Select inputProps={{ id: 'measureId' }} onChange={this.handleChange('measureId')} value={campaign.measureId}>
                  {renderComplexSelectItems(measures, 'id', 'name')}
                </Select>
                {renderErrorMessages(errors.measureId)}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid item xs={11}>
              <FormControl error={!!errors.periodTypeId}>
                <InputLabel htmlFor="periodTypeId">Period Type</InputLabel>
                <Select
                  inputProps={{ id: 'periodTypeId' }}
                  onChange={this.handleChange('periodTypeId')}
                  value={campaign.periodTypeId}
                >
                  {renderComplexSelectItems(periodTypes, 'periodTypeId', 'periodType')}
                </Select>
                {renderErrorMessages(errors.measureId)}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item xs={5}>
              <FormControl error={!!errors.duration}>
                <InputLabel htmlFor="duration" shrink={true}>
                  Duration
                </InputLabel>
                <Input
                  id="duration"
                  inputComponent={IntegerNumberFormat}
                  onChange={this.handleChange('duration')}
                  value={campaign.duration}
                />
                {renderErrorMessages(errors.duration)}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl error={!!errors.durationType}>
                <InputLabel htmlFor="durationType">Duration Type</InputLabel>
                <Select
                  inputProps={{ id: 'durationType' }}
                  onChange={this.handleChange('durationType')}
                  value={campaign.durationType}
                >
                  {renderComplexSelectItems(durationTypes, 'value', 'display')}
                </Select>
                {renderErrorMessages(errors.durationType)}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item xs={5}>
              <FormControl error={!!errors.daysSinceLeastRecentVisit}>
                <InputLabel htmlFor="daysSinceLeastRecentVisit" shrink={true}>
                  Days Since Least Recent Visit
                </InputLabel>
                <Input
                  id="daysSinceLeastRecentVisit"
                  inputComponent={IntegerNumberFormat}
                  onChange={this.handleChange('daysSinceLeastRecentVisit')}
                  value={campaign.daysSinceLeastRecentVisit}
                />
                {renderErrorMessages(errors.daysSinceLeastRecentVisit)}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl error={!!errors.daysSinceMostRecentVisit}>
                <InputLabel htmlFor="daysSinceMostRecentVisit" shrink={true}>
                  Days Since Most Recent Visit
                </InputLabel>
                <Input
                  id="daysSinceMostRecentVisit"
                  inputComponent={IntegerNumberFormat}
                  onChange={this.handleChange('daysSinceMostRecentVisit')}
                  value={campaign.daysSinceMostRecentVisit}
                />
                {renderErrorMessages(errors.daysSinceMostRecentVisit)}
              </FormControl>
            </Grid>
          </Grid>
        </div>
      );
    }
  }

  renderAppointmentTriggeredOptions(campaign, durationTypes, errors) {
    if (campaign.campaignType === 'appointment_triggered') {
      return (
        <div>
          <Grid container justifyContent="flex-end">
            <Grid item xs={11}>
              <FormControl error={!!errors.sourceList}>
                <InputLabel htmlFor="sourceList">Scheduling Source</InputLabel>
                <SuperHierarchyListPopper
                  id="sourceList"
                  hierarchy={this.hierarchies.sourceList}
                  hierarchyStrategy={hierarchyStrategies.ancestorFirst}
                  onValueChange={this.handleApptProviderChange('sourceList')}
                  searchEnabled={true}
                  selectAllEnabled={false}
                  value={campaign.sourceList}
                />
                {renderErrorMessages(errors.sourceList)}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid item xs={11}>
              <FormControl error={!!errors.locationList}>
                <InputLabel htmlFor="locationList">Location</InputLabel>
                <SuperHierarchyListPopper
                  id="locationList"
                  hierarchy={this.state.locationList}
                  hierarchyStrategy={hierarchyStrategies.ancestorFirst}
                  onValueChange={this.handleApptProviderChange('locationList')}
                  searchEnabled={true}
                  selectAllEnabled={true}
                  value={campaign.locationList}
                />
                {renderErrorMessages(errors.locationList)}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid item xs={11}>
              <FormControl error={!!errors.providerList}>
                <InputLabel htmlFor="providerList">Provider/Resource</InputLabel>
                <SuperHierarchyListPopper
                  id="providerList"
                  hierarchy={this.state.providerList}
                  hierarchyStrategy={hierarchyStrategies.ancestorFirst}
                  onValueChange={this.handleHierarchyValueChange('providerList')}
                  searchEnabled={true}
                  selectAllEnabled={true}
                  value={campaign.providerList}
                />
                {renderErrorMessages(errors.providerList)}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item xs={5}>
              <FormControl error={!!errors.apptDuration}>
                <InputLabel htmlFor="location" shrink={true}>
                  Duration Prior to Appointment
                </InputLabel>
                <Input
                  id="apptDuration"
                  inputComponent={IntegerNumberFormat}
                  onChange={this.handleChange('apptDuration')}
                  value={campaign.apptDuration}
                />
                {renderErrorMessages(errors.apptDuration)}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl error={!!errors.apptDurationType}>
                <InputLabel htmlFor="location">Duration Type</InputLabel>
                <Select
                  inputProps={{ id: 'apptDurationType' }}
                  onChange={this.handleChange('apptDurationType')}
                  value={campaign.apptDurationType}
                >
                  {renderComplexSelectItems(durationTypes, 'value', 'display')}
                </Select>
                {renderErrorMessages(errors.apptDurationType)}
              </FormControl>
            </Grid>
          </Grid>
        </div>
      );
    }
  }

  renderPatientListOptions(campaign, errors, durationTypes) {
    if (campaign.campaignType === 'patient_list') {
      return (
        <div>
          <Grid container justifyContent="flex-end">
            <Grid item xs={11}>
              <FormControl error={!!errors.patientListId}>
                <InputLabel htmlFor="patientListId">Patient Lists</InputLabel>

                <SuperHierarchyListPopper
                  id="patientListId"
                  hierarchy={this.hierarchies.patientLists}
                  hierarchyStrategy={hierarchyStrategies.descendantOnly}
                  onValueChange={this.handleHierarchyValueChange('patientListId')}
                  searchEnabled={true}
                  value={campaign.patientListId}
                />
                {renderErrorMessages(errors.patientListId)}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item xs={5}>
              <FormControl error={!!errors.duration}>
                <InputLabel htmlFor="duration" shrink={true}>
                  Duration
                </InputLabel>
                <Input
                  id="duration"
                  inputComponent={IntegerNumberFormat}
                  onChange={this.handleChange('duration')}
                  value={campaign.duration}
                />
                {renderErrorMessages(errors.duration)}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl error={!!errors.durationType}>
                <InputLabel htmlFor="durationType">Duration Type</InputLabel>
                <Select
                  inputProps={{ id: 'durationType' }}
                  onChange={this.handleChange('durationType')}
                  value={campaign.durationType}
                >
                  {renderComplexSelectItems(durationTypes, 'value', 'display')}
                </Select>
                {renderErrorMessages(errors.durationType)}
              </FormControl>
            </Grid>
          </Grid>
        </div>
      );
    }
  }

  handleCancel = () => this.props.onChange(null);

  handleChange = propName => event => {
    const campaign = cloneDeep(this.state.campaign);
    campaign[propName] = event.target.value;

    const prevErrors = this.state.errors;
    const propErrors = validateCampaign(campaign, false)[propName];
    this.setState({ errors: { ...prevErrors, [propName]: propErrors } });

    this.props.onChange(campaign);
  };

  handleCampaignTypeChange = propName => event => {
    const campaign = cloneDeep(this.state.campaign);
    campaign[propName] = event.target.value;

    // Reset measureId / sourceList when flipping between
    if (event.target.value === 'appointment_triggered') {
      campaign.measureId = null;
      campaign.periodTypeId = '';
    } else if (event.target.value === 'measure_based') {
      campaign.sourceList = [];
      campaign.providerList = [];
      campaign.locationList = [];
    }
    campaign.duration = null;
    campaign.durationType = null;
    this.props.onChange(campaign);
  };

  handleCheckChange = propName => (event, checked) => this.handleChange(propName)({ target: { value: checked } });

  handleHierarchyValueChange = propName => values => this.handleChange(propName)({ target: { value: values } });

  handleApptProviderChange = propName => values => {
    const campaign = cloneDeep(this.state.campaign);
    const apptProviderLocations = this.props.apptProviderLocations;

    campaign[propName] = values;

    if (propName === 'sourceList') {
      this.setState({
        locationList: new MultiSelectHierarchy({
          dataItems: uniqBy(
            apptProviderLocations.filter(apl => campaign.sourceList.includes(apl.source_id)),
            'location_id'
          ),
          dataMemberPaths: { key: 'location_id', display: 'location_name' },
          flat: true
        })
      });
    }

    if (propName === 'sourceList') {
      const sourcesLocations = apptProviderLocations
        .filter(apl => campaign.sourceList.includes(apl.source_id))
        .map(x => x.location_id);

      // Update locationList to reflect a deselection of a sourceList
      campaign.locationList = campaign.locationList.filter(selectedProvider => sourcesLocations.includes(selectedProvider));
    }

    if (propName === 'locationList') {
      const providerLocations = apptProviderLocations
        .filter(apl => campaign.locationList.includes(apl.location_id))
        .map(x => x.provider_id);

      // Update providerList to reflect a deselection of a locationList
      campaign.providerList = campaign.providerList.filter(selectedProvider => providerLocations.includes(selectedProvider));

      this.setState({
        providerList: new MultiSelectHierarchy({
          dataItems: uniqBy(
            apptProviderLocations.filter(
              apl => campaign.locationList.includes(apl.location_id) || campaign.locationList.includes(0)
            ),
            'provider_id'
          ),
          dataMemberPaths: { key: 'provider_id', display: 'provider_name' },
          flat: true
        })
      });
    }

    this.props.onChange(campaign);
  };

  handleUpsert = () => {
    let campaign = cloneDeep(this.state.campaign);

    const errors = validateCampaign(campaign);
    this.setState({ errors });
    if (Object.keys(errors).length) {
      return;
    }

    campaign = formatCampaignForUpsert(campaign);
    this.props.onUpsert(campaign);
  };
}

export default withStyles(styles)(CampaignForm);
