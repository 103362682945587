import PropTypes from 'prop-types';
import React, { Component } from 'react';

import isEqual from 'lodash/isEqual';

import HierarchyList from '../HierarchyList';

function hierarchyValue(props, state) {
  const { hierarchyStrategy } = props;
  const { hierarchy } = state;
  const value = hierarchyStrategy(hierarchy).map(n => n.key);
  return hierarchy.multiple ? value : value[0];
}

function isSelectAllEnabled(props, state) {
  const { selectAllEnabled } = props;
  const { hierarchy } = state;

  return hierarchy.multiple && !!selectAllEnabled;
}

function isSelectAllChecked(props, state) {
  return isSelectAllEnabled(props, state) && isEqual(props.value, props.sentinelValue);
}

export default function withState(WrappedComponent) {
  return class extends Component {
    static defaultProps = {
      sentinelValue: [0],
      selectAllEnabled: false
    };

    static propTypes = {
      hierarchy: PropTypes.object,
      hierarchyStrategy: PropTypes.func,
      onHierarchyChange: PropTypes.func,
      onSearchChange: PropTypes.func,
      onSelectAllChange: PropTypes.func,
      onValueChange: PropTypes.func.isRequired,
      selectAllEnabled: PropTypes.bool,
      sentinelValue: PropTypes.any,
      value: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
        PropTypes.number,
        PropTypes.string
      ]).isRequired
    };

    static getDerivedStateFromProps(nextProps, prevState) {
      const newState = {};

      if (nextProps.hierarchy !== prevState.hierarchy) {
        newState.hierarchy = nextProps.hierarchy;
      }

      const selectAllChecked = isSelectAllChecked(nextProps, { ...prevState, ...newState });
      if (selectAllChecked !== prevState.selectAllChecked) {
        newState.selectAllChecked = selectAllChecked;
      }

      if (!selectAllChecked && !isEqual(nextProps.value, hierarchyValue(nextProps, { ...prevState, ...newState }))) {
        const { hierarchy } = { ...prevState, ...newState };
        hierarchy.setChecked(nextProps.value);
        newState.hierarchy = nextProps.hierarchy;
      }

      return !!Object.keys(newState).length ? newState : null;
    }

    state = {
      hierarchy: null,
      searchValue: '',
      selectAllChecked: false
    };

    handleHierarchyChange = event => {
      const { eventType, hierarchy, nextPropValue, node, propName } = event;
      const { onValueChange, onHierarchyChange } = this.props;

      // One caveat with this approach is that if the value isn't updated by the consumer via onValueChange, the hierarchy and
      // value can become out of sync - this only applies to the "checked" event type. In this scenario when the consumer
      // component re-renders, the hierarchy will be reset to value passed.  While unlikely, this is a scenario that is worth
      // mentioning and is the reason why value/onValueChange are marked as required.
      node[propName] = nextPropValue;
      this.setState({ hierarchy });

      switch (eventType) {
        case HierarchyList.eventTypes.checked: {
          onValueChange(hierarchyValue(this.props, this.state));
          break;
        }
      }

      onHierarchyChange && onHierarchyChange(event);
    };

    handleSearchChange = event => {
      const { onSearchChange } = this.props;

      this.setState({ searchValue: event.target.value });

      onSearchChange && onSearchChange(event);
    };

    handleSelectAllChange = (event, checked) => {
      const { onSelectAllChange, onValueChange, sentinelValue } = this.props;

      this.setState({ selectAllChecked: checked });
      const value = checked ? sentinelValue : hierarchyValue(this.props, this.state);
      onValueChange(value);
      onSelectAllChange && onSelectAllChange(event, checked);
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onHierarchyChange={this.handleHierarchyChange}
          onSearchChange={this.handleSearchChange}
          onSelectAllChange={this.handleSelectAllChange}
        />
      );
    }
  };
}
