export function composeValidators(...validators) {
  return function(value) {
    return validators.reduce((error, validator) => error || validator(value), undefined);
  };
}

export function required(message = 'Required') {
  return function(value) {
    return value ? undefined : message;
  };
}
