import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';

import { User } from 'qdw-next/common/data/models';
import DateDisplay from 'qdw-next/common/components/DateDisplay';

import styles from './styles.module.scss';

interface Props {
  user: User;
  selectedUsers: number[];
  onDeleteUser: (id: number) => void;
  style: any;
  onToggleSelectedUser(id: number): void;
}

export default class UserDetails extends Component<Props> {
  readonly dateFormat = 'D.MMM.YYYY';

  public handleDelete = (event: React.MouseEvent<HTMLElement>): void => {
    const { user, onDeleteUser } = this.props;
    onDeleteUser(user.id);
  };

  public handleToggle = (event: React.MouseEvent<HTMLElement>): void => {
    const { user, onToggleSelectedUser } = this.props;
    onToggleSelectedUser(user.id);
  };

  public render() {
    const { selectedUsers, user } = this.props;
    const isSelected = selectedUsers.indexOf(user.id) !== -1;
    const disabledClass = user.disabled ? 'disabled' : '';
    const selectedStyle = `userDetails userSelected_${isSelected} ${disabledClass}`;

    return (
      <Grid container className={selectedStyle} style={this.props.style}>
        <Grid item xs={10} onClick={this.handleToggle} className={styles.userDetails_name}>
          <div className={styles.detailMainText}>
            {user.username}
            {user.temporary && ' (temp)'} {user.groupName ? ` •  ${user.groupName}` : ''}{' '}
            {user.category ? ` •  ${user.category}` : ''}
            {user.disabled ? (
              <span>
                {' '}
                • <DateDisplay label="Disabled on" dateFormat="D.MMM.YYYY" date={user.disabledAt!} />
              </span>
            ) : null}
          </div>
          <div className={styles.detailSubText}>
            <span>{user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : 'N/A'}</span>
            <span className={styles.secondaryUserInfo}>
              {' • '}
              <DateDisplay label="Created" dateFormat="D.MMM.YYYY" date={user.createdAt || ''} />
              {' • '}
              <DateDisplay label="Updated" dateFormat="D.MMM.YYYY" date={user.updatedAt || ''} />
              {' • '}
              <DateDisplay
                alternateText="Never"
                labelTooltip="Last login date"
                label="Last"
                dateFormat="D.MMM.YYYY"
                date={user.currentSignInAt || ''}
              />
            </span>
          </div>
        </Grid>
        <Grid item xs={1} onClick={this.handleToggle} className="userDetails_select">
          <Checkbox checked={isSelected} value={user.id.toString()} data-test={`select-${user.id.toString()}`} />
        </Grid>
      </Grid>
    );
  }
}
