import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import UserEditForm from './UserEditForm';
import { CurrentUser, User } from 'qdw-next/common/data/models';
import ApiService from './apiService';

interface Props {
  selectedUsers: number[];
  currentUser: CurrentUser;
  isNewUser: boolean;
  multiEdit: boolean;
  onUserCreated: () => void;
}

interface State {
  userData: User;
}

type TextFields = Pick<
  User,
  | 'username'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'npi'
  | 'category'
  | 'credentials'
  | 'address'
  | 'city'
  | 'state'
  | 'zip'
  | 'phoneNumber'
>;

export default class UserEdit extends Component<Props, State> {
  state = { userData: {} as User };

  public componentDidUpdate(prevProps: Props) {
    if (this.props.selectedUsers !== prevProps.selectedUsers) {
      this.setState({ userData: {} as User });
      const blankUser: User = {
        username: '',
        binderyAccess: 'unchanged',
        careManager: null,
        desktopAccess: null,
        referralAccess: null,
        referralPatientChart: null,
        dashboards: [],
        widgets: [],
        disabled: false,
        disabledAt: null,
        email: '',
        exportAbility: null,
        firstName: '',
        groupId: 0,
        type: 'unchanged',
        groupName: '',
        electronicFormPermissions: 0,
        hmrPermissions: 0,
        hpmPermissions: 0,
        hraAuditPermissions: 0,
        hraPermissions: 0,
        paperFormPermissions: 0,
        id: 0,
        initiatives: [],
        lastName: '',
        mfaVerified: false,
        name: null,
        npi: '',
        outreachAbility: null,
        outreachTemplates: [],
        phoneNumber: '',
        address: '',
        city: '',
        state: 'unchanged',
        zip: '',
        category: '',
        credentials: '',
        plans: [],
        providerType: 'unchanged',
        providers: [],
        reports: [],
        role: 'unchanged',
        sources: [],
        cognitoUserStatus: null
      };

      if (this.props.selectedUsers.length === 0 && this.props.isNewUser) {
        this.setState({ userData: blankUser });
      }

      if (this.props.selectedUsers.length === 1) {
        this.loadUserData(this.props.selectedUsers[0]).then(userData => {
          this.setState({ userData });
        });
      }

      if (this.props.selectedUsers.length > 1) {
        this.setState({ userData: blankUser });
      }
    }
  }

  public async loadUserData(user: number) {
    const apiService = new ApiService();
    const results = await apiService.getUser(user);
    const userData = results.data;

    userData.username = this.defaultNullEmptyString(userData, 'username');
    userData.email = this.defaultNullEmptyString(userData, 'email');
    userData.firstName = this.defaultNullEmptyString(userData, 'firstName');
    userData.lastName = this.defaultNullEmptyString(userData, 'lastName');
    userData.npi = this.defaultNullEmptyString(userData, 'npi');
    userData.category = this.defaultNullEmptyString(userData, 'category');
    userData.credentials = this.defaultNullEmptyString(userData, 'credentials');
    userData.address = this.defaultNullEmptyString(userData, 'address');
    userData.city = this.defaultNullEmptyString(userData, 'city');
    userData.state = this.defaultNullEmptyString(userData, 'state');
    userData.zip = this.defaultNullEmptyString(userData, 'zip');
    userData.phoneNumber = this.defaultNullEmptyString(userData, 'phoneNumber');

    userData.electronicFormPermissions = this.defaultHraZero(userData, 'electronicFormPermissions');
    userData.hmrPermissions = this.defaultHraZero(userData, 'hmrPermissions');
    userData.hraPermissions = this.defaultHraZero(userData, 'hraPermissions');
    userData.hraAuditPermissions = this.defaultHraZero(userData, 'hraAuditPermissions');
    userData.hpmPermissions = this.defaultHraZero(userData, 'hpmPermissions');
    userData.paperFormPermissions = this.defaultHraZero(userData, 'paperFormPermissions');

    userData.binderyAccess = userData.binderyAccess || (window as any).QDW_EXPORTS.bindery.roles[0].value;

    this.setState({ userData });
    return userData;
  }

  public render() {
    const { selectedUsers } = this.props;
    const selectedTitle = `${selectedUsers.length} User${selectedUsers.length === 1 ? '' : 's'} Selected`;
    const binderyConfig = (window as any).QDW_EXPORTS.bindery;
    const hraConfig = (window as any).QDW_EXPORTS.healthRiskAssessment;
    const mfaConfig = (window as any).QDW_EXPORTS.mfaSupported;
    const cognitoConfig = (window as any).QDW_EXPORTS.applicationFeatures.cognito;

    return (
      <Grid item xs={6}>
        <Card>
          <CardHeader title={selectedTitle} />
          <CardContent id="user-edit-form">
            <UserEditForm
              currentUser={this.props.currentUser}
              binderyConfig={binderyConfig}
              mfaConfig={mfaConfig}
              hraConfig={hraConfig}
              cognitoConfig={cognitoConfig}
              user={this.state.userData}
              multiEdit={this.props.multiEdit}
              selectedUsers={this.props.selectedUsers}
              isNewUser={this.props.isNewUser}
              onUserCreated={this.props.onUserCreated}
            />
          </CardContent>
        </Card>
      </Grid>
    );
  }

  private defaultNullEmptyString(user: User, field: keyof TextFields) {
    return user[field] || '';
  }

  private defaultHraZero(
    user: User,
    field: keyof Pick<
      User,
      | 'electronicFormPermissions'
      | 'hraPermissions'
      | 'hraAuditPermissions'
      | 'hmrPermissions'
      | 'hpmPermissions'
      | 'paperFormPermissions'
    >
  ) {
    return user[field] || 0;
  }
}
