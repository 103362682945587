import PropTypes from 'prop-types';
import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

export default function SelectField({
  children,
  input: { onBlur, onChange, value, ...restInput },
  isSubmitting,
  label,
  meta: { error, touched }
}) {
  return (
    <FormControl error={error && touched} fullWidth margin="dense">
      <InputLabel>{label}</InputLabel>
      <Select disabled={isSubmitting} onBlur={onBlur} onChange={onChange} value={value} inputProps={restInput}>
        {children}
      </Select>
      {error && touched ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
}

SelectField.defaultProps = {
  input: { value: '' },
  isSubmitting: false,
  meta: {}
};

SelectField.propTypes = {
  children: PropTypes.node,
  isSubmitting: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object
};
