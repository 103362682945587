import PropTypes from 'prop-types';
import React, { Component } from 'react';

import classNames from 'classnames';
import get from 'lodash/get';

import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';

import { formatUpside, sourceFlagNameForValue } from './util';
import styles from './styles.module.scss';

export default class ConditionDisplay extends Component {
  static defaultProps = {
    isEditing: false,
    isSelected: false,
    statuses: []
  };

  static propTypes = {
    condition: PropTypes.object.isRequired,
    hash: PropTypes.string.isRequired,
    isEditing: PropTypes.bool,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
    statuses: PropTypes.arrayOf(PropTypes.object)
  };

  handleClick = () => {
    const { hash, isSelected, onClick } = this.props;

    !isSelected && onClick && onClick(hash);
  };

  renderStatusLine() {
    const { condition, isEditing, statuses } = this.props;

    if (condition.riskStatusId || condition.notes) {
      const status = statuses.find(s => s.id === condition.riskStatusId);

      const statusNotes = [get(status, 'description'), get(condition, 'notes')].filter(text => !!text).join(' | ');

      return (
        <div className={styles.conditionStatus}>
          <Collapse in={!isEditing}>
            <Typography noWrap={true}>
              <Tooltip title={statusNotes}>
                <span>{statusNotes}</span>
              </Tooltip>
            </Typography>
          </Collapse>
        </div>
      );
    }
  }

  render() {
    const { condition, isEditing, isSelected } = this.props;

    const sourceFlagName = sourceFlagNameForValue(condition.sourceFlag);

    const conditionClasses = classNames(styles.condition, {
      [styles.conditionIsEditing]: isEditing,
      [styles.conditionIsSelected]: !isEditing && isSelected,
      [styles.conditionIsSelectable]: !isEditing
    });

    const sourceFlagClasses = classNames(
      'fa',
      `fa-${sourceFlagName.toLowerCase()}`,
      styles.conditionSourceFlag,
      styles[`conditionSourceFlag${sourceFlagName}`]
    );

    return (
      <a className={conditionClasses} href="javascript:void(0);" onClick={this.handleClick}>
        <span className={styles.conditionSummary}>
          <strong>
            <em>[{condition.category}]</em> {condition.riskDescription}
          </strong>
          <em>{condition.sourceDesc}</em>
        </span>
        <Tooltip title={formatUpside(condition.upside)}>
          <span className={styles.conditionUpside}>
            <span className={styles.conditionUpsideValue}>{formatUpside(condition.upside)}</span>
            <span className={sourceFlagClasses} />
          </span>
        </Tooltip>
        {this.renderStatusLine()}
      </a>
    );
  }
}
