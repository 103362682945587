import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import Paper from '@material-ui/core/Paper';

import styles from './styles.module.scss';

export default function ConditionContainer({ children, component, isEditing }) {
  const classes = classNames(styles.contentListItem, { [styles.contentListItemIsEditing]: isEditing });

  return (
    <Paper className={classes} component={component} elevation={isEditing ? 2 : 0}>
      {children}
    </Paper>
  );
}

ConditionContainer.defaultProps = {
  component: 'li'
};

ConditionContainer.propTypes = {
  children: PropTypes.node,
  component: PropTypes.any,
  isEditing: PropTypes.bool
};
