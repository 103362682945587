import cloneDeep from 'lodash/fp/cloneDeep';
import flow from 'lodash/fp/flow';
import groupBy from 'lodash/fp/groupBy';
import map from 'lodash/fp/map';
import mapValues from 'lodash/fp/mapValues';

import Schema from 'validate';

const positiveNumber = val => val > 0;
const areaCode = val => val >= 100 && val <= 999;

const outreachBulkNumberValidator = new Schema({
  phoneNumberCount: {
    message: {
      required: 'required',
      type: 'type',
      positiveNumber: 'must be greater than 0'
    },
    use: { positiveNumber },
    required: true,
    type: Number
  },
  areaCode: {
    message: {
      required: 'required',
      type: 'type',
      areaCode: 'must be a 3 digit number'
    },
    use: { areaCode },
    required: true,
    type: Number
  }
});

const transformErrors = flow(
  map(error => {
    const { message, path } = error;
    return {
      [message]: true,
      path
    };
  }),
  groupBy(error => error.path),
  mapValues(errors =>
    errors.reduce((acc, error) => {
      delete error.path;
      return { ...acc, ...error };
    }, {})
  )
);

export function validateParameters(parameters) {
  if (!parameters) {
    return {};
  }

  // validate modifies the original object and that is undesirable
  let clonedParameters = cloneDeep(parameters);

  let errors = outreachBulkNumberValidator.validate(clonedParameters);
  errors = transformErrors(errors);

  return errors;
}
