import React, { Fragment } from 'react';
import Select from '@material-ui/core/Select';
import omitBy from 'lodash/omitBy';
import includes from 'lodash/includes';

import FormHelperText from '@material-ui/core/FormHelperText';
import { FieldRenderProps } from 'react-final-form';

interface FinalFormSelectProps extends FieldRenderProps {
  options?: any;
  errorText?: string;
  onChange?: () => void;
}

const FinalFormSelect: React.SFC<FinalFormSelectProps> = obj => {
  const input = obj.input;
  const meta = obj.meta;
  const onChange = obj.onChange;
  const options = obj.options;
  const rest = omitBy(obj, (_, key) => includes(['input', 'meta', 'errorText'], key));
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <Fragment>
      <Select {...rest} name={name} value={input.value} error={showError} onChange={onChange}>
        {options}
      </Select>
      {showError && <FormHelperText error={true}>{obj.errorText}</FormHelperText>}
    </Fragment>
  );
};

export default FinalFormSelect;
