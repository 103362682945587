import React from 'react';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';

interface Props {
  label?: string;
  labelTooltip?: string;
  alternateText?: string;
  dateFormat: string;
  date: string;
}

function getLabel({ label, labelTooltip }: Props) {
  if (labelTooltip) {
    return (
      <Tooltip title={labelTooltip}>
        <span>{label}:</span>
      </Tooltip>
    );
  }
  return <span>{label}:</span>;
}

export default function DateDisplay(props: Props) {
  const alternate = props.alternateText || 'n/a';
  const finalFormat = props.dateFormat || 'MM-DD-YYYY hh:mm a';
  return (
    <span>
      {props.label && getLabel(props)} {props.date ? moment(props.date).format(finalFormat) : alternate}
    </span>
  );
}
