import qdwNext from 'qdw-next';
import Dashboard from 'qdw-next/components/Outreach/Dashboard';

export const proxyDirective = reactDirective => reactDirective(Dashboard);

export const proxyDirectiveName = 'proxyOutreachDashboard';

qdwNext.directive(proxyDirectiveName, ['reactDirective', proxyDirective]);

qdwNext.controller('OutreachDashboardCtrl', [function() {}]);

export const ngDirective = () => ({
  bindToController: {},
  controller: 'OutreachDashboardCtrl',
  controllerAs: 'ctrl',
  restrict: 'E',
  scope: {},
  template: `
    <proxy-outreach-dashboard>
    </proxy-outreach-dashboard>
  `
});

export const ngDirectiveName = 'outreachDashboard';

qdwNext.directive(ngDirectiveName, ngDirective);
