import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import every from 'lodash/every';
import objectValues from 'lodash/values';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Form } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import UserInformationPanel from './UserInformationPanel';
import FeatureAccessPanel from './FeatureAccessPanel';
import ApiService from './apiService';
import PageMultiSelectService from './PageMultiSelectService';
import PatientAccessPanel from './PatientAccessPanel';
import { userTypes, defaultAttributions, adminLevels } from './constants/';
import UserEditFormState, { SelectedFields, FormFieldsChanged } from './UserEditFormState';
import { CurrentUser, User, Template } from 'qdw-next/common/data/models';
import { BinderyRoles, HRARole } from './qdwExport';
import BulkUpdateService from './BulkUpdateService';
import { HttpError } from '../../../common/httpError';
import { MultiSelectHierarchy } from 'qdw-next/common/data';

import scss from './styles.module.scss';

interface UserEditFormProps {
  currentUser: CurrentUser;
  user: User;
  binderyConfig: BinderyRoles;
  mfaConfig: boolean;
  hraConfig: HRARole;
  cognitoConfig: boolean;
  multiEdit: boolean;
  selectedUsers: number[];
  isNewUser: boolean;
  onUserCreated: () => void;
}
interface ValidFields {
  plansSelected: string[];
  outreachTemplates: Array<string | 0>;
  providers: Array<string | 0>;
  sources: Array<string | 0>;
  userGroup: number;
  adminLevel: string;
  username: string;
  email: string;
}

export class UserEditForm extends Component<UserEditFormProps, UserEditFormState> {
  formChangedDefaults = {
    adminLevelSelected: false,
    binderySelected: false,
    userTypeSelected: false,
    defaultAttributionSelected: false,
    email: '',
    username: '',
    firstName: false,
    lastName: false,
    npi: false,
    phoneNumber: false,
    streetAddress: false,
    USState: false,
    city: false,
    zip: false,
    category: false,
    credentials: false,
    providersSelected: false,
    sourcesSelected: false,
    plansSelected: false,
    templatesSelected: false,
    electronicFormRoleSelected: false,
    hmrRoleSelected: false,
    hpmRoleSelected: false,
    hraRoleSelected: false,
    hraAuditRoleSelected: false,
    paperFormRoleSelected: false,
    userGroupSelected: false,
    dashboardsSelected: false,
    reportsSelected: false,
    initiativesSelected: false,
    outreach: false,
    export: false,
    careManage: false,
    careManagerAdmin: false,
    desktop: false,
    referral: false,
    referralPatientChart: false,
    disabled: false,
    widgetsSelected: false,
    ewaDashboardsSelected: false
  };

  state = {
    doneLoading: false,
    groups: [],
    sources: new MultiSelectHierarchy({
      dataItems: [],
      dataMemberPaths: { key: 'path' }
    }),
    providers: new MultiSelectHierarchy({
      dataItems: [],
      dataMemberPaths: { key: 'path' }
    }),
    templates: new MultiSelectHierarchy({
      dataItems: []
    }),
    dashboards: new MultiSelectHierarchy({
      dataItems: [],
      flat: true
    }),
    initiatives: new MultiSelectHierarchy({
      dataItems: [],
      flat: true
    }),
    reports: new MultiSelectHierarchy({
      dataItems: [],
      flat: true
    }),
    plans: new MultiSelectHierarchy({
      dataItems: [],
      flat: true
    }),
    plansSelected: [],
    email: '',
    username: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    streetAddress: '',
    city: '',
    USState: '',
    zip: '',
    npi: '',
    category: '',
    credentials: '',
    userGroupSelected: null,
    userTypeSelected: userTypes[0].id,
    adminLevelSelected: adminLevels[0].id,
    defaultAttributionSelected: defaultAttributions[0].id,
    sourcesSelected: [],
    providersSelected: [],
    templatesSelected: [],
    reportsSelected: [],
    initiativesSelected: [],
    dashboardsSelected: [],
    widgetsSelected: [],
    widgets: new MultiSelectHierarchy({ dataItems: [], flat: true }),
    ewaDashboardsSelected: [],
    ewaDashboards: new MultiSelectHierarchy({
      dataItems: [],
      flat: true,
      dataMemberPaths: { key: 'name' }
    }),
    outreach: false,
    export: false,
    careManage: false,
    careManagerAdmin: false,
    desktop: false,
    referral: false,
    referralPatientChart: false,
    disabled: false,
    binderySelected: '',
    electronicFormRoleSelected: 0,
    hmrRoleSelected: 0,
    hpmRoleSelected: 0,
    hraRoleSelected: 0,
    hraAuditRoleSelected: 0,
    paperFormRoleSelected: 0,
    fieldsChanged: { ...this.formChangedDefaults },
    errors: [],
    alertMessage: <div />,
    open: false,
    mfaVerified: this.props.user.mfaVerified,
    dialogIsOpen: false,
    dialogMessage: ''
  };

  public handleUserGroupChange = (userGroupSelected: number | null): void => {
    const fieldsChanged = { ...this.state.fieldsChanged, userGroupSelected: true } as Pick<
      FormFieldsChanged,
      keyof FormFieldsChanged
      >;
    this.setState({ userGroupSelected, fieldsChanged });
  };

  public handleClose = () => {
    this.setState({ open: false });
  };

  public handleChange = (
    stateKey: string,
    value: string | string[] | number[] | number | boolean | null,
    callback?: () => void
  ): void => {
    if (this.bulkUpdatingEmptyMultiselects(stateKey, value)) {
      this.skipFieldFromUpdating(stateKey);
    } else {
      const fieldsChanged = { ...this.state.fieldsChanged, [stateKey]: true } as Pick<
        FormFieldsChanged,
        keyof FormFieldsChanged
        >;

      if (callback) {
        this.setState({ fieldsChanged }, callback);
      } else {
        this.setState({ fieldsChanged });
      }
    }
    this.setState({ [stateKey]: value } as SelectedFields);
  };

  public handleSave = () => {
    const { isNewUser } = this.props;
    const bulkUpdate = new BulkUpdateService(
      this.props.multiEdit,
      this.state.fieldsChanged,
      this.state
    ).buildPatchRequest();


    const request: any = this.props.isNewUser
      ? { user: { ...bulkUpdate } }
      : { usersSelected: this.props.selectedUsers, user: { ...bulkUpdate } };
    const action: string = isNewUser ? 'created' : 'updated';
    const numberOfUsers: number = isNewUser ? 1 : this.props.selectedUsers.length;

    this.saveForm(request, isNewUser)
      .then(() => {
        this.setState({
          open: true,
          alertMessage: (
            <SnackbarContent
              className={scss.success}
              message={
                <div>
                  {numberOfUsers}/{numberOfUsers} user(s) were successfully {action}
                </div>
              }
            />
          )
        });
        this.props.onUserCreated();
      })
      .catch((e: HttpError) => {
        let errorMessage = '';
        if (e.body && e.body.error) {
          if (e.body.username) {
            errorMessage = errorMessage.concat(`\n Username: ${e.body.username}`);
          }
          errorMessage = errorMessage.concat(`\n ${e.body.error}`);
        }

        this.setState({
          open: true,
          alertMessage: (
            <SnackbarContent
              className={scss.error}
              message={
                <div>
                  <div>
                    Error: {numberOfUsers} user(s) were not {action}
                  </div>
                  {errorMessage && <div>{errorMessage}</div>}
                </div>
              }
            />
          )
        });
        if (e.name === 'Error') {
          throw e;
        }
      });
  };

  public handleMfaReset = () => {
    this.resetMfa(this.props.user.id)
      .then(response => {
        this.setState({
          open: true,
          mfaVerified: false,
          alertMessage: <SnackbarContent className={scss.success} message={<div>Successfully reset MFA</div>} />
        });
      })
      .catch((e: Error) => {
        this.setState({
          open: true,
          alertMessage: (
            <SnackbarContent
              className={scss.error}
              message={
                <div>
                  <div>Error: User was unable to have MFA reset</div>
                  {e.message && <div>{e.message}</div>}
                </div>
              }
            />
          )
        });
      });
  };

  public async resetMfa(userId: number) {
    const apiService = new ApiService();
    const fetchOptions = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': (window as any).Rails.csrfToken(),
        'X-Requested-With': 'XMLHttpRequest'
      },
      method: 'POST'
    };

    const response = await apiService.resetMFA(userId, fetchOptions);
    return response;
  }

  public handleUnlinkingUser = () => {
    this.unlinkUser(this.props.user.id)
      .then(response => {
        this.setState({
          open: true,
          dialogIsOpen: false,
          alertMessage: <SnackbarContent className={scss.success} message={<div>Successfully unlinked user</div>} />
        });
      })
      .catch((e: Error) => {
        this.setState({
          open: true,
          dialogIsOpen: false,
          alertMessage: (
            <SnackbarContent
              className={scss.error}
              message={
                <div>
                  <div>Error: User was unable to be unlinked</div>
                  {e.message && <div>{e.message}</div>}
                </div>
              }
            />
          )
        });
      });
  };

  public async unlinkUser(userId: number) {
    const apiService = new ApiService();
    const fetchOptions = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': (window as any).Rails.csrfToken(),
        'X-Requested-With': 'XMLHttpRequest'
      },
      method: 'POST'
    };

    const response = await apiService.unlinkUser(userId, fetchOptions);
    return response;
  }

  public chooseUserToUnlink = () => {
    const usertoUnlink: any = this.props.user.username;
    this.setState({ dialogIsOpen: true, dialogMessage: `Are you sure you want to unlink ${usertoUnlink}?` });
  };

  public showUnlinkUserButton(currentUser: CurrentUser | undefined) {
    if (currentUser && currentUser.role === 'super_admin' && this.props.cognitoConfig === true && !this.props.multiEdit) {
      return (
        <div className={scss.userManagementButton}>
          <label htmlFor="unlink-user">
            <Button id="unlink-users" component="span" className={scss.unlinkUserButton} onClick={this.chooseUserToUnlink}>
              Unlink User
            </Button>
          </label>
        </div>
      );
    }
  }

  public showResendTempPassword(currentUser: CurrentUser | undefined) {
    if (
      currentUser &&
      currentUser.role !== 'regular_user' &&
      this.props.user.cognitoUserStatus === 'FORCE_CHANGE_PASSWORD' &&
      !this.props.multiEdit
    ) {
      return (
        <Button data-test="re-send-password" onClick={this.resendTempPassword}>
          Resend Temporary Password
        </Button>
      );
    }
  }

  public closeDialog = (): void => {
    this.setState({ dialogIsOpen: false });
  };

  public async saveForm(formData: any = {}, create: boolean = false) {
    const apiService = new ApiService();
    const fetchOptions = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': (window as any).Rails.csrfToken(),
        'X-Requested-With': 'XMLHttpRequest'
      },
      method: create ? 'POST' : 'PUT'
    };
    const response = create
      ? await apiService.create(JSON.stringify(formData), fetchOptions)
      : await apiService.bulkUpdate(JSON.stringify(formData), fetchOptions);
    return response;
  }

  public resendTempPassword = () => {
    const apiService = new ApiService();

    const fetchOptions = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': (window as any).Rails.csrfToken(),
        'X-Requested-With': 'XMLHttpRequest'
      },
      method: 'POST'
    };

    return apiService
      .resendTempPassword(this.props.user.id, {}, fetchOptions)
      .then(() => {
        this.setState({
          open: true,
          alertMessage: (
            <SnackbarContent className={scss.success}
                             message={
                               <div>Successfully resent temporary password.
                               </div>}
            />
          )
        });
      })
      .catch((e: Error) => {
        this.setState({
          open: true,
          alertMessage: (
            <SnackbarContent className={scss.error}
                             message={
                               <div>
                                 <div>Error: Unable to resend temporary password.</div>
                               </div>
                             }

            />
          )
        });
      });
  };

  public componentDidMount() {
    if (this.props.isNewUser) {
      this.setState({ doneLoading: true });
    } else {
      const apiService = new ApiService();
      Promise.all([
        apiService.getGroups(),
        apiService.getSources(),
        apiService.getProviders(),
        apiService.getPlans(),
        apiService.getTemplates(this.props!.currentUser),
        apiService.getPageConfigurations(),
        apiService.getInitiatives(),
        apiService.getReports(),
        apiService.getWidgets(),
        apiService.getEwaDashboards()
      ]).then(
        ([
           groupResults,
           sourceResults,
           providerResults,
           planResults,
           templateResults,
           pageConfigResults,
           initiativeResults,
           reportResults,
           widgetResults,
           ewaDashboardResults
         ]) => {
          const pageService = new PageMultiSelectService(
            reportResults.data,
            initiativeResults.data,
            pageConfigResults.data,
            widgetResults.data,
            ewaDashboardResults.data,
            this.props!.currentUser
          );

          this.setState({
            groups: groupResults.data,
            sources: new MultiSelectHierarchy({ dataItems: sourceResults.data, dataMemberPaths: { key: 'path' } }),
            providers: new MultiSelectHierarchy({
              dataItems: providerResults.data,
              dataMemberPaths: { key: 'path' }
            }),
            plans: new MultiSelectHierarchy({ dataItems: planResults.data, flat: true }),
            templates: new MultiSelectHierarchy({ dataItems: this.prependOutreachType(templateResults.data) }),
            dashboards: new MultiSelectHierarchy({ dataItems: pageService.getDashboards(), flat: true }),
            widgets: new MultiSelectHierarchy({ dataItems: pageService.getWidgets(), flat: true }),
            ewaDashboards: new MultiSelectHierarchy({
              dataItems: pageService.getEwaDashboards(),
              flat: true,
              dataMemberPaths: { key: 'name' }
            }),
            initiatives: new MultiSelectHierarchy({ dataItems: pageService.getInitiatives(), flat: true }),
            reports: new MultiSelectHierarchy({ dataItems: pageService.getReports(), flat: true }),
            doneLoading: true
          });
        }
      );
    }
  }

  public componentDidUpdate(prevProps: UserEditFormProps) {
    const user = this.props.user;
    if (user !== prevProps.user && !isEmpty(user)) {
      if ((this.props.multiEdit && user.id === 0) || !this.props.multiEdit) {
        this.setState({
          doneLoading: true,
          email: user.email!,
          username: user.username,
          firstName: user.firstName!,
          lastName: user.lastName!,
          phoneNumber: user.phoneNumber!,
          streetAddress: user.address!,
          city: user.city!,
          USState: user.state!,
          zip: user.zip!,
          npi: user.npi!,
          category: user.category!,
          credentials: user.credentials!,
          userGroupSelected: user.groupId!,
          userTypeSelected: user.type!,
          adminLevelSelected: user.role!,
          defaultAttributionSelected: user.providerType!,
          sourcesSelected: user.sources,
          providersSelected: user.providers,
          plansSelected: user.plans!,
          outreach: user.outreachAbility,
          export: user.exportAbility!,
          careManage: user.careManager!,
          careManagerAdmin: user.careManagerAdmin!,
          desktop: user.desktopAccess!,
          referral: user.referralAccess!,
          referralPatientChart: user.referralPatientChart!,
          disabled: user.disabled,
          dashboardsSelected: user.dashboards!,
          widgetsSelected: user.widgets!,
          ewaDashboardsSelected: user.ewaDashboards!,
          initiativesSelected: user.initiatives!,
          reportsSelected: user.reports!,
          binderySelected: user.binderyAccess!,
          electronicFormRoleSelected: user.electronicFormPermissions!,
          hmrRoleSelected: user.hmrPermissions!,
          hpmRoleSelected: user.hpmPermissions!,
          hraRoleSelected: user.hraPermissions!,
          hraAuditRoleSelected: user.hraAuditPermissions!,
          paperFormRoleSelected: user.paperFormPermissions!,
          templatesSelected: this.splitTemplates(user),
          mfaVerified: user.mfaVerified,
          fieldsChanged: {
            ...this.formChangedDefaults
          }
        });
      }
    }
  }

  public render() {
    const { user } = this.props;
    return (
      <div>
        <Grid container spacing={2}>
          {isEmpty(user) ? (
            <h1> No User Selected.</h1>
          ) : (
            <Form
              onSubmit={this.handleSave}
              initialValues={{
                userGroup: this.state.userGroupSelected,
                adminLevel: this.state.adminLevelSelected,
                plansSelected: this.state.plansSelected,
                providers: this.state.providersSelected,
                sources: this.state.sourcesSelected,
                outreachTemplates: this.state.templatesSelected,
                username: this.state.username,
                email: this.state.email,
                widgets: this.props.isNewUser && this.state.widgetsSelected.length < 1 ? [0] : this.state.widgetsSelected,
                ewaDashboards: this.state.ewaDashboardsSelected
              }}
              validate={(values: ValidFields) => {
                // tslint:disable-next-line jsx-no-lambda
                const errors: any = {};

                if (values.plansSelected && values.plansSelected.length === 0 && this.state.userTypeSelected === 'plan') {
                  errors.plansSelected = true;
                }

                if (values.outreachTemplates.length === 0 && this.state.outreach) {
                  errors.outreachTemplates = true;
                }

                if (values.providers.length === 0 && !this.props.multiEdit) {
                  errors.providers = true;
                }
                if (values.sources.length === 0 && !this.props.multiEdit) {
                  errors.sources = true;
                }

                if (this.regularAdminLevel(values.adminLevel) && values.userGroup === null) {
                  errors.userGroup = true;
                }

                if (this.unchangedUserGroup(values.userGroup)) {
                  if (!this.props.multiEdit) {
                    errors.userGroup = true;
                  }

                  if (this.props.multiEdit && this.regularAdminLevel(values.adminLevel)) {
                    errors.userGroup = true;
                  }
                }

                return errors;
              }}
            >
              {({ handleSubmit, submitting }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <UserInformationPanel
                      email={this.state.email}
                      username={this.state.username}
                      firstName={this.state.firstName}
                      lastName={this.state.lastName}
                      phoneNumber={this.state.phoneNumber}
                      streetAddress={this.state.streetAddress}
                      city={this.state.city}
                      USState={this.state.USState}
                      zip={this.state.zip}
                      npi={this.state.npi}
                      category={this.state.category}
                      credentials={this.state.credentials}
                      disabled={this.state.disabled}
                      multiEdit={this.props.multiEdit}
                      onSelectChange={this.handleChange}
                      isNewUser={this.props.isNewUser}
                      cognitoConfig={this.props.cognitoConfig}
                    />
                    <PatientAccessPanel
                      currentUser={this.props.currentUser}
                      doneLoading={this.state.doneLoading}
                      onUserGroupChange={this.handleUserGroupChange}
                      onSelectChange={this.handleChange}
                      groups={this.state.groups}
                      sources={this.state.sources}
                      providers={this.state.providers}
                      userGroupSelected={this.state.userGroupSelected}
                      userTypeSelected={this.state.userTypeSelected}
                      adminLevelSelected={this.state.adminLevelSelected}
                      defaultAttributionSelected={this.state.defaultAttributionSelected}
                      sourcesSelected={this.state.sourcesSelected}
                      providersSelected={this.state.providersSelected}
                      plans={this.state.plans}
                      plansSelected={this.state.plansSelected}
                      multiEdit={this.props.multiEdit}
                    />
                    <FeatureAccessPanel
                      currentUser={this.props.currentUser}
                      onSelectChange={this.handleChange}
                      onMfaReset={this.handleMfaReset}
                      reports={this.state.reports}
                      initiatives={this.state.initiatives}
                      dashboards={this.state.dashboards}
                      widgets={this.state.widgets}
                      ewaDashboards={this.state.ewaDashboards}
                      templates={this.state.templates}
                      templatesSelected={this.state.templatesSelected}
                      outreach={this.state.outreach}
                      export={this.state.export}
                      careManage={this.state.careManage}
                      careManagerAdmin={this.state.careManagerAdmin}
                      desktop={this.state.desktop}
                      referral={this.state.referral}
                      referralPatientChart={this.state.referralPatientChart}
                      binderySelected={this.state.binderySelected}
                      electronicFormRoleSelected={this.state.electronicFormRoleSelected}
                      hmrRoleSelected={this.state.hmrRoleSelected}
                      hpmRoleSelected={this.state.hpmRoleSelected}
                      hraRoleSelected={this.state.hraRoleSelected}
                      hraAuditRoleSelected={this.state.hraAuditRoleSelected}
                      paperFormRoleSelected={this.state.paperFormRoleSelected}
                      reportsSelected={this.state.reportsSelected}
                      initiativesSelected={this.state.initiativesSelected}
                      dashboardsSelected={this.state.dashboardsSelected}
                      widgetsSelected={this.state.widgetsSelected}
                      ewaDashboardsSelected={this.state.ewaDashboardsSelected}
                      hraConfig={this.props.hraConfig}
                      binderyConfig={this.props.binderyConfig}
                      mfaConfig={this.props.mfaConfig}
                      mfaVerified={this.state.mfaVerified}
                      multiEdit={this.props.multiEdit}
                    />
                    <Grid container item justifyContent="flex-end">
                      {this.showUnlinkUserButton(this.props.currentUser)}
                      {this.showResendTempPassword(this.props.currentUser)}
                      <Button
                        data-test="save"
                        type="submit"
                        disabled={submitting || every(objectValues(this.state.fieldsChanged), field => !field)}
                      >
                        Save
                      </Button>
                    </Grid>
                    <Snackbar
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                      open={this.state.open}
                      autoHideDuration={5000}
                      onClose={this.handleClose}
                    >
                      {this.state.alertMessage}
                    </Snackbar>
                    <Dialog open={this.state.dialogIsOpen}>
                      <DialogTitle id="alert-dialog-title">{'Unlink User'}</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">{this.state.dialogMessage}</DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.handleUnlinkingUser} color="primary">
                          Yes
                        </Button>
                        <Button onClick={this.closeDialog} color="primary">
                          No
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </form>
                );
              }}
            </Form>
          )}
        </Grid>
      </div>
    );
  }

  // TODO: move functions, unit test seperately.
  public splitSources(user: User) {
    const userSources = (get(user, 'sources', []) as User['sources']).map(source => {
      if (source === 0) {
        return source;
      }
      const splitSource = source.toString().split('/');
      return splitSource[splitSource.length - 1] ? splitSource[splitSource.length - 1] : source;
    });
    return userSources;
  }

  // TODO: move functions, unit test seperately.
  public splitProviders(user: User) {
    const providersSources = (get(user, 'providers', []) as User['providers']).map(provider => {
      if (provider === 0) {
        return provider;
      }
      const splitProvider = provider.toString().split('/');
      return splitProvider[splitProvider.length - 1] ? splitProvider[splitProvider.length - 1] : provider;
    });
    return providersSources;
  }

  // TODO: move functions, unit test seperately.
  public splitTemplates(user: User) {
    const userTemplates = (get(user, 'outreachTemplates', []) as User['outreachTemplates']).map(template => {
      if (template === 0) {
        return template;
      }
      const splitTemplate = template.toString().split('/');
      return splitTemplate[splitTemplate.length - 1]
        ? parseInt(splitTemplate[splitTemplate.length - 1], 10)
        : parseInt(template, 10);
    });
    return userTemplates;
  }

  private unchangedUserGroup(userGroup: number) {
    return userGroup === 0;
  }
  private regularAdminLevel(adminLevel: string) {
    return !includes(['client_admin', 'super_admin', 'unchanged'], adminLevel);
  }

  private bulkUpdatingEmptyMultiselects(stateKey: string, value: any) {
    return (
      includes(['providersSelected', 'sourcesSelected'], stateKey) &&
      Array.isArray(value) &&
      value.length < 1 &&
      this.props.multiEdit
    );
  }

  private skipFieldFromUpdating(stateKey: string) {
    const fieldsChanged = { ...this.state.fieldsChanged, [stateKey]: false } as Pick<
      FormFieldsChanged,
      keyof FormFieldsChanged
      >;
    this.setState({ fieldsChanged });
  }

  private prependOutreachType(templates: Template[]) {
    templates = templates.map((template: Template) => {
      template.children.map((child: any) => {
        child.name =
          child.dependency.outreachType !== null ? `${child.dependency.outreachType}: ${child.name}` : child.name;
        return child;
      });
      return template;
    });
    return templates;
  }
}

export default UserEditForm;
