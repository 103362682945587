// a **theme independent** collection of styles that may be used throughout the campaign manager components
const styles = {
  padded: {
    padding: '0 15px'
  },
  scrollable: {
    maxHeight: 600,
    minHeight: 600,
    overflowY: 'auto'
  }
};

export default styles;
