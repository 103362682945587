// This file is the entrypoint for the webpack pipeline for qdw. All code that
// needs to be available on the client must be imported/required here or in an
// imported dependency. If it's not referenced it won't be available.
import 'qdw-next/global_styles/index.scss';

import 'isomorphic-fetch';

import React from 'react';
window['React'] = React;

import ReactDOM from 'react-dom';
window['ReactDOM'] = ReactDOM;

import qdwNext from 'qdw-next';
window['qdwNext'] = qdwNext;

import * as Hydra from '@arcadia/hydra';
window['hydra'] = Hydra;

// Very important, *all* files recursively inside of 'qdw-next/directives' that
// are not in a '__tests__' directory will be imported and included in the output bundle.
// If files are put there accidentally it may break the build or grow the output size.
const directiveRequire = require.context('qdw-next/directives/', true, /^(?!.*__tests__.*).*\.jsx?$/);
directiveRequire.keys().forEach(directiveRequire);
