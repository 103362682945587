import React, { Fragment } from 'react';
import SuperHierarchyListPopper from 'qdw-next/common/components/SuperHierarchyListPopper';
import omitBy from 'lodash/omitBy';
import includes from 'lodash/includes';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FieldRenderProps } from 'react-final-form';

interface FinalFormTreePopperProps extends FieldRenderProps {
  errorText?: string;
  hierarchy?: any;
  hierarchyStrategy?: any;
  searchEnabled?: boolean;
  selectAllEnabled?: boolean;
  onValueChange?: (values: string[]) => void;
}

const FinalFormTreePopper: React.SFC<FinalFormTreePopperProps> = obj => {
  const input = obj.input;
  const meta = obj.meta;
  const rest = omitBy(obj, (_, key) => includes(['input', 'meta', 'errorText'], key));

  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <Fragment>
      <SuperHierarchyListPopper {...rest} value={input.value} InputProps={{ InputProps: { error: showError } }} />
      {showError && <FormHelperText error={true}>{obj.errorText}</FormHelperText>}
    </Fragment>
  );
};

export default FinalFormTreePopper;
