import PropTypes from 'prop-types';
import React, { Component } from 'react';

import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';

import Delete from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';

import IntegerNumberFormat from './IntegerNumberFormat';
import { generateTimeRange, generateWeekDays, renderErrorMessages, renderSimpleSelectItems } from './utils';
import { renderComplexSelectItems } from 'qdw-next/common/formHelpers';
import { validateCampaignWindow } from './validator';

const daysOfWeek = generateWeekDays();
const timeValues = generateTimeRange(0, 15, 'minutes');

export default class CampaignWindow extends Component {
  static defaultProps = {
    errors: {},
    timeZones: []
  };

  static propTypes = {
    campaignWindow: PropTypes.object.isRequired,
    errors: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    timeZones: PropTypes.arrayOf(PropTypes.object)
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (isEqual(prevState.errors, nextProps.errors)) {
      return null;
    }

    return { errors: { ...nextProps.errors, ...prevState.errors } };
  }

  state = {
    errors: {}
  };

  render() {
    const { campaignWindow, timeZones } = this.props;
    const { errors } = this.state;
    const hasErrors = !!filter(errors).length;

    return (
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControl error={!!errors.dayOfWeek}>
            <InputLabel htmlFor={`dayOfWeek${campaignWindow.id}`}>Day of Week</InputLabel>
            <Select
              inputProps={{ id: `dayOfWeek${campaignWindow.id}` }}
              onChange={this.handleChange('dayOfWeek')}
              value={campaignWindow.dayOfWeek}
            >
              {renderComplexSelectItems(daysOfWeek, 'id', 'name')}
            </Select>
            {renderErrorMessages(errors.dayOfWeek)}
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl error={!!errors.messageLimit}>
            <InputLabel htmlFor={`messageLimit${campaignWindow.id}`} shrink={true}>
              Message Limit
            </InputLabel>
            <Input
              id={`messageLimit${campaignWindow.id}`}
              inputComponent={IntegerNumberFormat}
              onChange={this.handleChange('messageLimit')}
              value={campaignWindow.messageLimit}
            />
            {renderErrorMessages(errors.messageLimit)}
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl error={!!errors.startTime}>
            <InputLabel htmlFor={`startTime${campaignWindow.id}`}>Start Time</InputLabel>
            <Select
              inputProps={{ id: `startTime${campaignWindow.id}` }}
              onChange={this.handleChange('startTime')}
              value={campaignWindow.startTime}
            >
              {renderSimpleSelectItems(timeValues)}
            </Select>
            {renderErrorMessages(errors.startTime)}
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl error={!!errors.endTime}>
            <InputLabel htmlFor={`endTime${campaignWindow.id}`}>End Time</InputLabel>
            <Select
              inputProps={{ id: `endTime${campaignWindow.id}` }}
              onChange={this.handleChange('endTime')}
              value={campaignWindow.endTime}
            >
              {renderSimpleSelectItems(timeValues)}
            </Select>
            {renderErrorMessages(errors.endTime)}
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl error={!!errors.timeZone}>
            <InputLabel htmlFor={`timeZone${campaignWindow.id}`}>Time Zone</InputLabel>
            <Select
              inputProps={{ id: `timeZone${campaignWindow.id}` }}
              onChange={this.handleChange('timeZone')}
              value={campaignWindow.timeZone}
            >
              {renderComplexSelectItems(timeZones, 'identifier', 'display')}
            </Select>
            {renderErrorMessages(errors.timeZone)}
          </FormControl>
        </Grid>

        <Grid alignItems={hasErrors ? 'center' : 'flex-end'} container item xs={1}>
          <Tooltip title="Remove Window">
            <IconButton onClick={this.handleRemove}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }

  handleChange = propName => event => {
    const { campaignWindow, onChange } = this.props;
    campaignWindow[propName] = event.target.value;
    onChange(campaignWindow);

    const prevErrors = this.state.errors;
    const propErrors = validateCampaignWindow(campaignWindow)[propName];
    this.setState({ errors: { ...prevErrors, [propName]: propErrors } });
  };

  handleRemove = () => {
    const { campaignWindow, onRemove } = this.props;
    onRemove(campaignWindow);
  };
}
