import flattenDeep from 'lodash/flattenDeep';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';

/**
 * Utility method to find the ancestors of a given HierarchyNode.
 *
 * @param node {SingleSelectHierarchyNode} The node to find the ancestors of.
 *
 * @returns {Array} The ancestor HierarchyNodes for the given node from the lowest depth to the highest depth.
 */
export function getAncestorNodes(node) {
  const ancestors = [];
  let ancestor = node.parentNode;

  while (ancestor) {
    // the parentNode will be the Hierarchy for level zero nodes, and the Hierarchy is not a valid ancestor
    if (isNumber(ancestor.level)) {
      ancestors.push(ancestor);
      ancestor = ancestor.parentNode;
    } else {
      ancestor = null;
    }
  }

  return ancestors;
}

/**
 * Utility method to find the descendants of a given Hierarchy or HierarchyNode.
 *
 * @param node {SingleSelectHierarchy | SingleSelectHierarchyNode} The node to find the descendants of.
 *
 * @returns {Array} A flattened list of all descendants in logical order.
 */
export function getDescendantNodes(node) {
  return flattenDeep(get(node, 'nodes', []).map(n => [n, ...getDescendantNodes(n)]));
}

/**
 * Utility method to find all checked descendant nodes of a given hierarchy.
 *
 * @param hierarchy {SingleSelectHierarchy} The hierarchy or node to find the checked descendant nodes.
 * @returns {Array} A flattened list of all checked nodes.
 */
function allStrategy(hierarchy) {
  return hierarchy.descendants().filter(d => d.checked);
}

/**
 * Utility method to find checked descendant nodes of a given hierarchy with nodes. If a node that has descendant nodes is
 * determined to be checked, it will be returned and its descendant nodes will not be.  Otherwise, its descendant nodes will be
 * returned when checked.
 *
 * @param hierarchy {SingleSelectHierarchy} The hierarchy or node to find the checked descendant nodes.
 * @returns {Array} A flattened list of all checked nodes.
 */
function ancestorFirstStrategy(hierarchy) {
  return flattenDeep(
    hierarchy.nodes.map(n => {
      if (n.indeterminate) {
        return ancestorFirstStrategy(n);
      } else if (n.checked) {
        return n;
      } else {
        return [];
      }
    })
  );
}

/**
 * Utility method to find checked descendant nodes of a given hierarchy. Only nodes that do not have descendant nodes will be
 * returned.
 *
 * @param hierarchy {SingleSelectHierarchy} The hierarchy or node to find the checked descendant nodes.
 * @returns {Array} A flattened list of all checked nodes.
 */
function descendantOnlyStrategy(hierarchy) {
  return hierarchy
    .descendants()
    .filter(d => !d.hasChildren)
    .filter(d => d.checked);
}

/**
 * Strategies are different mechanisms to obtain the checked nodes from a hierarchy. Strategies are needed because different parts
 * of the application have different needs in terms of obtaining the checked nodes such as performance implications, legacy
 * expectations, and new expectations.
 *
 * Strategies can be used to determine the following about a hierarchy based on the checked nodes returned: value state, display
 * state, and likely more.
 */
export const hierarchyStrategies = {
  all: allStrategy,
  ancestorFirst: ancestorFirstStrategy,
  descendantOnly: descendantOnlyStrategy
};
