import qdwNext from 'qdw-next';
import CampaignManager from 'qdw-next/components/Outreach/CampaignManager';

import { filterTemplates, formatAttribute } from './utils';

export const proxyDirective = reactDirective => reactDirective(CampaignManager, proxyDirectiveOptions);

export const proxyDirectiveName = 'proxyOutreachCampaignManager';

export const proxyDirectiveOptions = [
  ['campaigns', { watchDepth: 'collection' }],
  ['durationTypes', { watchDepth: 'collection' }],
  ['languages', { watchDepth: 'collection' }],
  ['lobs', { watchDepth: 'collection' }],
  ['memberProducts', { watchDepth: 'collection' }],
  ['appointmentProviderLocations', { watchDepth: 'collection' }],
  'loading',
  ['measures', { watchDepth: 'collection' }],
  ['patientLists', { watchDepth: 'collection' }],
  ['periodTypes', { watchDepth: 'collection' }],
  ['providers', { watchDepth: 'collection' }],
  ['providerAttributionTypes', { watchDepth: 'collection' }],
  ['templates', { watchDepth: 'collection' }],
  ['timeZones', { watchDepth: 'collection' }],
  ['onCampaignUpsert', { watchDepth: 'reference' }]
];

qdwNext.directive(proxyDirectiveName, ['reactDirective', proxyDirective]);

qdwNext.controller('OutreachCampaignManagerCtrl', [
  '$q',
  'Demographics',
  'OutreachCampaigns',
  'OutreachCampaignDurationTypes',
  'OutreachCampaignTimeZones',
  'OutreachMeasures',
  'OutreachProviders',
  'OutreachTemplates',
  'PeriodTypes',
  'ProviderAttributionType',
  'AppointmentProviderLocation',
  'PatientLists',
  function(
    $q,
    Demographics,
    OutreachCampaigns,
    OutreachCampaignDurationTypes,
    OutreachCampaignTimeZones,
    OutreachMeasures,
    OutreachProviders,
    OutreachTemplates,
    PeriodTypes,
    ProviderAttributionType,
    AppointmentProviderLocation,
    PatientLists
  ) {
    this.loading = true;

    $q.all([
      Demographics.query(),
      OutreachCampaigns.query(),
      OutreachCampaignDurationTypes.query(),
      OutreachCampaignTimeZones.query(),
      OutreachMeasures.query(),
      OutreachProviders.query(),
      OutreachTemplates.query('campaign'),
      AppointmentProviderLocation.query(),
      PeriodTypes.query(),
      PatientLists.query()
    ])
      .then(responses => {
        const [
          demographics,
          campaigns,
          durationTypes,
          timeZones,
          measures,
          providers,
          templates,
          appointmentProviderLocations,
          periodTypes,
          patientLists
        ] = responses;
        this.campaigns = campaigns;
        this.durationTypes = durationTypes;
        this.languages = demographics.language;
        this.lobs = demographics.lob;
        this.memberProducts = demographics.member_product;
        this.measures = measures;
        this.periodTypes = periodTypes.filter(pt => pt.periodType.toLowerCase().includes('year'));
        this.providers = providers;
        this.providerAttributionTypes = ProviderAttributionType.withRendering.map(type => ({
          display: formatAttribute(type),
          value: type
        }));
        this.appointmentProviderLocations = appointmentProviderLocations;
        this.templates = filterTemplates(templates, t => t.dependency.outreachType !== 'Phone');
        this.timeZones = timeZones;
        this.patientLists = patientLists;
      })
      .finally(() => {
        this.loading = false;
      });

    this.handleCampaignUpsert = value => {
      return new Promise((resolve, reject) => {
        OutreachCampaigns.upsert(value)
          .then(campaign => {
            const campaigns = [...this.campaigns];
            if (value.id) {
              const index = campaigns.findIndex(c => c.id === campaign.id);
              campaigns.splice(index, 1, campaign);
            } else {
              campaigns.push(campaign);
            }

            this.campaigns = campaigns;

            resolve();
          })
          .catch(() => reject());
      });
    };
  }
]);

export const ngDirective = () => ({
  bindToController: {},
  controller: 'OutreachCampaignManagerCtrl',
  controllerAs: 'ctrl',
  restrict: 'E',
  scope: {},
  template: `
    <proxy-outreach-campaign-manager
      campaigns="ctrl.campaigns"
      duration-types="::ctrl.durationTypes"
      languages="::ctrl.languages"
      lobs="::ctrl.lobs"
      member-products="::ctrl.memberProducts"
      loading="::ctrl.loading"
      measures="::ctrl.measures"
      patientLists="::ctrl.patientLists"
      on-campaign-upsert="::ctrl.handleCampaignUpsert"
      period-types="::ctrl.periodTypes"
      providers="::ctrl.providers"
      provider-attribution-types="::ctrl.providerAttributionTypes"
      appointment-provider-locations="::ctrl.appointmentProviderLocations"
      templates="::ctrl.templates"
      time-zones="::ctrl.timeZones"
    >
    </proxy-outreach-campaign-manager>
  `
});

export const ngDirectiveName = 'outreachCampaignManager';

qdwNext.directive(ngDirectiveName, ngDirective);
