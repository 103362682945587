import React, { Fragment } from 'react';

import classNames from 'classnames';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import sharedStyles from './shared-styles';

import scss from './styles.module.scss';

const styles = () => ({
  ...sharedStyles,
  scrollable: {
    ...sharedStyles.scrollable,
    minHeight: 300,
    maxHeight: 300
  }
});

export function ExecutionPlanViewer({ classes, showExecutionPlan, executionPlan, onReload }) {
  return (
    <Fragment>
      <AppBar>
        <Toolbar>
          <Typography color="inherit" variant="h6">
            Execution Plan
          </Typography>
        </Toolbar>
      </AppBar>

      <div className={classNames(classes.padded, classes.scrollable)}>
        {showExecutionPlan && (
          <Fragment>
            <div className={classNames(scss.flexHeader)}>
              <div>Total patients returned:</div>
              <div>{executionPlan.scheduablePeople}</div>
            </div>
            <div className={classNames(scss.flexBody)}>
              <div className={classNames(scss.grow)}>
                <div style={{ textAlign: 'left' }}>Number of bad phone numbers:</div>
                <div style={{ textAlign: 'left' }}>Number of missing phone numbers:</div>
                <div style={{ textAlign: 'left' }}>Number of patients with prior SMS:</div>
                <div style={{ textAlign: 'left' }}>Expected Volume:</div>
              </div>
              <div>
                <div>{executionPlan.badPhoneNumber}</div>
                <div>{executionPlan.noPhoneNumber}</div>
                <div>{executionPlan.previousOutreaches}</div>
                <div>{executionPlan.volume}</div>
              </div>
            </div>
            <div className={classNames(scss.flexBody)}>
              <div className={classNames(scss.grow)}>
                <div style={{ textAlign: 'left' }}>{executionPlan.duplicateNumber} duplicate phone numbers</div>

                <div style={{ textAlign: 'left' }}>{executionPlan.messagesPerHour} messages attempted per hour</div>
                <p>
                  <em>
                    Note: 'Messages attempted per hour' is an estimate, segment length and other variables affect this limit
                  </em>
                </p>
              </div>
            </div>
          </Fragment>
        )}

        <div className={classNames(scss.flexBody)}>
          <Button onClick={onReload}>Reload Execution Plan</Button>
        </div>
      </div>
    </Fragment>
  );
}

export default withStyles(styles)(ExecutionPlanViewer);
