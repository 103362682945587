import qdwNext from 'qdw-next';
import UserManagement from 'qdw-next/components/User/Management';

export function userManagementReactDirective(reactDirective) {
  return reactDirective(UserManagement, undefined, {
    restrict: 'E'
  });
}

qdwNext.directive('userManagement', ['reactDirective', userManagementReactDirective]);
