import React, { Component, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import { Theme } from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Field } from 'react-final-form';
import Select from '@material-ui/core/Select';

import FinalFormTextField from 'qdw-next/common/components/FinalFormTextField';
import { USStates } from './constants/';
import { renderNativeSelectItems } from 'qdw-next/common/formHelpers';
import { SelectedFields } from './UserEditFormState';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1)
    },
    formControl: {
      margin: theme.spacing(1),
      width: 185
    },
    formControlFullLine: {
      margin: theme.spacing(1),
      width: 370 + theme.spacing(2)
    },
    textField: {
      textTransform: 'uppercase'
    }
  });

interface Props {
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  streetAddress: string;
  city: string;
  USState: string;
  zip: string;
  npi: string;
  category: string;
  credentials: string;
  disabled: boolean;
  multiEdit: boolean;
  onSelectChange: (key: string, value: string | string[] | boolean) => void;
  isNewUser: boolean;
  cognitoConfig: boolean;
}
export class UserInformationPanel extends Component<Props & WithStyles<typeof styles>> {
  public render() {
    const USStateSelections = this.multiEditSelections(USStates, 'unchanged');
    const cognitoEnabled = this.props.cognitoConfig;

    return (
      <Fragment>
        <Grid alignItems="center" container className={this.props.classes.root}>
          <Grid item xs>
            <Typography variant="h6">User Information</Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item>
            <FormControl className={this.props.classes.formControlFullLine}>
              <InputLabel shrink={true}>USERNAME</InputLabel>
              <Typography variant="body1" style={{ paddingTop: '15px' }}>
                {this.props.username}
              </Typography>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={this.props.classes.formControlFullLine}>
              <InputLabel shrink={true}>EMAIL</InputLabel>
              <Typography variant="body1" style={{ paddingTop: '15px' }}>
                {this.props.email}
              </Typography>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={this.props.classes.formControl}>
              <InputLabel shrink={true}>First Name</InputLabel>
              <Typography variant="body1" style={{ paddingTop: '15px' }}>
                {this.props.firstName}
              </Typography>
            </FormControl>
            <FormControl className={this.props.classes.formControl}>
              <InputLabel shrink={true}>Last Name</InputLabel>
              <Typography variant="body1" style={{ paddingTop: '15px' }}>
                {this.props.lastName}
              </Typography>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={this.props.classes.formControl}>
              <InputLabel shrink={true}>NPI</InputLabel>
              <Typography variant="body1" style={{ paddingTop: '15px' }}>
                {this.props.npi}
              </Typography>
            </FormControl>
            <FormControl className={this.props.classes.formControl}>
              <InputLabel shrink={true}>Category</InputLabel>
              <Typography variant="body1" style={{ paddingTop: '15px' }}>
                {this.props.category}
              </Typography>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={this.props.classes.formControl}>
              <InputLabel shrink={true}>Credentials</InputLabel>
              <Typography variant="body1" style={{ paddingTop: '15px' }}>
                {this.props.credentials}
              </Typography>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={this.props.classes.formControlFullLine}>
              <InputLabel shrink={true}>Street Address</InputLabel>
              <Typography variant="body1" style={{ paddingTop: '15px' }}>
                {this.props.streetAddress}
              </Typography>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={this.props.classes.formControl}>
              <InputLabel shrink={true}>City</InputLabel>
              <Typography variant="body1" style={{ paddingTop: '15px' }}>
                {this.props.city}
              </Typography>
            </FormControl>
            <FormControl className={this.props.classes.formControl}>
              <InputLabel shrink={true}>State</InputLabel>
              <Typography variant="body1" style={{ paddingTop: '15px' }}>
                {this.props.USState}
              </Typography>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={this.props.classes.formControl}>
              <InputLabel shrink={true}>Zip</InputLabel>
              <Typography variant="body1" style={{ paddingTop: '15px' }}>
                {this.props.zip}
              </Typography>
            </FormControl>
            <FormControl className={this.props.classes.formControl}>
              <InputLabel shrink={true}>Phone Number</InputLabel>
              <Typography variant="body1" style={{ paddingTop: '15px' }}>
                {this.props.phoneNumber}
              </Typography>
            </FormControl>
          </Grid>
        </Grid>
      </Fragment>
    );
  }

  private multiEditSelections(selections: any, value: string | number) {
    return this.props.multiEdit ? [{ name: value, abbreviation: 'Unchanged' }].concat(selections) : selections;
  }

  private showAbbreviation() {
    return this.props.USState === 'unchanged' ? 'name' : 'abbreviation';
  }
}

export default withStyles(styles)(UserInformationPanel);
