import React, { Component, Fragment } from 'react';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { AppBar, Button, FormControl, FormHelperText, Grid, Input, InputLabel, Toolbar, Typography } from '@material-ui/core';
import bulkCreateTheme from './theme';
import ApiService from './apiService';
import { validateParameters } from './validator';

export class BulkNumberCreation extends Component {
  state = {
    phoneNumberCount: 4,
    areaCode: 330,
    error: '',
    result: {},
    validation: {}
  };

  handleSubmit = async () => {
    const apiService = new ApiService();

    const errors = validateParameters(this.state);
    this.setState({ validation: errors });
    if (Object.keys(errors).length) {
      return;
    }
    try {
      const result = await apiService.bulkCreate(this.state.areaCode, this.state.phoneNumberCount);
      if (result.data.error) {
        this.setState({ error: result.data.error, result: {} });
      } else {
        this.setState({ error: '', result: result.data });
      }
    } catch (e) {
      this.setState({ error: e, result: {} });
    }
  };

  disply_result = function() {
    if (this.state.result.countAdded) {
      return (
        <Grid container>
          <Grid container>Added {this.state.result.countAdded} numbers.</Grid>
          <Grid container>Assigned {this.state.result.countAssigned} numbers.</Grid>
        </Grid>
      );
    }
  };

  renderErrorMessages = function(errors) {
    const keys = Object.keys(errors || {});
    if (!keys.length) {
      return null;
    }

    return keys.map(key => <FormHelperText key={key}>{this.getErrorMessage(key)}</FormHelperText>);
  };

  getErrorMessage = function(key) {
    switch (key) {
      case 'required':
        return 'Required';
      case 'type':
      default:
        return 'Invalid';
    }
  };

  handleAreaCodeChange = event => {
    var newValue = parseInt(event.target.value);
    if (newValue == NaN) {
      newValue = undefined;
    }
    const prevErrors = this.state.validation;
    const propErrors = validateParameters({ areaCode: newValue })['areaCode'];
    this.setState({ validation: { ...prevErrors, areaCode: propErrors } });

    this.setState({ areaCode: newValue });
  };

  handleCountChange = event => {
    var newValue = parseInt(event.target.value);
    if (newValue == NaN) {
      newValue = undefined;
    }
    const prevErrors = this.state.validation;
    const propErrors = validateParameters({ phoneNumberCount: newValue })['phoneNumberCount'];
    this.setState({ validation: { ...prevErrors, phoneNumberCount: propErrors } });

    this.setState({ phoneNumberCount: newValue });
  };

  render() {
    const { phoneNumberCount, areaCode, error, validation } = this.state;
    if (error) {
      var error_display = <Grid container>Error encountered creating Outreach numbers.</Grid>;
    } else {
      var error_display = '';
    }
    return (
      <MuiThemeProvider theme={bulkCreateTheme}>
        <Fragment>
          <Grid className={'panel'} item xs={6}>
            <Fragment>
              <AppBar>
                <Toolbar>
                  <Grid alignItems="center" container>
                    <Grid item xs>
                      <Typography color="inherit" variant="h6">
                        Bulk Create New Outreach Phone Numbers
                      </Typography>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
              <Grid container spacing={2}>
                <Grid className={'panel'} item xs={6}>
                  <form className={'padded scrollable'}>
                    <FormControl error={!!validation.phoneNumberCount}>
                      <InputLabel htmlFor="phoneNumberCount">How many phone numbers would you like?</InputLabel>
                      <Input
                        id="phoneNumberCount"
                        value={phoneNumberCount}
                        type="number"
                        min="1"
                        onChange={this.handleCountChange}
                      />
                      {this.renderErrorMessages(validation.phoneNumberCount)}
                    </FormControl>
                    <FormControl error={!!validation.areaCode}>
                      <InputLabel htmlFor="areaCode">What area code would you like these numbers to have?</InputLabel>
                      <Input
                        id="areaCode"
                        value={areaCode}
                        type="number"
                        min="100"
                        max="999"
                        onChange={this.handleAreaCodeChange}
                      />
                      {this.renderErrorMessages(validation.areaCode)}
                    </FormControl>
                    <Button onClick={this.handleSubmit}>Create New Phone Numbers</Button>
                  </form>
                </Grid>
                <Grid className={'panel'} item xs={5}>
                  {error_display}
                  {this.disply_result()}
                </Grid>
              </Grid>
            </Fragment>
          </Grid>
        </Fragment>
      </MuiThemeProvider>
    );
  }
}

export default BulkNumberCreation;
