import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { MuiThemeProvider } from '@material-ui/core/styles';
import React, { Component } from 'react';
import userManagementTheme from './theme';
import UserEdit from './UserEdit';
import UserList from './UserList';
import ApiService from './apiService';
import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';
import debounce from 'lodash/debounce';

import { User, CurrentUser } from 'qdw-next/common/data/models';

interface State {
  users: User[];
  visibleUsers: User[];
  allUsersSelected: boolean;
  selectedUsers: number[];
  currentUser: CurrentUser;
  doneLoading: boolean;
  alertIsOpen: boolean;
  alertMessage: string;
  newUserSelected: boolean;
  multiEdit: boolean;
  dialogIsOpen: boolean;
  userIdToDelete: number | undefined;
  dialogMessage: string;
  searchQuery: string;
}
export default class UserManagement extends Component<{}, State> {
  state = {
    doneLoading: false,
    currentUser: {} as CurrentUser,
    users: [],
    visibleUsers: [],
    allUsersSelected: false,
    selectedUsers: [],
    alertIsOpen: false,
    alertMessage: '',
    newUserSelected: false,
    multiEdit: false,
    userIdToDelete: undefined,
    dialogIsOpen: false,
    dialogMessage: '',
    searchQuery: ''
  };

  public updateFilteredUsers = debounce(query => {
    const users = this.state.users;
    const updatedUserList = users.filter((user: User) => {
      const userString = `${user.firstName} ${user.lastName} ${user.username} ${user.groupName} ${user.category}`;
      return userString.toLowerCase().search(query.toLowerCase().trim()) !== -1;
    });

    this.setState({
      searchQuery: query,
      visibleUsers: updatedUserList,
      allUsersSelected: false,
      selectedUsers: []
    });
  }, 400);

  public componentDidMount() {
    const apiService = new ApiService();
    Promise.all([apiService.getUsers(), apiService.getCurrentUser()]).then(([userResults, currentUserResults]) => {
      const users = sortBy(userResults.data, ['disabled'], ['asc']);

      this.setState({
        users,
        visibleUsers: users,
        currentUser: currentUserResults.data,
        doneLoading: true
      });
    });
  }

  public async fetchUsers() {
    const apiService = new ApiService();
    const userResults = await apiService.getUsers();
    const users = sortBy(userResults.data, ['disabled'], ['asc']);
    this.setState({
      users,
      visibleUsers: users,
      doneLoading: true
    });
  }

  public chooseUserToDelete = (userId: number) => {
    const userToDelete: any = this.state.users.find((u: User) => userId === u.id);
    this.setState({
      dialogIsOpen: true,
      dialogMessage: `Are you sure you want to delete ${userToDelete.username}?`,
      userIdToDelete: userId
    });
  };

  public deleteUser = async () => {
    const userId: any = this.state.userIdToDelete;
    const apiService = new ApiService();
    this.setState({ doneLoading: false });

    const deleteResult = await apiService.deleteUser(userId);

    if (deleteResult.response.status === 200) {
      const newSelectedUsers: number[] = [...this.state.selectedUsers];

      if (includes(newSelectedUsers, userId)) {
        newSelectedUsers.splice(newSelectedUsers.indexOf(userId), 1);
      }

      this.setState({
        alertIsOpen: true,
        alertMessage: 'User deleted successfully',
        dialogIsOpen: false,
        userIdToDelete: undefined,
        selectedUsers: newSelectedUsers
      });

      this.fetchUsers();
    } else {
      this.setState({
        alertIsOpen: true,
        alertMessage: 'There was a problem deleting the user',
        dialogIsOpen: false,
        userIdToDelete: undefined
      });
    }
  };

  public allSelected = (newCount: number): boolean => {
    return newCount === this.state.visibleUsers.length;
  };

  public toggleSelectAll = () => {
    const newToggleState = !this.state.allUsersSelected;
    this.setState({
      allUsersSelected: newToggleState,
      selectedUsers: newToggleState ? this.state.visibleUsers.map((u: User) => u.id) : [],
      newUserSelected: false,
      multiEdit: !this.state.multiEdit
    });
  };

  public toggleSelectedUser = (userId: number): void => {
    const newSelectedUsers: number[] = [...this.state.selectedUsers];

    if (includes(newSelectedUsers, userId)) {
      newSelectedUsers.splice(newSelectedUsers.indexOf(userId), 1);
    } else {
      newSelectedUsers.push(userId);
    }

    this.setState({
      allUsersSelected: this.allSelected(newSelectedUsers.length),
      selectedUsers: newSelectedUsers,
      newUserSelected: false,
      multiEdit: newSelectedUsers.length > 1
    });
  };

  public closeDialog = (): void => {
    this.setState({
      dialogIsOpen: false,
      userIdToDelete: undefined
    });
  };

  public closeAlert = (): void => {
    this.setState({
      alertMessage: '',
      alertIsOpen: false
    });
  };

  public processUploadResults(data: any) {
    let alertMessage: string = `${data.details.success} user(s) successfully imported.`;

    if (data.details.failures.length > 0) {
      alertMessage += `The following users were not created: ${data.details.failures.join(', ')}`;
    }

    this.setState({
      alertMessage,
      alertIsOpen: true
    });
  }

  public async uploadFile(formData: any = {}) {
    const apiService = new ApiService();
    const fetchOptions = {
      headers: {
        Accept: 'application/json, application/xml, text/play, text/html, *.*',
        'X-CSRF-Token': (window as any).Rails.csrfToken(),
        'X-Requested-With': 'XMLHttpRequest'
      },
      method: 'POST'
    };
    this.setState({ doneLoading: false });
    const uploadResults = await apiService.uploadFile(formData, fetchOptions);
    if (uploadResults.data) {
      this.fetchUsers();
      this.processUploadResults(uploadResults.data);
    }
  }

  public handleAddNewUser = () => {
    this.setState({ newUserSelected: true, allUsersSelected: false, selectedUsers: [] });
  };

  public onUserCreated = () => {
    this.setState({ searchQuery: '' });
    this.fetchUsers();
  };

  public selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const csvFile: File | null = event.target.files![0];

    if (csvFile) {
      const formData = new FormData();
      formData.append('file', csvFile, csvFile.name);
      this.uploadFile(formData);
    }
  };

  public renderUserList() {
    const { visibleUsers } = this.state;
    const cognitoConfig = (window as any).QDW_EXPORTS.applicationFeatures.cognito;
    return (
      <UserList
        doneLoading={this.state.doneLoading}
        users={visibleUsers}
        allUsersSelected={this.state.allUsersSelected}
        cognitoConfig={cognitoConfig}
        currentUser={this.state.currentUser}
        selectedUsers={this.state.selectedUsers}
        onToggleSelectedUser={this.toggleSelectedUser}
        onToggleSelectAll={this.toggleSelectAll}
        onAddNewUserClick={this.handleAddNewUser}
        onUserFilter={this.updateFilteredUsers}
        onSelectFile={this.selectFile}
        onDeleteUser={this.chooseUserToDelete}
        searchQuery={this.state.searchQuery}
      />
    );
  }

  public render() {
    return (
      <MuiThemeProvider theme={userManagementTheme}>
        <Snackbar
          open={this.state.alertIsOpen}
          autoHideDuration={50000}
          onClose={this.closeAlert}
          message={<span id="message-id">{this.state.alertMessage}</span>}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        />
        <Dialog open={this.state.dialogIsOpen}>
          <DialogTitle id="alert-dialog-title">{'Delete User'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{this.state.dialogMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.deleteUser} color="primary">
              Yes
            </Button>
            <Button onClick={this.closeDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={3}>
          {this.renderUserList()}
          {this.state.doneLoading && (
            <UserEdit
              selectedUsers={this.state.selectedUsers}
              currentUser={this.state.currentUser}
              onUserCreated={this.onUserCreated}
              isNewUser={this.state.newUserSelected}
              multiEdit={this.state.multiEdit}
            />
          )}
        </Grid>
      </MuiThemeProvider>
    );
  }
}
