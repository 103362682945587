import { SingleSelectHierarchyNode } from './SingleSelectHierarchyNode';
import { getDescendantNodes } from './utils';

const defaultDataMemberPaths = {
  children: 'children',
  disabled: 'disabled',
  display: 'name',
  key: 'id'
};

export class SingleSelectHierarchy {
  _dataItems;
  _dataMemberPaths;
  _descendants;
  _nodes;
  _flat;

  get _NodeType() {
    return SingleSelectHierarchyNode;
  }

  get dataItems() {
    return [...this._dataItems];
  }

  get dataMemberPaths() {
    return { ...this._dataMemberPaths };
  }

  get multiple() {
    return false;
  }

  get nodes() {
    return [...this._nodes];
  }

  get flat() {
    return this._flat;
  }

  constructor(options) {
    this._initialize(options);
  }

  _initialize(options) {
    const { checkedKeys, dataItems, dataMemberPaths, flat } = options;

    this._dataItems = dataItems || [];
    this._dataMemberPaths = { ...defaultDataMemberPaths, ...dataMemberPaths };
    this._flat = flat || false;
    this._nodes = this.dataItems.map(
      (dataItem, index) =>
        new this._NodeType({
          dataItem,
          hierarchy: this,
          index,
          level: 0,
          parentNode: this
        })
    );

    this.setChecked(checkedKeys);
  }

  _normalizeCheckedKeys(checkedKeys) {
    if (!checkedKeys) {
      return [];
    } else if (!Array.isArray(checkedKeys)) {
      return [checkedKeys];
    } else {
      return checkedKeys;
    }
  }

  descendants() {
    if (!this._descendants) {
      this._descendants = getDescendantNodes(this);
    }
    return [...this._descendants];
  }

  setChecked(checkedKeys) {
    checkedKeys = this._normalizeCheckedKeys(checkedKeys);

    this.descendants().forEach(d => (d._checked = false));

    if (checkedKeys.length <= 0) {
      return;
    } else if (checkedKeys.length > 1) {
      console.warn(
        'SingleSelectHierarchy only supports one checked node, but many were provided. The first provided value will be used.'
      );
    }

    const checkedKey = checkedKeys[0];
    const matchingNode = this.descendants()
      .filter(d => !d.hasChildren)
      .find(d => d.key === checkedKey);

    if (matchingNode) {
      matchingNode.checked = true;
    }
  }
}
