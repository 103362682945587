import qdwNext from 'qdw-next';
import PcBreakGlassSearchWrapper from 'qdw-next/components/PatientChart/PcBreakGlassSearchWrapper.jsx';
import PcConfigWrapper from 'qdw-next/components/PatientChart/PcConfigWrapper.jsx';

import PcBreakGlassSearchIconWrapper from 'qdw-next/components/PatientChart/PcBreakGlassSearchIconWrapper.jsx';
import PcClinicalChartWrapper from 'qdw-next/components/PatientChart/PcClinicalChartWrapper.jsx';

import * as PcComponents from '@arcadia/pc-components/loader';
function initPcComponents() {
  PcComponents.applyPolyfills().then(() => {
    return PcComponents.defineCustomElements();
  });
}

export function pcConfigWrapperReactDirective(reactDirective) {
  return reactDirective(PcConfigWrapper, undefined, {
    restrict: 'E'
  });
}

export function pcBreakGlassSearchWrapperReactDirective(reactDirective) {
  return reactDirective(PcBreakGlassSearchWrapper, undefined, {
    restrict: 'E'
  });
}

export function pcBreakGlassSearchIconWrapperReactDirective(reactDirective) {
  return reactDirective(PcBreakGlassSearchIconWrapper, undefined, {
    restrict: 'E'
  });
}

export function PcClinicalChartWrapperReactDirective(reactDirective) {
  return reactDirective(PcClinicalChartWrapper, undefined, {
    restrict: 'E'
  });
}
qdwNext.directive('pcConfigWrapper', ['reactDirective', pcConfigWrapperReactDirective]);
qdwNext.directive('pcBreakGlassSearchWrapper', ['reactDirective', pcBreakGlassSearchWrapperReactDirective]);
qdwNext.directive('pcBreakGlassSearchIconWrapper', ['reactDirective', pcBreakGlassSearchIconWrapperReactDirective]);
qdwNext.directive('pcClinicalChartWrapper', ['reactDirective', PcClinicalChartWrapperReactDirective]);

initPcComponents();
