import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import uuid from 'uuid/v4';

import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import CampaignWindow from './CampaignWindow';
import { normalizeCampaignWindow } from './utils';

export default class CampaignWindows extends Component {
  static defaultProps = {
    campaignWindows: [],
    errors: {},
    timeZones: []
  };

  static propTypes = {
    campaignWindows: PropTypes.arrayOf(PropTypes.object),
    errors: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    timeZones: PropTypes.arrayOf(PropTypes.object)
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (isEqual(prevState.campaignWindows, nextProps.campaignWindows)) {
      return null;
    }

    const campaignWindows = nextProps.campaignWindows.map(item => ({ ...item }));
    return { campaignWindows };
  }

  state = {
    campaignWindows: []
  };

  render() {
    return (
      <Fragment>
        <Grid alignItems="center" container>
          <Grid item xs>
            <Typography variant="h6">Windows</Typography>
          </Grid>
          <Grid container item justifyContent="flex-end" xs={2}>
            <Tooltip title="Add New Window">
              <IconButton onClick={this.handleAdd}>
                <AddCircleOutline />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        {this.renderCampaignWindows()}
      </Fragment>
    );
  }

  renderCampaignWindows() {
    const { errors, timeZones } = this.props;
    const { campaignWindows } = this.state;

    if (!campaignWindows.length) {
      return null;
    }

    return campaignWindows
      .filter(campaignWindow => !campaignWindow._destroy)
      .map(campaignWindow => normalizeCampaignWindow(campaignWindow))
      .map(campaignWindow => (
        <CampaignWindow
          key={campaignWindow.id}
          errors={errors[campaignWindow.id]}
          campaignWindow={campaignWindow}
          onChange={this.handleChange}
          onRemove={this.handleRemove}
          timeZones={timeZones}
        />
      ));
  }

  findIndex = campaignWindow => {
    const { campaignWindows } = this.state;
    return campaignWindows.findIndex(cw => cw.id === campaignWindow.id);
  };

  handleAdd = () => {
    const { campaignWindows } = this.state;
    campaignWindows.push(normalizeCampaignWindow({ id: uuid() }));
    this.handleCollectionChange(campaignWindows);
  };

  handleChange = campaignWindow => {
    const { campaignWindows } = this.state;
    const index = this.findIndex(campaignWindow);
    campaignWindows.splice(index, 1, campaignWindow);
    this.handleCollectionChange(campaignWindows);
  };

  handleCollectionChange = campaignWindows => {
    const { onChange } = this.props;
    onChange({ target: { value: campaignWindows } });
  };

  handleRemove = campaignWindow => {
    const { campaignWindows } = this.state;
    const index = this.findIndex(campaignWindow);

    if (isNumber(campaignWindow.id)) {
      campaignWindow._destroy = true;
      campaignWindows.splice(index, 1, campaignWindow);
    } else {
      campaignWindows.splice(index, 1);
    }

    this.handleCollectionChange(campaignWindows);
  };
}
