import wrappedFetch from '../../../common/wrappedFetch';

export default class ApiService {
  constructor() {} // tslint:disable-line: no-empty

  bulkCreate(areaCode, numberOfPhoneNumbers) {
    return wrappedFetch(
      `/api/outreach/bulk_create_phone_number/`,
      JSON.stringify({ parameters: { area_code: areaCode, number_count: numberOfPhoneNumbers } }),
      { method: 'POST' }
    );
  }
}
