import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#31849a'
    },
    secondary: {
      main: '#7cbf4c'
    }
  },
  typography: {
    fontSize: 15
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: 15
      }
    },
    MuiInput: {
      input: {
        fontSize: 15
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: 15
      }
    }
  }
});
