import qs from 'qs';
import angular from 'angular';
const humps = require('lodash-humps');

import { ApiResponse } from 'qdw-next/common/data/utils';
import { HttpError } from './httpError';

export default async function wrappedFetch<TData>(path: string, body: any = {}, options: any = {}) {
  if (options.qs) {
    path = `${path}?${qs.stringify(options.qs)}`;
  }

  if (options.method && (options.method === 'POST' || options.method === 'PUT')) {
    options.body = body;
  }
  options.method = options.method || 'GET';
  options.credentials = 'same-origin';
  const token = await angular
    .element(document.body)
    .injector()
    .get('Auth0Client')
    .getToken();

  options.headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    ...options.headers
  };

  const response = await fetch(path, options);
  const data = humps(await response.json());
  if (response.status >= 300) {
    throw new HttpError(response, data);
  }
  return { response, data } as ApiResponse<TData, Response>;
}
