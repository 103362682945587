/* eslint-disable react/react-in-jsx-scope */
import { Component, Fragment } from 'react';

class PcClinicalChartWrapper extends Component {
  render() {
    const { jwt, client, patientId, apiUrl, patientChartEnv, modalHeader } = this.props;

    return (
      <Fragment>
        <pc-config
          env={patientChartEnv}
          jwt={jwt}
          client={client}
          person-id={patientId}
          tab-name={modalHeader}
        >
          <div className="cm-multi-card-pane clinicalChart">
            <pc-allergies></pc-allergies>
            <pc-orders-results-vitals></pc-orders-results-vitals>
          </div>
        </pc-config>
      </Fragment>
    );
  }
}
export default PcClinicalChartWrapper;
