import qdwNext from 'qdw-next';
import MultiSelect from 'qdw-next/components/GlobalFilters/MultiSelect';

const ngReactOptions = [
  'entity',
  'maxResults',
  'isAllSelected',
  'label',
  ['options', { watchDepth: 'collection' }],
  ['selected', { watchDepth: 'collection' }],
  'spinner',
  ['toggleIsAllSelected', { watchDepth: 'reference' }],
  ['toggleOptionSelected', { watchDepth: 'reference' }]
];

const ngReactProxyDirective = reactDirective => reactDirective(MultiSelect, ngReactOptions);

const filterMultiSelectDirective = () => ({
  controller: [
    '$scope',
    'filterState',
    ($scope, filterState) => {
      $scope.toggleIsAllSelected = () => {
        $scope.isAllSelected = !$scope.isAllSelected;

        if ($scope.isAllSelected) {
          filterState.setIncludeExcludeToggleToInclude($scope.for);
        }

        if (filterState.allOptionFilters.includes($scope.for)) {
          filterState.updateFilterCache($scope.isAllSelected, $scope.for);
        }
      };

      $scope.toggleOptionSelected = newSelectedId => {
        const isCurrentlySelected = $scope.selected.indexOf(newSelectedId) !== -1;

        if (isCurrentlySelected) {
          $scope.selected.splice($scope.selected.indexOf(newSelectedId), 1);
        } else {
          $scope.selected.push(newSelectedId);
        }
      };
    }
  ],
  scope: {
    for: '@',
    isAllSelected: '=allOptionModel',
    label: '@',
    options: '=',
    selected: '=',
    spinner: '=',
    maxResults: '@'
  },
  template: `
    <react-proxy-filter-multi-select
      entity="::for"
      is-all-selected="isAllSelected"
      label="::label"
      options="options"
      selected="selected"
      spinner="spinner"
      max-results="::maxResults"
      toggle-is-all-selected="::toggleIsAllSelected"
      toggle-option-selected="::toggleOptionSelected">
    </react-filter-select>
  `
});

qdwNext.directive('reactProxyFilterMultiSelect', ['reactDirective', ngReactProxyDirective]);
qdwNext.directive('filterMultiSelect', filterMultiSelectDirective);

export { filterMultiSelectDirective, ngReactOptions, ngReactProxyDirective };
