import React, { Component } from 'react';

import Input from '@material-ui/core/Input';

interface SearchBarProps {
  clearQuery: string;
  onUserFilter: (query: string, event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface SearchBarState {
  searchQuery: string;
}
export default class SearchBar extends Component<SearchBarProps, SearchBarState> {
  state = {
    searchQuery: ''
  };

  public filterUserList = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    this.setState({ searchQuery: query });
    this.props.onUserFilter(query, event);
  };

  shouldComponentUpdate(nextProps: SearchBarProps, nextState: SearchBarState) {
    if (nextState.searchQuery === this.state.searchQuery) {
      return false;
    }
    return true;
  }

  componentWillReceiveProps(nextProps: SearchBarProps) {
    if (nextProps.clearQuery !== this.state.searchQuery) {
      this.setState({ searchQuery: nextProps.clearQuery });
    }
  }

  render() {
    return <Input id="userListFilterInput" value={this.state.searchQuery} onChange={this.filterUserList} />;
  }
}
