import {
  CurrentUser,
  Dashboard,
  PageConfiguration,
  Report,
  Initiative,
  Widget,
  EwaDashboards
} from 'qdw-next/common/data/models';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import mapKeys from 'lodash/mapKeys';

export default class PageMultiSelectService {
  readonly abbreviations = ['ed'];

  reports: Report[];
  initiatives: Initiative[];
  pageConfigurations: PageConfiguration[];
  widgets: any;
  ewaDashboards: any;
  currentUser: CurrentUser | undefined;

  constructor(
    reports: Report[],
    initiatives: Initiative[],
    pageConfigurations: PageConfiguration[],
    widgets: any,
    ewaDashboards: EwaDashboards[],
    currentUser: CurrentUser | undefined
  ) {
    this.reports = reports;
    this.initiatives = initiatives;
    this.pageConfigurations = pageConfigurations;
    this.widgets = widgets;
    this.ewaDashboards = ewaDashboards;
    this.currentUser = currentUser;
  }

  getReports = (): Report[] => {
    return this.filteredReports();
  };

  getWidgets = () => {
    return this.widgets.widgets.map((widget: Widget) => {
      return mapKeys(widget, (value, key) => {
        return key === 'title' ? 'name' : key;
      });
    });
  };

  // the goal here is to map the db schema to the view schema which is {name: string, id: number}
  // id is set in controller but name is actually dashboardName so we need this mapping below
  getEwaDashboards = () => {
    return this.ewaDashboards.ewaDashboards;
  };

  getInitiatives = (): Initiative[] => {
    return this.initiatives;
  };

  getDashboards = (): Dashboard[] => {
    const pageConfigs = this.pageConfigurations.filter(config => config.dashboard);
    const dashboards: Dashboard[] = pageConfigs.map(dashboard => ({
      id: dashboard.title,
      name: this.getNameFromTitle(dashboard.title)
    }));

    return dashboards;
  };

  private filteredReports = () => {
    let filteredReports: Report[] = this.reports.filter(report => report.name !== 'Utilization Detail');
    const userReports = get(this.currentUser, 'reports', []);
    if (this.currentUser && !isEqual(userReports, [0])) {
      filteredReports = filteredReports.filter(report => this.currentUser && this.currentUser.reports.indexOf(report.id) > -1);
      // TODO: need to fix this extra this.currentUser
    }
    return filteredReports;
  };

  private formatWord = (word: string) => {
    if (word in this.abbreviations) {
      return word.toUpperCase();
    }
    return `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`;
  };

  private getNameFromTitle = (title: string) => {
    return title
      .split('-')
      .map(this.formatWord)
      .join(' ');
  };
}
