/* eslint-disable react/react-in-jsx-scope */
import { Component, Fragment } from 'react';
import * as $ from 'jquery';

class PcConfigWrapper extends Component {
  componentDidMount() {
    $('cm-enroll-suppl-card')
      .detach()
      .prependTo('.cm-multi-card-pane.summary');

    document.addEventListener('selectPatient', event => {
      const url = new URL(window.location.href);
      url.search = `?cmPersonId=${event.detail.patientId}&cmTab=chart`;
      window.location.href = url.toString();
    });
  }
  render() {
    const { jwt, client, patientId, apiUrl, defaultAlgorithm, enabledAlgorithms, modalHeader, patientChartEnv } = this.props;

    return (
      <Fragment>
        <pc-config env={patientChartEnv} jwt={jwt} client={client} person-id={patientId}>
          <div className="cm-multi-card-pane summary">
            <pc-summarize></pc-summarize>
            <pc-patient-information></pc-patient-information>
            <pc-next-best-action></pc-next-best-action>
            <pc-quality-guidelines modal-header={modalHeader}></pc-quality-guidelines>
            <pc-risk-adjustment
              default-algorithm={defaultAlgorithm}
              enabled-algorithms={enabledAlgorithms}
              modal-header={modalHeader}
              parent="DeliveryPatientChart"
            ></pc-risk-adjustment>
            <pc-visits-appointments></pc-visits-appointments>
            <pc-medications modal-header={modalHeader}></pc-medications>
            <pc-forms></pc-forms>
          </div>
        </pc-config>
      </Fragment>
    );
  }
}

export default PcConfigWrapper;
