import { Component } from 'react';

class PcBreakGlassSearchWrapper extends Component {
  componentDidMount() {
    document.addEventListener('breakGlassSelectPatient', event => {
      const url = new URL(window.location.href);
      url.search = `?cmPersonId=${event.detail.personId}&cmTab=chart`;
      window.location.href = url.toString();
    });
  }

  render() {
    const { apiUrl, patientChartEnv, jwt, client } = this.props;

    return (
      <pc-config env={patientChartEnv} jwt={jwt} client={client}>
        <pc-break-glass-search api-url={apiUrl} tooltip-enabled={true} />
      </pc-config>
    );
  }
}

export default PcBreakGlassSearchWrapper;
