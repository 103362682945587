import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import pluralize from 'pluralize';

import Checkbox from './Checkbox';
import SearchInput from './SearchInput';

export default class MultiSelect extends Component {
  static defaultProps = {
    maxResults: 500
  };

  static propTypes = {
    maxResults: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    entity: PropTypes.string,
    isAllSelected: PropTypes.bool,
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string
      })
    ),
    selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    spinner: PropTypes.bool,
    toggleIsAllSelected: PropTypes.func,
    toggleOptionSelected: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = { searchFilter: '' };
  }

  render() {
    const { entity, isAllSelected, label } = this.props;
    const formattedLabel = pluralize(label).toLowerCase();
    const isAllSelectedMessage = isAllSelected ? `All ${formattedLabel} selected` : `Select all ${formattedLabel}`;
    const wrapperId = `${entity}-select`;
    const labelFor = `${entity}-all-option`;

    return (
      <div id={wrapperId} className="multi-select-height-lg">
        <div className="title-section">
          <div className="checkbox-wrapper">
            <label onClick={this.handleAllSelectionClick} htmlFor={labelFor}>
              <Checkbox checked={isAllSelected} /> <span className="multi-select-msg">{isAllSelectedMessage}</span>
            </label>
          </div>
          {this.renderSearchInput()}
        </div>
        {this.renderOptions()}
        {this.renderMaxResultsMessage()}
      </div>
    );
  }

  // Render methods

  renderMaxResultsMessage() {
    const { options, isAllSelected, maxResults } = this.props;
    const { searchFilter } = this.state;
    const filteredOptions = this.filterForTerm(options, searchFilter);

    if (isAllSelected || filteredOptions.length < maxResults) {
      return null;
    }

    return (
      <div className="max-results-message">
        Showing top {maxResults} results<br />
        Refine your search to see others
      </div>
    );
  }

  renderOptions() {
    const { entity, isAllSelected, options, spinner, maxResults } = this.props;
    const { searchFilter } = this.state;

    if (isAllSelected) {
      return null;
    }

    const containerClassNames = classNames('multi-select-results', `${entity}-results`);

    let filteredOptions = this.filterForTerm(options, searchFilter);
    if (filteredOptions.length > maxResults) {
      filteredOptions = filteredOptions.slice(0, maxResults);
    }

    const optionComponents = filteredOptions.map(({ id, name }) => (
      <li key={id}>
        <label title={name} onClick={this.handleOptionClick(id)}>
          <Checkbox checked={this.isSelected(id)} /> <span>{name}</span>
        </label>
      </li>
    ));

    return (
      <div className={containerClassNames}>
        <ul className="margin-top-reduced">{optionComponents}</ul>
        {spinner && <div className="loading-overlay" />}
      </div>
    );
  }

  renderSearchInput() {
    const { isAllSelected } = this.props;
    const { searchFilter } = this.state;

    if (isAllSelected) {
      return null;
    }

    return <SearchInput onValueUpdate={this.handleSearchValueUpdate} value={searchFilter} />;
  }

  // Callbacks

  handleAllSelectionClick = () => {
    this.props.toggleIsAllSelected();
  };

  handleOptionClick = optionId => {
    return () => {
      this.props.toggleOptionSelected(optionId);
    };
  };

  handleSearchValueUpdate = value => {
    this.setState({ searchFilter: value });
  };

  // Utility methods

  filterForTerm(options, searchTerm) {
    const byLowercaseName = ({ name }) => name.toLowerCase().includes(searchTerm.toLowerCase());
    return searchTerm && searchTerm.length ? options.filter(byLowercaseName) : options;
  }

  isSelected(optionId) {
    const { selected } = this.props;
    return selected.includes(optionId);
  }
}
