import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

export function formatUpside(upside) {
  const floatUpside = parseFloat(upside);

  if (!isNaN(floatUpside)) {
    return floatUpside.toFixed(2).replace(/^0+\./, '.');
  }

  return isNull(upside) || isUndefined(upside) ? upside : upside.toString().toUpperCase();
}

export function sourceFlagNameForValue(value) {
  switch (+value) {
    case 1:
      return 'Check';
    case 0:
      return 'Exclamation';
    case -1:
      return 'Close';
  }
}
