import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: 'transparent'
      },
      root: {
        boxShadow: 'unset',
        padding: '0 15px'
      }
    }
  },
  props: {
    MuiAppBar: {
      color: 'default',
      position: 'static'
    },
    MuiFormControl: {
      fullWidth: true,
      margin: 'normal'
    },
    MuiToolbar: {
      disableGutters: true
    }
  }
});

export default theme;
