import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import HierarchyListNode from './HierarchyListNode';
import { MultiSelectHierarchy, SingleSelectHierarchy } from '../../data';

export default class HierarchyList extends Component {
  static eventTypes = {
    checked: 'checked',
    expanded: 'expanded',
    visible: 'visible'
  };

  static propTypes = {
    hierarchy: PropTypes.oneOfType([PropTypes.instanceOf(MultiSelectHierarchy), PropTypes.instanceOf(SingleSelectHierarchy)])
      .isRequired,
    onHierarchyChange: PropTypes.func,
    renderNodeDisplay: PropTypes.func
  };

  handleHierarchyChange(node, propName, nextPropValue, eventType) {
    const { hierarchy, onHierarchyChange } = this.props;

    onHierarchyChange &&
      onHierarchyChange({
        eventType,
        hierarchy,
        nextPropValue,
        node,
        propName
      });
  }

  handleNodeCheckedChange = (node, nextValue) =>
    this.handleHierarchyChange(node, 'checked', nextValue, HierarchyList.eventTypes.checked);

  handleNodeExpandedChange = (node, nextValue) =>
    this.handleHierarchyChange(node, 'expanded', nextValue, HierarchyList.eventTypes.expanded);

  render() {
    const { hierarchy } = this.props;
    const nodes = hierarchy.descendants().filter(d => d.visible && (d.level === 0 || d.ancestors().every(a => a.expanded)));
    return <Fragment>{nodes.map(n => this.renderNode(n))}</Fragment>;
  }

  renderNode(node) {
    const { renderNodeDisplay } = this.props;
    return (
      <HierarchyListNode
        key={node.key}
        node={node}
        onNodeCheckedChange={this.handleNodeCheckedChange}
        onNodeExpandedChange={this.handleNodeExpandedChange}
        renderNodeDisplay={renderNodeDisplay}
      />
    );
  }
}
