import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import ApiService from './apiService';

export class Dashboard extends Component {
  static propTypes = {
    classes: PropTypes.object
  };

  flattenedTemplates = null;

  state = {
    sendThroughputs: [],
    loading: false
  };

  getDateForToday() {
    const today = new Date();
    let day = today.getDate();
    if (Number.parseInt(day) < 10) {
      day = `0${day}`;
    }
    const month = today.getMonth() + 1;
    return `${today.getFullYear()}-${month}-${day}`;
  }

  componentDidMount = async () => {
    this.setState({ loading: true });
    const apiService = new ApiService();
    const date = this.getDateForToday();
    const result = await apiService.sendThroughputs(date);
    this.setState({ sendThroughputs: result.data, loading: false });
  };

  getData = async dateEvent => {
    const apiService = new ApiService();
    const result = await apiService.sendThroughputs(dateEvent.target.value);
    this.setState({ sendThroughputs: result.data });
  };

  render() {
    const { loading } = this.state;

    if (loading) {
      return this.renderLoadingIndicator();
    }

    return (
      <Fragment>
        {this.renderDatePicker()}
        {this.renderSendThroughput()}
        {/* {this.renderErrorCodesPerLine()} */}
      </Fragment>
    );
  }

  renderLoadingIndicator() {
    return (
      <Grid alignItems="center" className={'progressPanel'} container item justifyContent="center" xs>
        <CircularProgress size={120} />
      </Grid>
    );
  }

  renderDatePicker() {
    return (
      <form className={'dateContainer'} noValidate>
        <TextField
          id="date"
          label="Selected Date"
          type="date"
          defaultValue={this.getDateForToday()}
          className={'dateTextField'}
          InputLabelProps={{
            shrink: true
          }}
          onChange={this.getData}
        />
      </form>
    );
  }

  renderSendThroughput() {
    return (
      <Paper className={'tableContainer'}>
        <Typography className={'tableTitle'} variant="subtitle1">
          Outreach Campaign Send Throughput
        </Typography>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Campaign Name</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Window</TableCell>
              <TableCell align="right">Number of Lines</TableCell>
              <TableCell align="right">Messages per Line per Day</TableCell>
              <TableCell align="right">Daily Message Limit</TableCell>
              <TableCell align="right">Window Message Limit</TableCell>
              <TableCell align="right">Expected Throughput</TableCell>
              <TableCell align="right">Actual Throughput</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.sendThroughputs.map((row, index) => (
              <TableRow key={row.campaignName + index}>
                <TableCell component="th" scope="row">
                  {row.campaignName}
                </TableCell>
                <TableCell align="right">{row.date}</TableCell>
                <TableCell align="right">{row.window}</TableCell>
                <TableCell align="right">{row.numberOfLines}</TableCell>
                <TableCell align="right">{row.messagesPerLinePerDay}</TableCell>
                <TableCell align="right">{row.dailyMessageLimit}</TableCell>
                <TableCell align="right">{row.windowMessageLimit}</TableCell>
                <TableCell align="right">{row.expectedThroughput}</TableCell>
                <TableCell align="right">{row.actualThroughput}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  renderErrorCodesPerLine() {
    // note that the columns like 0, 20000, 30000 will probably be variable
    return (
      <Paper className={'tableContainer'}>
        <Typography className={'tableTitle'} variant="subtitle1">
          Error Codes by Line
        </Typography>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Hour</TableCell>
              <TableCell align="right">Line</TableCell>
              <TableCell align="right">0</TableCell>
              <TableCell align="right">20000</TableCell>
              <TableCell align="right">30000</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.errorCodePerLineRows.map(row => (
              <TableRow key={row.hour}>
                <TableCell align="right">{row.date}</TableCell>
                <TableCell align="right">{row.hour}</TableCell>
                <TableCell align="right">{row.line}</TableCell>
                <TableCell align="right">{row.err0}</TableCell>
                <TableCell align="right">{row.err1}</TableCell>
                <TableCell align="right">{row.err2}</TableCell>
                <TableCell align="right">{row.total}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default Dashboard;
