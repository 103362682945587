import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import { Field } from 'react-final-form';

import CategoryField from './fields/CategoryField';
import PeriodField from './fields/PeriodField';
import StatusField from './fields/StatusField';
import TextField from './fields/TextField';
import { composeValidators, required } from './fields/validators';
import styles from './styles.module.scss';

export default class ConditionAddNew extends Component {
  static defaultProps = {
    categories: [],
    isSubmitting: false,
    periods: [],
    statuses: []
  };

  static propTypes = {
    categories: PropTypes.arrayOf(PropTypes.object),
    isSubmitting: PropTypes.bool,
    name: PropTypes.string.isRequired,
    statuses: PropTypes.arrayOf(PropTypes.object),
    periods: PropTypes.arrayOf(PropTypes.object)
  };

  render() {
    const { categories, isSubmitting, name, periods, statuses } = this.props;

    return (
      <div className={styles.conditionFields}>
        <Field name={`${name}.category`} validate={composeValidators(required())}>
          {props => <CategoryField {...props} categories={categories} isSubmitting={isSubmitting} />}
        </Field>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Field name={`${name}.eventCode`}>
              {props => <TextField {...props} isSubmitting={isSubmitting} label={'Code'} />}
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field name={`${name}.yearAt`}>
              {props => <PeriodField {...props} isSubmitting={isSubmitting} periods={periods} />}
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name={`${name}.riskStatusId`}>
              {props => <StatusField {...props} isSubmitting={isSubmitting} statuses={statuses} />}
            </Field>
          </Grid>
        </Grid>
        <Field name={`${name}.notes`}>
          {props => <TextField {...props} isSubmitting={isSubmitting} label={'Notes'} multiline={true} />}
        </Field>
      </div>
    );
  }
}
