import PropTypes from 'prop-types';
import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';

import SelectField from './SelectField';

export default function CategoryField({ categories, ...props }) {
  return (
    <SelectField label="Category" {...props}>
      {categories.map(({ categoryId, groupId, name }) => {
        const id = `${categoryId}-${groupId}`;
        return (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        );
      })}
    </SelectField>
  );
}

CategoryField.defaultProps = {
  categories: []
};

CategoryField.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.number,
      groupId: PropTypes.number,
      name: PropTypes.string,
      riskAlgorithmId: PropTypes.number
    })
  )
};
