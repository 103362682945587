export interface AdminLevel {
  id: string;
  name: string;
}

export const userTypes = [{ id: 'plan', name: 'Plan' }, { id: 'clinical', name: 'Clinical' }];

export const defaultAttributions = [
  { id: 'cost_derived', name: 'Cost Derived' },
  { id: 'plan', name: 'Plan' },
  { id: 'clinical', name: 'Clinical' },
  { id: 'functional', name: 'Functional' },
  { id: 'event_derived', name: 'Event Derived' },
  { id: 'rendering', name: 'Rendering' }
];

export const adminLevels = [{ id: 'regular_user', name: 'Regular User' }, { id: 'group_manager', name: 'Group Manager' }];

export * from './states';
