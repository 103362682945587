import React from 'react';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List, ListRowRenderer } from 'react-virtualized';

import UserDetails from './UserDetails';
import { User } from 'qdw-next/common/data/models';
import styles from './styles.module.scss';

interface Props {
  users: User[];
  selectedUsers: number[];
  onDeleteUser: (id: number) => void;
  onToggleSelectedUser(id: number): void;
}

export default class VirtualizedUserList extends React.Component<Props, any> {
  cache = new CellMeasurerCache({ fixedWidth: true });
  private listComponent: List | null;

  constructor(props: Props) {
    super(props);
    this.listComponent = null;
  }

  componentDidUpdate(prevProps: Props) {
    this.cache.clearAll();
    if (this.listComponent) {
      this.listComponent.forceUpdateGrid();
    }
  }

  render() {
    const numRows = this.props.users.length;
    let maxHeight = styles.maxHeight;
    if (this.props.selectedUsers.length > 1) {
      maxHeight = styles.maxHeightMultiEdit;
    }

    if (this.props.selectedUsers.length < 1) {
      maxHeight = styles.maxHeightNewUser;
    }

    if (this.props.users.length < 1) {
      maxHeight = styles.maxHeightNoUsers;
    }

    return (
      <div className={maxHeight}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              style={{ outline: 'none' }}
              ref={list => (this.listComponent = list)}
              height={height}
              width={width}
              rowCount={numRows}
              rowHeight={this.cache.rowHeight}
              deferredMeasurementCache={this.cache}
              rowRenderer={this.rowItemRenderer}
            />
          )}
        </AutoSizer>
      </div>
    );
  }

  private rowItemRenderer: ListRowRenderer = ({ key, index, parent, style }) => {
    return (
      <CellMeasurer cache={this.cache} key={key} columnIndex={0} rowIndex={index} parent={parent} style={style}>
        {() => this.renderUser(key, index, style)}
      </CellMeasurer>
    );
  };

  private renderUser = (key: number | string, index: number, style?: any) => {
    const user = this.props.users[index];
    return (
      <UserDetails
        selectedUsers={this.props.selectedUsers}
        key={user.id}
        onToggleSelectedUser={this.props.onToggleSelectedUser}
        onDeleteUser={this.props.onDeleteUser}
        user={user}
        style={style}
      />
    );
  };
}
