import PropTypes from 'prop-types';
import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';

import SelectField from './SelectField';

export default function StatusField({ statuses, ...props }) {
  return (
    <SelectField label="Status" {...props}>
      <MenuItem key="no-status" value="">
        No Status
      </MenuItem>
      {statuses.map(({ id, description }) => (
        <MenuItem key={id} value={id}>
          {description}
        </MenuItem>
      ))}
    </SelectField>
  );
}

StatusField.defaultProps = {
  statuses: []
};

StatusField.propTypes = {
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string
    })
  )
};
