export class HttpError extends Error {
  name = this.constructor.name;
  body = {} as any;

  constructor(response: Response, body: any) {
    super();
    Object.setPrototypeOf(this, HttpError.prototype);
    this.message = response.statusText;
    this.body = body;
  }
}
