import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Input from '@material-ui/core/Input';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';

export const styles = {
  paper: {
    overflow: 'auto',
    maxHeight: '400px'
  }
};

export class PopperInput extends Component {
  static propTypes = {
    InputProps: PropTypes.object,
    classes: PropTypes.object,
    PopperProps: PropTypes.object,
    children: PropTypes.node,
    displayValue: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
      PropTypes.number,
      PropTypes.string
    ]),
    id: PropTypes.any
  };

  state = {
    anchorEl: null,
    popperWidth: null
  };

  handleClose = event => {
    if (this.anchorEl && this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ anchorEl: null });
  };

  render() {
    const { InputProps, PopperProps, children, displayValue, id } = this.props;
    const { anchorEl, popperWidth } = this.state;
    const isOpen = !!anchorEl;

    return (
      <Fragment>
        <Input
          {...InputProps}
          endAdornment={<ArrowDropDown />}
          id={id}
          {...InputProps && { error: InputProps.error }}
          onClick={this.handleOpen}
          readOnly={true}
          value={displayValue || ''}
          inputRef={node => {
            this.anchorEl = node;
          }}
        />
        <Popper
          placement="top"
          modifiers={{
            flip: {
              enabled: false
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent'
            }
          }}
          anchorEl={anchorEl}
          popperOptions={{ positionFixed: true }}
          open={isOpen}
        >
          <ClickAwayListener onClickAway={this.handleClose}>
            <Paper
              className={this.props.classes.paper}
              style={{ minWidth: (PopperProps && PopperProps.popperwidth) || popperWidth }}
            >
              {children}
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Fragment>
    );
  }

  handleOpen = event =>
    this.setState({
      anchorEl: event.currentTarget,
      popperWidth: event.currentTarget.offsetWidth
    });
}

export default withStyles(styles)(PopperInput);
