import PropTypes from 'prop-types';
import React from 'react';

import { Field } from 'react-final-form';

import StatusField from './fields/StatusField';
import TextField from './fields/TextField';
import styles from './styles.module.scss';

export default function ConditionEditor({ name, isSubmitting, statuses }) {
  return (
    <div className={styles.conditionFields}>
      <Field name={`${name}.riskStatusId`}>
        {props => <StatusField {...props} isSubmitting={isSubmitting} statuses={statuses} />}
      </Field>
      <Field name={`${name}.notes`}>
        {props => <TextField {...props} isSubmitting={isSubmitting} label={'Notes'} multiline={true} />}
      </Field>
    </div>
  );
}

ConditionEditor.defaultProps = {
  isSubmitting: false,
  statuses: []
};

ConditionEditor.propTypes = {
  isSubmitting: PropTypes.bool,
  name: PropTypes.string.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.object)
};
