import pickBy from 'lodash/pickBy';
import UserEditFormState, { FormFieldsChanged } from './UserEditFormState';

export default class BulkUpdateService {
  public multiEditEnabled: boolean;
  public fieldsChanged: FormFieldsChanged;
  public state: UserEditFormState;

  constructor(multiEditEnabled: boolean, fieldsChanged: FormFieldsChanged, state: UserEditFormState) {
    this.multiEditEnabled = multiEditEnabled;
    this.fieldsChanged = fieldsChanged;
    this.state = state;
  }

  buildPatchRequest() {
    const adminLevelMultiEditChange = this.multiEditFieldChanged(this.state.adminLevelSelected);
    const userTypeMultiEditChange = this.multiEditFieldChanged(this.state.userTypeSelected);
    const providerTypeMultiEditChange = this.multiEditFieldChanged(this.state.defaultAttributionSelected);
    const binderyMultiEditChange = this.multiEditFieldChanged(this.state.binderySelected);
    const USStateMultiEditChange = this.multiEditFieldChanged(this.state.USState);

    const outreachMultiEditChange = this.multiEditIndeterminateFieldChanged(this.state.outreach);
    const exportMultiEditChange = this.multiEditIndeterminateFieldChanged(this.state.export);
    const careManageMultiEditChange = this.multiEditIndeterminateFieldChanged(this.state.careManage);
    const careManagerAdminMultiEditChange = this.multiEditIndeterminateFieldChanged(this.state.careManagerAdmin);
    const desktopMultiEditChange = this.multiEditIndeterminateFieldChanged(this.state.desktop);
    const referralMultiEditChange = this.multiEditIndeterminateFieldChanged(this.state.referral);
    const referralPatientChartMultiEditChange = this.multiEditIndeterminateFieldChanged(this.state.referralPatientChart);

    const groupMultiEditChange = this.multiEditGroupChanged(this.state.userGroupSelected);

    const bulkUpdate: any = {
      email: this.fieldsChanged.email ? this.state.email : undefined,
      username: this.fieldsChanged.username ? this.state.username : undefined,
      firstName: this.fieldsChanged.firstName ? this.state.firstName : undefined,
      lastName: this.fieldsChanged.lastName ? this.state.lastName : undefined,
      phoneNumber: this.fieldsChanged.phoneNumber ? this.state.phoneNumber : undefined,
      address: this.fieldsChanged.streetAddress ? this.state.streetAddress : undefined,
      city: this.fieldsChanged.city ? this.state.city : undefined,
      state: this.fieldsChanged.USState && !this.multiEditEnabled ? this.state.USState : USStateMultiEditChange,
      zip: this.fieldsChanged.zip ? this.state.zip : undefined,
      npi: this.fieldsChanged.npi ? this.state.npi : undefined,
      category: this.fieldsChanged.category ? this.state.category : undefined,
      credentials: this.fieldsChanged.credentials ? this.state.credentials : undefined,

      role:
        this.fieldsChanged.adminLevelSelected && !this.multiEditEnabled
          ? this.state.adminLevelSelected
          : adminLevelMultiEditChange,

      group_id:
        this.fieldsChanged.userGroupSelected && !this.multiEditEnabled ? this.state.userGroupSelected : groupMultiEditChange,

      type: this.fieldsChanged.userTypeSelected && !this.multiEditEnabled ? this.state.userTypeSelected : userTypeMultiEditChange,
      providerType: this.fieldsChanged.defaultAttributionSelected
        ? this.state.defaultAttributionSelected
        : providerTypeMultiEditChange,

      plans: this.fieldsChanged.plansSelected && this.state.userTypeSelected === 'plan' ? this.state.plansSelected : undefined,
      providers: this.fieldsChanged.providersSelected ? this.state.providersSelected : undefined,
      sources: this.fieldsChanged.sourcesSelected ? this.state.sourcesSelected : undefined,

      reports: this.fieldsChanged.reportsSelected ? this.state.reportsSelected : undefined,
      initiatives: this.fieldsChanged.initiativesSelected ? this.state.initiativesSelected : undefined,
      dashboards: this.fieldsChanged.dashboardsSelected ? this.state.dashboardsSelected : undefined,
      widgets: this.fieldsChanged.widgetsSelected ? this.state.widgetsSelected : undefined,
      ewaDashboards: this.fieldsChanged.ewaDashboardsSelected ? this.state.ewaDashboardsSelected : undefined,

      binderyAccess:
        this.fieldsChanged.binderySelected && !this.multiEditEnabled ? this.state.binderySelected : binderyMultiEditChange,

      outreachAbility: this.fieldsChanged.outreach ? outreachMultiEditChange : undefined,
      outreachTemplates: this.fieldsChanged.templatesSelected ? this.convertToString(this.state.templatesSelected) : undefined,
      exportAbility: this.fieldsChanged.export ? exportMultiEditChange : undefined,
      careManager: this.fieldsChanged.careManage ? careManageMultiEditChange : undefined,
      careManagerAdmin: this.fieldsChanged.careManagerAdmin ? careManagerAdminMultiEditChange : undefined,
      desktopAccess: this.fieldsChanged.desktop ? desktopMultiEditChange : undefined,
      referralAccess: this.fieldsChanged.referral ? referralMultiEditChange : undefined,
      referralPatientChart: this.fieldsChanged.referralPatientChart ? referralPatientChartMultiEditChange : undefined,

      electronicFormPermissions: this.fieldsChanged.electronicFormRoleSelected
        ? this.state.electronicFormRoleSelected
        : undefined,
      hmrPermissions: this.fieldsChanged.hmrRoleSelected ? this.state.hmrRoleSelected : undefined,
      hraPermissions: this.fieldsChanged.hraRoleSelected ? this.state.hraRoleSelected : undefined,
      hpmPermissions: this.fieldsChanged.hpmRoleSelected ? this.state.hpmRoleSelected : undefined,
      hraAuditPermissions: this.fieldsChanged.hraAuditRoleSelected ? this.state.hraAuditRoleSelected : undefined,
      paperFormPermissions: this.fieldsChanged.paperFormRoleSelected ? this.state.paperFormRoleSelected : undefined,
      disabled: this.fieldsChanged.disabled ? this.state.disabled : undefined
    };

    return pickBy(bulkUpdate, value => value !== undefined);
  }

  private multiEditFieldChanged(state: any) {
    return state !== 'unchanged' && this.multiEditEnabled ? state : undefined;
  }

  private multiEditGroupChanged(state: any) {
    return state !== 0 && this.multiEditEnabled ? state : undefined;
  }

  private multiEditIndeterminateFieldChanged(state: boolean | null) {
    return state !== null ? state : undefined;
  }

  private convertToString(state: any) {
    return state.map((field: any) => field.toString());
  }
}
