import { SingleSelectHierarchy } from './SingleSelectHierarchy';
import { MultiSelectHierarchyNode } from './MultiSelectHierarchyNode';

export class MultiSelectHierarchy extends SingleSelectHierarchy {
  get _NodeType() {
    return MultiSelectHierarchyNode;
  }

  get multiple() {
    return true;
  }

  _setChecked(nodes, checkedKeys) {
    nodes.forEach(n => {
      const checked = checkedKeys.includes(n.key);
      if (checked) {
        n.checked = checked;
      } else if (n.hasChildren) {
        this._setChecked(n.nodes, checkedKeys);
      }
    });
  }

  setChecked(checkedKeys) {
    checkedKeys = this._normalizeCheckedKeys(checkedKeys);
    this.descendants().forEach(n => (n._checked = false));

    if (checkedKeys.length <= 0) {
      return;
    }

    this._setChecked(this.nodes, checkedKeys);
  }
}
