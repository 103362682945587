import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import { MuiThemeProvider } from '@material-ui/core/styles';

import CampaignForm from './CampaignForm';
import CampaignList from './CampaignList';
import TemplateViewer from './TemplateViewer';
import ExecutionPlanViewer from './ExecutionPlanViewer';
import ApiService from './apiService';

import campaignManagerTheme from './theme';
import { flattenTemplates } from './utils';

import scss from './styles.module.scss';

export class CampaignManager extends Component {
  static defaultProps = {
    campaigns: [],
    durationTypes: [],
    lobs: [],
    memberProducts: [],
    languages: [],
    loading: false,
    measures: [],
    patientLists: [],
    periodTypes: [],
    providers: [],
    providerAttributionTypes: [],
    appointmentProviderLocations: [],
    templates: [],
    timeZones: []
  };

  static propTypes = {
    campaigns: PropTypes.arrayOf(PropTypes.object),
    classes: PropTypes.object,
    durationTypes: PropTypes.arrayOf(PropTypes.object),
    languages: PropTypes.arrayOf(PropTypes.object),
    lobs: PropTypes.arrayOf(PropTypes.object),
    memberProducts: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    measures: PropTypes.arrayOf(PropTypes.object),
    patientLists: PropTypes.arrayOf(PropTypes.object),
    onCampaignUpsert: PropTypes.func.isRequired,
    periodTypes: PropTypes.arrayOf(PropTypes.object),
    providers: PropTypes.arrayOf(PropTypes.object),
    providerAttributionTypes: PropTypes.arrayOf(PropTypes.object),
    appointmentProviderLocations: PropTypes.arrayOf(PropTypes.object),
    templates: PropTypes.arrayOf(PropTypes.object),
    timeZones: PropTypes.arrayOf(PropTypes.object)
  };

  flattenedTemplates = null;

  state = {
    selectedCampaign: null,
    selectedTemplate: null,
    executionPlan: {},
    showExecutionPlan: false,
    alertMessage: null,
    showAlert: false,
    loadingExecutionPlan: false
  };

  constructor(props) {
    super(props);

    this.createFlattenedTemplates();
  }

  componentDidUpdate(prevProps) {
    this.createFlattenedTemplates(prevProps);
  }

  createFlattenedTemplates(prevProps) {
    const { templates } = this.props;

    if (!isEqual(get(prevProps, 'templates'), templates)) {
      this.flattenedTemplates = flattenTemplates(templates);
    }
  }

  render() {
    return (
      <MuiThemeProvider theme={campaignManagerTheme}>
        <Grid container>{this.renderPanels()}</Grid>
      </MuiThemeProvider>
    );
  }

  renderCampaignForm() {
    const {
      durationTypes,
      languages,
      lobs,
      memberProducts,
      measures,
      patientLists,
      periodTypes,
      providers,
      providerAttributionTypes,
      appointmentProviderLocations,
      templates,
      timeZones
    } = this.props;
    const { selectedCampaign } = this.state;

    if (!selectedCampaign) {
      return null;
    }

    return (
      <Grid className={'panel'} item xs={6}>
        <CampaignForm
          apptProviderLocations={appointmentProviderLocations}
          campaign={selectedCampaign}
          durationTypes={durationTypes}
          languages={languages}
          lobs={lobs}
          sourceList={uniqBy(appointmentProviderLocations, 'source_id')}
          locationList={uniqBy(
            appointmentProviderLocations.filter(
              apl => selectedCampaign.sourceList && selectedCampaign.sourceList.includes(apl.source_id)
            ),
            'location_id'
          )}
          providerList={uniqBy(
            appointmentProviderLocations.filter(
              apl =>
                selectedCampaign.sourceList &&
                selectedCampaign.sourceList.includes(apl.source_id) &&
                selectedCampaign.locationList &&
                selectedCampaign.locationList.includes(apl.location_id)
            ),
            'provider_id'
          )}
          memberProducts={memberProducts}
          measures={measures}
          patientLists={patientLists}
          onChange={this.handleCampaignFormChange}
          onUpsert={this.handleCampaignUpsert}
          periodTypes={periodTypes}
          providers={providers}
          providerAttributionTypes={providerAttributionTypes}
          templates={templates}
          timeZones={timeZones}
        />
      </Grid>
    );
  }

  renderCampaignList() {
    const { campaigns } = this.props;

    return (
      <Fragment>
        <Grid className={'panel'} item xs={3}>
          <CampaignList
            campaigns={campaigns}
            onSelectedCampaignChange={this.handleSelectedCampaignChange}
            selectedCampaign={this.state.selectedCampaign}
          />
        </Grid>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.showAlert} autoHideDuration={5000}>
          {this.state.alertMessage}
        </Snackbar>
      </Fragment>
    );
  }

  renderLoadingIndicator() {
    return (
      <Grid alignItems="center" className={'progressPanel'} container item justifyContent="center" xs>
        <CircularProgress size={120} />
      </Grid>
    );
  }

  renderPanels() {
    const { loading } = this.props;

    if (loading) {
      return this.renderLoadingIndicator();
    }

    return (
      <Fragment>
        {this.renderCampaignList()}
        {this.renderCampaignForm()}
        {this.renderTemplateViewer()}
      </Fragment>
    );
  }

  renderTemplateViewer() {
    const { selectedTemplate } = this.state;

    if (!selectedTemplate) {
      return null;
    }

    return (
      <Grid item className={'panel'} xs={3}>
        {this.renderExecutionPlanViewer()}
        <TemplateViewer template={selectedTemplate} />
      </Grid>
    );
  }

  renderExecutionPlanViewer() {
    const { executionPlan, selectedCampaign, showExecutionPlan, loadingExecutionPlan } = this.state;

    if (loadingExecutionPlan) {
      return (
        <Grid alignItems="center" className={'progressPanel'} container item justifyContent="center" xs>
          <CircularProgress size={50} />
        </Grid>
      );
    }

    if (!selectedCampaign) {
      return null;
    }

    return (
      <ExecutionPlanViewer
        showExecutionPlan={showExecutionPlan}
        executionPlan={executionPlan}
        onReload={() => this.handleReloadExecutionPlan(selectedCampaign.id)}
      />
    );
  }

  handleCampaignUpsert = async campaign => {
    await this.props.onCampaignUpsert(campaign);
    this.setState({ loadingExecutionPlan: true });
    const executionPlan = await this.getExecutionPlan(campaign.id);
    this.setState({ executionPlan, showExecutionPlan: true, loadingExecutionPlan: false });
  };

  handleReloadExecutionPlan = async campaignId => {
    try {
      this.setState({ loadingExecutionPlan: true });
      const executionPlan = await this.getExecutionPlan(campaignId);
      this.setState({ executionPlan, showExecutionPlan: true, loadingExecutionPlan: false });
    } catch (e) {
      this.setState({
        showAlert: true,
        alertMessage: <SnackbarContent className={scss.error} message={<div>Something went wrong</div>} />
      });
    }
  };

  getExecutionPlan = async campaignId => {
    const apiService = new ApiService();
    const result = await apiService.executionPlan(campaignId);
    return result.data;
  };

  handleCampaignFormChange = async selectedCampaign => {
    if (isEqual(this.state.selectedCampaign, selectedCampaign)) {
      return;
    }
    this.setState({ selectedCampaign });

    const templateId = get(selectedCampaign, 'templateId');
    this.handleSelectedTemplateIdChange(templateId);
  };

  handleSelectedCampaignChange = async selectedCampaign => {
    await this.handleCampaignFormChange(selectedCampaign);
    this.setState({ showExecutionPlan: false });
  };

  handleSelectedTemplateIdChange = selectedTemplateId => {
    const selectedTemplate = this.flattenedTemplates.find(template => template.id === selectedTemplateId);
    if (isEqual(this.state.selectedTemplate, selectedTemplate)) {
      return;
    }

    this.setState({ selectedTemplate });
  };
}

export default CampaignManager;
