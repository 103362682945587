import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import moment from 'moment';

export function flattenTemplates(templates) {
  if (!get(templates, 'length', 0)) {
    return [];
  }

  const items = templates.map(template =>
    !get(template, 'children.length', 0) ? template : flattenTemplates(template.children)
  );

  return flatten(items);
}

export function formatCampaignForEdit(campaign) {
  if (!campaign) {
    return campaign;
  }

  campaign = cloneDeep(campaign);

  campaign.measureId = campaign.campaignType === 'measure_based' ? `${campaign.initiativeId}/${campaign.measureId}` : null;

  get(campaign, 'windowsAttributes', []).forEach(campaignWindow => {
    campaignWindow.endTime = moment(campaignWindow.endTime).format('hh:mm A');
    campaignWindow.startTime = moment(campaignWindow.startTime).format('hh:mm A');
  });

  return campaign;
}

export function formatCampaignForUpsert(campaign) {
  if (!campaign) {
    return campaign;
  }

  campaign = cloneDeep(campaign);

  campaign.daysSinceLeastRecentVisit = isNumber(campaign.daysSinceLeastRecentVisit) ? campaign.daysSinceLeastRecentVisit : null;
  campaign.daysSinceMostRecentVisit = isNumber(campaign.daysSinceMostRecentVisit) ? campaign.daysSinceMostRecentVisit : null;

  campaign.maxAge = isNumber(campaign.maxAge) ? campaign.maxAge : null;
  campaign.minAge = isNumber(campaign.minAge) ? campaign.minAge : null;
  campaign.priority = isNumber(campaign.priority) ? campaign.priority : null;
  campaign.measureId = campaign.campaignType === 'measure_based' ? campaign.measureId : null;
  campaign.patientListId = campaign.campaignType === 'patient_list' ? campaign.patientListId : null;

  get(campaign, 'windowsAttributes', []).forEach(campaignWindow => {
    campaignWindow.id = isNumber(campaignWindow.id) ? campaignWindow.id : undefined;
    campaignWindow.endTime = moment(campaignWindow.endTime, 'hh:mm A').toDate();
    campaignWindow.startTime = moment(campaignWindow.startTime, 'hh:mm A').toDate();
  });

  return campaign;
}
