import qs from 'qs';

export class HttpError extends Error {
  name = this.constructor.name;

  constructor(response) {
    super();
    Object.setPrototypeOf(this, HttpError.prototype);
    this.message = response.statusText;
  }
}

async function wrappedFetch(path, options = {}) {
  if (options.qs) {
    path = `${path}?${qs.stringify(options.qs)}`;
  }

  options.credentials = 'same-origin';
  options.headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': window.Rails.csrfToken(),
    'X-Requested-With': 'XMLHttpRequest'
  };

  const response = await fetch(path, options);

  if (response.status >= 300) {
    throw new HttpError(response);
  }

  const data = await response.json();
  return { response, data };
}

export async function getCategories({ riskAlgorithmId }) {
  return wrappedFetch(`/api/risk/algorithm/${riskAlgorithmId}/categories`);
}

export async function getConditions({ personId, ...queryParams }) {
  const { response, data } = await wrappedFetch(`/api/risk/person/${personId}/conditions`, { qs: queryParams });
  return { response, data: data.filter(c => c.riskDescription.toLowerCase() !== 'demographics') };
}

export async function getStatuses() {
  return wrappedFetch('/api/risk/condition_statuses');
}

export async function updateConditions({ conditions, personId }) {
  return wrappedFetch(`/api/risk/person/${personId}/conditions/upsert`, {
    body: JSON.stringify({ conditions }),
    method: 'POST'
  });
}
