import React, { Component, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Theme } from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import { Field } from 'react-final-form';
import includes from 'lodash/includes';

import FinalFormSelect from 'qdw-next/common/components/FinalFormSelect';
import FinalFormTreePopper from 'qdw-next/common/components/FinalFormTreePopper';

import { MultiSelectHierarchy, hierarchyStrategies } from 'qdw-next/common/data';
import { renderComplexSelectItems } from 'qdw-next/common/formHelpers';
import { userTypes, defaultAttributions, adminLevels, AdminLevel } from './constants/';
import UserEditFormState, { SelectedFields } from './UserEditFormState';
import { CurrentUser } from 'qdw-next/common/data/models';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 150
    },
    formControlFullLine: {
      margin: theme.spacing(1),
      width: 370 + 2 * theme.spacing(1)
    },
    multiSelect: {
      margin: theme.spacing(1),
      width: 420
    },
    multiSelectLabel: {
      fontSize: 13
    }
  });

type PropsPassedFromState = Pick<
  UserEditFormState,
  | 'groups'
  | 'sources'
  | 'plans'
  | 'plansSelected'
  | 'providers'
  | 'userGroupSelected'
  | 'userTypeSelected'
  | 'adminLevelSelected'
  | 'defaultAttributionSelected'
  | 'providersSelected'
  | 'sourcesSelected'
  | 'doneLoading'
>;

interface PatientAccessProps extends PropsPassedFromState {
  onUserGroupChange: (value: number | null) => void;
  onSelectChange: (key: string, value: string | string[] | null | number, callback?: () => void) => void;
  multiEdit: boolean;
  currentUser: CurrentUser | undefined;
}
export class PatientAccessPanel extends Component<PatientAccessProps & WithStyles<typeof styles>, any> {
  constructor(props: any) {
    super(props);
  }

  public handleUserGroupChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    this.props.onUserGroupChange(Number(event.target.value));
  };

  public handleSelectChange = (stateKey: keyof SelectedFields) => (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const selectedValue = event.target.value;
    if (stateKey === 'userTypeSelected' && selectedValue !== 'plan') {
      this.props.onSelectChange('plansSelected', []);
    }
    if (stateKey === 'adminLevelSelected') {
      /* using callbacks for multiple calls to onSelectChanges, this.state is not guaranteed to be up to date */
      if (includes(['client_admin', 'super_admin'], selectedValue)) {
        this.props.onSelectChange('userGroupSelected', null, () => this.props.onSelectChange(stateKey, selectedValue));
      }

      if (!includes(['client_admin', 'super_admin'], selectedValue)) {
        this.props.onSelectChange('userGroupSelected', 0, () => this.props.onSelectChange(stateKey, selectedValue));
      }
    } else {
      this.props.onSelectChange(stateKey, selectedValue);
    }
  };

  public handleMultiSelectChange = (stateKey: string) => (paths: string[]): void => {
    this.props.onSelectChange(stateKey, paths);
  };

  public render() {
    const {
      classes,
      groups,
      userGroupSelected,
      userTypeSelected,
      adminLevelSelected,
      defaultAttributionSelected,
      sourcesSelected,
      providersSelected,
      plansSelected,
      currentUser
    } = this.props;

    const adminLevelSelections = this.adminLevelPermissions(adminLevels, currentUser);
    const groupSelections = this.multiEditSelections(groups, 0);
    const userTypeSelections = this.multiEditSelections(userTypes, 'unchanged');
    const defaultAttributionSelections = this.multiEditSelections(defaultAttributions, 'unchanged');

    return (
      (this.props.doneLoading && (
        <Fragment>
          <Grid container className={classes.root}>
            <Grid item xs>
              <Typography variant="h6">Patient Access</Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item>
              <FormControl className={classes.formControl} data-test="adminLevel">
                <InputLabel>Admin Level</InputLabel>
                <Field
                  name="adminLevel"
                  component={FinalFormSelect}
                  onChange={this.handleSelectChange('adminLevelSelected')}
                  options={renderComplexSelectItems(adminLevelSelections, 'id', 'name')}
                  value={adminLevelSelected}
                />
              </FormControl>
              {!(includes(['client_admin', 'super_admin'], adminLevelSelected) && userGroupSelected === null) && (
                <FormControl className={classes.formControl} data-test="selectGroup">
                  <InputLabel>User Group</InputLabel>
                  <Field
                    name="userGroup"
                    component={FinalFormSelect}
                    onChange={this.handleUserGroupChange}
                    options={renderComplexSelectItems(groupSelections, 'id', 'name')}
                    value={userGroupSelected ? userGroupSelected : 0}
                    errorText="No group selected"
                  />
                </FormControl>
              )}
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl}>
                <InputLabel>User Type</InputLabel>
                <Select
                  disabled={true}
                  data-test="userType"
                  onChange={this.handleSelectChange('userTypeSelected')}
                  value={userTypeSelected}
                >
                  {renderComplexSelectItems(userTypeSelections, 'id', 'name')}
                </Select>
              </FormControl>

              <FormControl className={classes.formControl} disabled>
                <InputLabel>Default Attribution</InputLabel>
                <Select
                  data-test="defaultAttribution"
                  onChange={this.handleSelectChange('defaultAttributionSelected')}
                  value={defaultAttributionSelected}
                >
                  {renderComplexSelectItems(defaultAttributionSelections, 'id', 'name')}
                </Select>
              </FormControl>
            </Grid>
            {userTypeSelected === 'plan' && (
              <Grid item>
                <FormControl disabled className={classes.multiSelect}>
                  <InputLabel className={classes.multiSelectLabel}>Plans</InputLabel>
                  <Field
                    name="plansSelected"
                    hierarchy={this.props.plans}
                    hierarchyStrategy={hierarchyStrategies.ancestorFirst}
                    searchEnabled={false}
                    selectAllEnabled={true}
                    component={FinalFormTreePopper}
                    id="selectPlan"
                    onValueChange={this.handleMultiSelectChange('plansSelected')}
                    value={plansSelected}
                    errorText="No plan selected"
                  />
                </FormControl>
              </Grid>
            )}

            <Grid item className="disabledMultiSelect">
              <FormControl disabled className={classes.multiSelect} data-test="patientAssignmentAccessLabel">
                <InputLabel className={classes.multiSelectLabel}>Patient Assignment Access</InputLabel>
                <Field
                  name="providers"
                  hierarchy={this.props.providers}
                  hierarchyStrategy={hierarchyStrategies.ancestorFirst}
                  searchEnabled={true}
                  selectAllEnabled={true}
                  component={FinalFormTreePopper}
                  id="providers"
                  onValueChange={this.handleMultiSelectChange('providersSelected')}
                  value={providersSelected}
                  dataValue="patientAssignmentAccessValues"
                  errorText="No providers selected"
                />
              </FormControl>
              <FormControl disabled className={classes.multiSelect} data-test="sourceLevelAccessLabel">
                <InputLabel className={classes.multiSelectLabel}>Source-Level Access</InputLabel>
                <Field
                  name="sources"
                  hierarchy={this.props.sources}
                  hierarchyStrategy={hierarchyStrategies.ancestorFirst}
                  searchEnabled={true}
                  selectAllEnabled={true}
                  component={FinalFormTreePopper}
                  id="sources"
                  onValueChange={this.handleMultiSelectChange('sourcesSelected')}
                  value={sourcesSelected}
                  dataValue="sourceLevelAccessValues"
                  errorText="No sources selected"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Fragment>
      )) || <CircularProgress className={classes.multiSelect} />
    );
  }

  private multiEditSelections(selections: any, value: string | number) {
    return this.props.multiEdit ? [{ id: value, name: 'Unchanged' }].concat(selections) : selections;
  }

  private adminLevelPermissions(adminLevel: AdminLevel[], currentUser: CurrentUser | undefined): AdminLevel[] {
    if (currentUser) {
      let validAdminLevels: AdminLevel[] = adminLevel;
      if (currentUser.role === 'super_admin' || currentUser.role === 'client_admin') {
        validAdminLevels = validAdminLevels.concat({ id: 'client_admin', name: 'Client Admin' });
      }
      if (currentUser.role === 'super_admin') {
        validAdminLevels = validAdminLevels.concat({ id: 'super_admin', name: 'Super User' });
      }

      validAdminLevels = this.multiEditSelections(validAdminLevels, 'unchanged');
      return validAdminLevels;
    } else {
      return [];
    }
  }
}

export default withStyles(styles)(PatientAccessPanel);
