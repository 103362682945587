import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import ExpanderIcon from './ExpanderIcon';

export default class HierarchyListNode extends Component {
  static propTypes = {
    node: PropTypes.object.isRequired,
    onNodeCheckedChange: PropTypes.func.isRequired,
    onNodeExpandedChange: PropTypes.func.isRequired,
    renderNodeDisplay: PropTypes.func
  };

  handleNodeCheckedChange = () => {
    const { node, onNodeCheckedChange } = this.props;

    onNodeCheckedChange(node, !node.checked);
  };

  handleNodeExpandedChange = () => {
    const { node, onNodeExpandedChange } = this.props;

    onNodeExpandedChange(node, !node.expanded);
  };

  render() {
    const { node } = this.props;

    return (
      <Grid alignItems="center" container item justifyContent="center" style={{ paddingLeft: 15 * node.level }}>
        {this.renderExpanderIcon(node)}
        {this.renderCheckbox(node)}
        <Grid item xs>
          {this.renderNodeDisplay(node)}
        </Grid>
      </Grid>
    );
  }

  renderCheckbox(node) {
    const { hierarchy } = node;

    if (node.hasChildren && !hierarchy.multiple) {
      return null;
    }

    return (
      <Grid item xs={1}>
        <Checkbox
          checked={node.checked}
          style={{ paddingRight: 10 }}
          indeterminate={node.indeterminate}
          onClick={this.handleNodeCheckedChange}
        />
      </Grid>
    );
  }

  renderNodeDisplay(node) {
    const { renderNodeDisplay } = this.props;

    return renderNodeDisplay ? renderNodeDisplay(node) : node.display;
  }

  renderExpanderIcon(node) {
    const { hierarchy } = node;
    if ((!node.hasChildren && !hierarchy.multiple) || (!node.hasChildren && hierarchy.flat)) {
      return null;
    }

    const button = node.hasChildren ? (
      <IconButton onClick={this.handleNodeExpandedChange}>
        <ExpanderIcon expanded={node.expanded} />
      </IconButton>
    ) : null;

    return (
      <Grid item xs={1}>
        {button}
      </Grid>
    );
  }
}
