import get from 'lodash/get';

import cloneDeep from 'lodash/fp/cloneDeep';
import flow from 'lodash/fp/flow';
import groupBy from 'lodash/fp/groupBy';
import map from 'lodash/fp/map';
import mapValues from 'lodash/fp/mapValues';

import Schema from 'validate';

const campaignValidator = new Schema({
  campaignType: {
    message: {
      required: 'required',
      type: 'type'
    },
    required: true,
    type: 'string'
  },
  duration: {
    message: {
      required: 'required',
      type: 'type'
    },
    required: true,
    type: 'number'
  },
  durationType: {
    message: {
      required: 'required',
      type: 'type'
    },
    required: true,
    type: 'number'
  },
  languages: {
    length: { min: 1 },
    message: {
      length: 'required',
      required: 'required',
      type: 'type'
    },
    required: true,
    type: 'array'
  },
  name: {
    message: {
      required: 'required',
      type: 'type'
    },
    required: true,
    type: 'string'
  },
  pcps: {
    length: { min: 1 },
    message: {
      length: 'required',
      required: 'required',
      type: 'type'
    },
    required: true,
    type: 'array'
  },
  providerAttribution: {
    message: {
      required: 'required',
      type: 'type'
    },
    required: true,
    type: 'string'
  },
  templateId: {
    message: {
      required: 'required',
      type: 'type'
    },
    required: true,
    type: 'number'
  },
  windowsAttributes: {
    message: {
      type: 'type'
    },
    type: 'array'
  }
});

const campaignWindowValidator = new Schema({
  dayOfWeek: {
    message: {
      required: 'required',
      type: 'type'
    },
    required: true,
    type: 'number'
  },
  endTime: {
    message: {
      required: 'required',
      type: 'type'
    },
    required: true,
    type: 'string'
  },
  messageLimit: {
    message: {
      required: 'required',
      type: 'type'
    },
    required: true,
    type: 'number'
  },
  startTime: {
    message: {
      required: 'required',
      type: 'type'
    },
    required: true,
    type: 'string'
  },
  timeZone: {
    message: {
      required: 'required',
      type: 'type'
    },
    required: true,
    type: 'string'
  }
});

const transformErrors = flow(
  map(error => {
    const { message, path } = error;
    return {
      [message]: true,
      path
    };
  }),
  groupBy(error => error.path),
  mapValues(errors =>
    errors.reduce((acc, error) => {
      delete error.path;
      return { ...acc, ...error };
    }, {})
  )
);

export function validateCampaign(campaign, includeWindows = true) {
  if (!campaign) {
    return {};
  }

  // validate modifies the original object and that is undesirable
  let clonedCampaign = cloneDeep(campaign);

  let errors = campaignValidator.validate(clonedCampaign);
  errors = transformErrors(errors);

  if (!includeWindows) {
    return errors;
  }

  // validate modifies the original object and that is undesirable
  clonedCampaign = cloneDeep(campaign);
  const campaignWindowsErrors = validateCampaignWindows(clonedCampaign.windowsAttributes);
  if (Object.keys(campaignWindowsErrors).length) {
    errors.windowsAttributes = { ...errors.windowsAttributes, ...campaignWindowsErrors };
  }

  return errors;
}

function validateCampaignWindows(campaignWindows) {
  if (!get(campaignWindows, 'length')) {
    return {};
  }

  // don't validate those about to be destroyed
  campaignWindows = campaignWindows.filter(campaignWindow => !campaignWindow._destroy);
  const errors = {};

  for (const campaignWindow of campaignWindows) {
    const windowErrors = validateCampaignWindow(campaignWindow);
    if (Object.keys(windowErrors).length) {
      errors[campaignWindow.id] = windowErrors;
    }
  }

  return errors;
}

export function validateCampaignWindow(campaignWindow) {
  if (!campaignWindow) {
    return {};
  }

  // validate modifies the original object and that is undesirable
  campaignWindow = cloneDeep(campaignWindow);

  const errors = campaignWindowValidator.validate(campaignWindow);
  return transformErrors(errors);
}
