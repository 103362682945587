import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import isString from 'lodash/isString';

export function filterTemplates(templates, filterFn) {
  if (!get(templates, 'length', 0)) {
    return [];
  }

  if (!filterFn) {
    return templates;
  }

  templates.forEach(template => {
    if (get(template, 'children.length', 0)) {
      template.children = filterTemplates(template.children, filterFn);
    }
  });

  return templates.filter(filterFn);
}

export function formatAttribute(attribute) {
  if (!isString(attribute)) {
    return '';
  }

  return attribute
    .replace(/[$_-]/g, ' ')
    .split(' ')
    .filter(word => !!word)
    .map(word => {
      if (word.match(/^[^a-z]*$/)) {
        return word;
      }
      return capitalize(word);
    })
    .join(' ');
}
