import {
  User,
  CurrentUser,
  Group,
  Source,
  Provider,
  Plan,
  Template,
  ClientConfiguration,
  PageConfiguration,
  Report,
  Initiative,
  HomepageWidgets,
  EwaDashboards
} from 'qdw-next/common/data/models';

import wrappedFetch from '../../../common/wrappedFetch';

export default class ApiService {
  constructor() {} // tslint:disable-line: no-empty

  uploadFile(body: any = {}, options: any = {}) {
    return wrappedFetch(`/api/users/import`, body, options);
  }

  getUser(userId: number) {
    return wrappedFetch<User>(`/api/users/${userId}`);
  }

  deleteUser(userId: number) {
    return wrappedFetch<User>(`/api/users/${userId}`, {}, { method: 'DELETE' });
  }

  getHRAClientConfiguration() {
    return wrappedFetch<ClientConfiguration>(`/api/client_configurations`, { body: '{"name":"HRA:Enabled"}', method: 'POST' });
  }

  getUsers() {
    return wrappedFetch<User[]>(`/api/users`);
  }

  getGroups() {
    return wrappedFetch<Group[]>(`/api/groups`);
  }

  getSources() {
    return wrappedFetch<Source[]>(`/api/filters/sources`);
  }

  getCurrentUser() {
    return wrappedFetch<CurrentUser>(`/api/users/current`);
  }

  getProviders() {
    return wrappedFetch<Provider[]>(`/api/filters/providers`);
  }

  getPlans() {
    return wrappedFetch<Plan[]>(`/api/plans`);
  }

  getTemplates(currentUser: CurrentUser | undefined) {
    if (currentUser && currentUser.outreachAbility) {
      return wrappedFetch<Template[]>(`/api/outreach/templates`);
    } else {
      const response = new Response(new Blob(), { status: 200, statusText: '' }) as Response;
      const data = [] as any;
      return Promise.resolve({ data, response });
    }
  }

  getReports() {
    return wrappedFetch<Report[]>(`/api/reports`);
  }

  getInitiatives() {
    return wrappedFetch<Initiative[]>(`/api/initiatives`);
  }

  getPageConfigurations() {
    return wrappedFetch<PageConfiguration[]>(`/api/page_configurations`);
  }

  getWidgets() {
    return wrappedFetch<HomepageWidgets[]>(`/api/homepage_widgets`);
  }

  getEwaDashboards() {
    return wrappedFetch<EwaDashboards[]>(`/api/ewa_dashboards`);
  }

  bulkUpdate(body: any = {}, options: any = {}) {
    return wrappedFetch(`/api/users/bulk_update`, body, options);
  }

  create(body: any = {}, options: any = {}) {
    return wrappedFetch(`/api/users`, body, options);
  }

  resetMFA(userId: number, options: any = {}) {
    return wrappedFetch(`/api/users/${userId}/mfa_reset`, JSON.stringify({}), options);
  }

  syncAllUsers(body: any = {}, options: any = {}) {
    return wrappedFetch('/api/users/sync_all_users', body, options);
  }

  syncOrganizationHierarchy(body: any = {}, options: any = {}) {
    return wrappedFetch('/api/admin/organization_hierarchy', JSON.stringify(body), options);
  }

  syncChimeraSettings(body: any = {}, options: any = {}) {
    return wrappedFetch('/api/admin/chimera_settings', JSON.stringify(body), options);
  }

  unlinkUser(userId: number, options: any = {}) {
    return wrappedFetch(`/api/users/${userId}/unlink_user`, JSON.stringify({}), options);
  }

  resendTempPassword(userId: any, body: any = {}, options: any = {}) {
    return wrappedFetch(`/api/users/${userId}/resend_password`, JSON.stringify(body), options);
  }
}
