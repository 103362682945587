import { SingleSelectHierarchyNode } from './SingleSelectHierarchyNode';

export class MultiSelectHierarchyNode extends SingleSelectHierarchyNode {
  get checked() {
    if (!this.hasChildren) {
      return !!this._checked;
    } else {
      return this._everyDescendantsChecked();
    }
  }
  set checked(value) {
    if (!!value === this.checked) {
      return;
    } else if (!this.hasChildren) {
      this._checked = !!value;
    } else {
      this.nodes.forEach(d => (d.checked = !!value));
    }
  }

  get indeterminate() {
    if (!this.hasChildren) {
      return false;
    } else if (this.disabled) {
      return this._someDescendantsChecked();
    } else {
      return this._someDescendantsIndeterminate() || (!this.checked && this._someDescendantsChecked());
    }
  }

  _everyDescendantsChecked() {
    return this.hasChildren && this.descendants().every(d => d.checked);
  }

  _someDescendantsChecked() {
    return this.hasChildren && this.descendants().some(d => d.checked);
  }

  _someDescendantsIndeterminate() {
    return this.hasChildren && this.descendants().some(d => d.indeterminate);
  }
}
